<template>
  <div class="sp">
  <v-app-bar
    color="white"
    app
    elevate-on-scroll
  >
  <router-link to="/" class="pt-2">
    <img src="@/assets/img/betalogo.svg">
  </router-link>
  <v-btn
    depressed
    :to="'/'"
    class="ml-4"
    icon
    plain
  >
    <v-icon>mdi-home-outline</v-icon>
  </v-btn>
  <v-spacer></v-spacer>
        
      <div v-if="isLoggedIn">
        <router-link :to="'/users/'+ this.$store.getters.user.uid +'/detail'" class="no-under-line">
          <v-avatar
          size="30"
          class="mr-2">
            <v-img
              :src="this.$store.state.userIconSrc"
            />
          </v-avatar>
        </router-link>  
      </div>

      <v-menu
        bottom
        left
        class="z999"
        :offset-y="true"
      >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
          >mdi-menu</v-icon>
        </v-btn>
      </template>

      <v-list class="text-left">
        <v-list-item
          to="/"
        >
        <v-list-item-title>ホーム</v-list-item-title>
        </v-list-item>

        <v-divider class="mt-2 mb-2"></v-divider>

        <v-list-item
          to="/beginner"
        >
        <v-list-item-title>はじめての方へ</v-list-item-title>
        </v-list-item>

        <v-list-item
          to="/post"
          v-if="isLoggedIn"
        >
        <v-list-item-title>スポット情報を共有</v-list-item-title>
        </v-list-item>
        
        <v-divider class="mt-2 mb-2"></v-divider>

        <!-- <v-list-item
          to="/spotrequest"
          v-if="isLoggedIn"
        >
        <v-list-item-title>スポットリクエスト</v-list-item-title>
        </v-list-item> -->

        <v-list-item
          to="/users/home"
          v-if="isLoggedIn"
        >
        <v-list-item-title>設定</v-list-item-title>
        </v-list-item>

        <div v-if="isLoggedIn">
          <v-list-item
            :to="'/users/'+ this.$store.getters.user.uid +'/detail'"
          >
          <v-list-item-title>ユーザーページ</v-list-item-title>
          </v-list-item>

          <v-list-item
            :to="'/users/'+ this.$store.getters.user.uid +'/list'"
          >
          <v-list-item-title>リスト</v-list-item-title>
          </v-list-item>
        </div>


        <v-list-item
          to="/auth/login"
          v-if="!isLoggedIn"
        >
        <v-list-item-title>ログイン</v-list-item-title>
        </v-list-item>

        <v-list-item
          to="/auth/signup"
          v-if="!isLoggedIn"
        >
        <v-list-item-title>無料でアカウント作成</v-list-item-title>
        </v-list-item>
        
        <v-divider class="mt-2 mb-2" v-if="isLoggedIn"></v-divider>

        <v-list-item
          v-if="isLoggedIn"
          @click="logout"
        >
        <v-list-item-title class="subtitle-2">
          <v-icon class="pb-1">mdi-logout</v-icon>
          ログアウト</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
  </div>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'Headersp',
  data: () => ({
  }),
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.idToken !== null;
    }
  },
  methods: {
    logout: function () {
      this.$store.commit('updateIdToken', null);
      this.$store.commit('updateDisplayName', null);
      this.$store.commit('updateUserIconSrc', null);
      this.$store.commit('updateUser', null);
      this.$store.commit('updateFlightSpot', null);
      this.$store.commit('userFlightSpotSelectValue', null);
      this.$store.commit('searchValue', null);

      firebase.analytics().logEvent('logout', {});
      firebase.auth().signOut()
      this.$router.push('/')
    }
  },
}
</script>


<style lang="scss">
.z999 {
  z-index: 999 !important;
}
</style>