<template>
  <div>
    <div class="guide-container">
      <h1 class="text-center mt-16 mb-10 text-h5 font-weight-bold text-sm-h4">コミュニティガイドライン</h1>
      <div class="text-justify">
        <p class="mb-16">
          Flyersは、ドローンユーザーのためのプラットフォームです。
          このコミュニティガイドラインは、「みんなのフライトスポット」をお使いの皆様にFlyersの考え方を示し、ユーザーの皆様にご理解とご協力をいただくことを目的としています。
        </p>

        <div class="mb-6">
          <h2 class="text-h6 font-weight-bold mb-2">ユーザー同士の尊重</h2>
          <p>
            ドローンを取り巻く環境は常に変化しており、投稿内容が間違えていたり、意見が食い違ったりすることは当然起こりうると考えています。<br>
            ですから、内容の正誤に関係なく、他者を攻撃し傷つける投稿は許容されません。<br>
            仮に意見が食い違っても敬意を持ち、わかりあえないからといって口論などはしないよう、お願いいたします。
          </p>
          <h3 class="subtitle-1 font-weight-bold mb-2">他のユーザーとの交流の際は、以下の点にお気遣いください。</h3>
          <ul class="mb-4 subtitle-2">
            <li>丁寧な言葉遣いをする</li>
            <li>人格を攻撃しない</li>
            <li>嫌がらせをしない</li>
            <li>煽るような投稿をしない</li>
            <li>相手との距離感を誤らない
            <li>相手を不快な気持ちにさせない</li>
            <li>他者への嫌がらせ（ハラスメント）を率先して行ったり、嫌がらせに加わったり、嫌がらせを奨励したりしない</li>
          </ul>
        </div>

        <div class="mb-6">
          <h2 class="text-h6 font-weight-bold mb-2">スポット情報の共有にあたって</h2>
          <p>
            「みんなのフライトスポット」はドローンユーザー同士での情報共有を促し、
            もっとドローンを楽しめるようにとの想いを込めて開発しています。 
          </p>
          <h3 class="subtitle-1 font-weight-bold mb-2">投稿の際には以下の点を考慮してみてください。</h3>
          <ul class="mb-4 subtitle-2">
            <li>できるだけ客観的にまとめる</li>
            <li>根拠となる情報源があれば記載する</li>
            <li>人が読むことを意識した言葉遣いを心がける</li>
            <li>ドローンに直接関係しなくとも、近隣の観光情報や思い出など場所にまつわる情報を書き込んでみる</li>
          </ul>
        </div>

        <div class="mb-6">
          <h2 class="text-h6 font-weight-bold mb-2">最後に</h2>
          <p>
            Flyersはユーザーの皆様に「Flyersがあってよかった」と思っていただけるよう、開発・運用をしています。
            利用規約をよく読まれた上でFlyersをご利用いただき、より良いドローンライフをお過ごしください。
          </p>
          <p>
            ご要望やお問い合わせなどはflyersのお問い合わせよりお気軽にお願いいたします。
          </p>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.title = this.$route.meta.title
  },
}
</script>

<style lang="scss" scoped>

.guide-container {
  width: 70%;
  margin: 0 auto;
  text-justify: auto;
}

ul {
list-style-type: decimal;
  li {
    margin-bottom: 4px;
  }
}

@media (max-width: 768px) {

  .guide-container {
    width: 80%;
  }

}

</style>