<template>
  <div>
    <v-row class="landingg mr-0 ml-0">
      <div class="aaaaaaaaa">
        <v-card width="350px" class="mx-auto mt-5 pa-7 zzz"> 
          <v-img class="ma-6" src="@/assets/img/betalogo.svg"></v-img>
          <h1 class="text-h6 font-weight-bold mt-4 mb-0">パスワード再設定</h1>
          <v-card-text>
            <v-form>
              <v-text-field 
                label="メールアドレス"
                v-model="email"
                />
              <v-card-actions>
                <v-btn 
                  rounded
                  color="primary"
                  width="100%"
                  depressed
                  class="mt-6 mb-3"
                  :loading="status"
                  :disabled="status"
                  @click="sendMail"
                >
                <span class="font-weight-bold">再設定用メールを送信する</span>
                </v-btn>
              </v-card-actions>
              <router-link to="/auth/login" class="text-decoration-none"><p class="subtitle-2 mt-2 mb-0">ログイン画面にもどる</p></router-link>
            </v-form>
          </v-card-text>
        </v-card>
      </div>

      <div class="hoge"></div>
      <video poster="" preload="none" muted loop autoplay playsinline>
        <source src="@/assets/img/top.mp4">
      </video>
    </v-row>

      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
        >
        <v-card>
          <v-card-title class="text-h6">
            お知らせ
          </v-card-title>
          <v-card-text class="subtitle-1 text-left">
            {{ message }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="closeDialog"
            >
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'App',
  data (){
    return {
      email: '',
      status: false,
      dialog: false,
      message: '',
      isSuccsess: false
    }
  },
  methods: {
    sendMail () {
      var self = this
      self.status = true
      firebase.auth().sendPasswordResetEmail(this.email).then(function() {
        self.message = '再設定メールを送信しました'
        self.dialog = true
        self.isSuccsess = true
      }).catch(function(error) {
        console.log(error)
        self.message = 'メールアドレスが間違っているか、ユーザーが存在しません'
        self.dialog = true
        self.status = false
      });
      firebase.analytics().logEvent('reset_password_complete', {});
    },
    closeDialog() {
      var self = this
      self.dialog = false
      if(self.isSuccsess){
        this.$router.push('/auth/login')
      }
    },
  },
  created() {
   firebase.analytics().logEvent('reset_password', {});
  },
  mounted() {
    document.title = this.$route.meta.title
  }
}
</script>