<template>
  <div>
    <v-form ref="form">
      <div class="container">
        <h1>利用申請</h1>
        <div class="information mt-6 mb-3 mt-md-6">
          <div class="d-flex align-center">
            <v-img 
              max-height="26px"
              max-width="23px"
              class="mr-1"
              v-bind:class="{'opa': !flightSpot.certify }"
              :src="require('@/assets/img/icon_cirtify.svg')"/>
            <v-img 
              max-height="26px"
              max-width="20px"
              class="mr-2"
              :src="getTypeIconSrc(flightSpot.type)"/>
            <p class="caption text-sm-subtitle-2 mb-0 pt-0">{{ flightSpot.prefecture }} / {{ flightSpot.municipality }}</p>
          </div>
        </div>
        <h2 class="text-sm-h2 text-h4 font-weight-bold mb-8">{{ flightSpot.name }}</h2>
        <section class="picker mb-14">
          <div>
            <h3 class="md-text-h4 text-h5 font-weight-bold">
              <v-icon large class="pr-2 pb-2 primary--text">far fa-calendar-alt</v-icon>
              フライト予定日を選択してください
            </h3>
            <p class="subtitle-1">2021年9月30日まで選択できます</p>
            <v-date-picker 
              locale="jp-ja"
              :day-format="date => new Date(date).getDate()"
              full-width
              v-model="date"
              :min="minDate"
              max="2021-9-30"
              style="border:1px solid rgba(0,0,0,0.2); border-radius:6px;"
              color="primary"
              no-title
              @change="dateChange"
            ></v-date-picker>
            <v-text-field
              v-model="selectDate"
              label="フライト予定日（カレンダーから日付を選択してください）"
              :rules="[rules.required]"
              readonly
              class="mt-6"
            ></v-text-field>
          </div>
        </section>

        <section class="pt-6 mb-16">
          <div>
            <h3 class="text-h5 font-weight-bold">
              <v-icon large class="pr-2 pb-2 primary--text">far fa-clock</v-icon>
              利用予定時間を選択してください
            </h3>
            <p class="subtitle-1">余裕のある時間設定をお願いいたします</p>
            <v-card
              class="d-flex flex-column pr-0 pl-8 pb-2 pt-2"
              outlined
              elevation="0"
            >
            <div>
            <v-select
              :items="times"
              item-text="time"
              item-value="value"
              label="開始予定時刻"
              v-on:change="changeStartTime"
              v-model="start"
              class="pr-10"
              :rules="[rules.required]"
            ></v-select>
            <v-select
              :items="endTimes"
              item-text="time"
              item-value="value"
              label="終了予定時刻"
              v-model="end"
              :disabled="isEndtime"
              v-on:change="changeEndTime"
              class="pr-10"
              :rules="[rules.required]"
            ></v-select>
            </div>
            <p class="subtitle-2">利用予定時間：<span class="subtitle-1">{{t}}</span>時間</p>
            </v-card>
          </div>
        </section>
        
        <section class="drone pt-7 mb-16">
          <div class="mb-3">
            <h3 class="text-h5 font-weight-bold mb-3">
              <v-icon large class="pr-2 pb-2 primary--text">mdi-quadcopter</v-icon>
              使用する機体を選択してください
            </h3>
            <v-select
              :items="drones"
              label="使用する機体"
              v-model="drone"
              v-on:change="changeDrone"
              :rules="[rules.required]"
            ></v-select>
            <v-text-field
              v-model="otherDrone"
              label="その他のドローン"
              placeholder="使用する機体名を入力してください"
              v-if="isOtherDrone"
              :rules="[rules.required]"
            ></v-text-field>
          </div>
          <div>
            <h3 class="subtitle-1 font-weight-bold">
              <v-icon class="text-h5 pr-1 pb-2 primary--text">mdi-medical-bag</v-icon>
              上記機体は損害賠償保険の保証対象ですか？
            </h3>
            <v-radio-group 
              v-model="radios" 
              :mandatory="false"
              class="mt-0"
              :rules="[rules.required]"
            >
              <v-radio :value="1">
                <template v-slot:label>
                  <div class="subtitle-1">はい、保証対象です</div>
                </template>
              </v-radio>
              <v-radio :value="0">
                <template v-slot:label>
                  <div class="subtitle-1">いいえ、保証対象では有りません</div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>  
        </section>

        <section class="drone pt-6 mb-14">
          <div class="mb-3">
            <h3 class="text-h5 font-weight-bold mb-3">
              <v-icon large class="pr-2 pb-2 primary--text">fas fa-bullseye</v-icon>
              どのような目的のためご利用をされますか？
            </h3>
            <v-select
              :items="purposes"
              label="ご利用目的を選択してください"
              v-model="purpose"
              v-on:change="changePurpose"
              :rules="[rules.required]"
            ></v-select>
            <v-text-field
              v-model="otherPurpose"
              label="その他の目的のため"
              placeholder="利用目的を入力してください"
              v-if="isOtherPurpose"
              :rules="[rules.required]"
            ></v-text-field>
          </div>
        </section>

        <section class="purpose pt-6 mb-14">
          <div>
            <h3 class="text-h5 font-weight-bold mb-3">
              <v-icon large class="pr-2 pb-2 primary--text">fas fa-coins</v-icon>
              ご利用は個人としてのものですか？
            </h3>
            <v-radio-group 
              v-model="isCommercial" 
              :mandatory="false"
              class="mt-0"
              :rules="[rules.required]"
            >
              <v-radio :value="1">
                <template v-slot:label>
                  <div class="subtitle-1">個人での利用（非商用）</div>
                </template>
              </v-radio>
              <v-radio :value="0">
                <template v-slot:label>
                  <div class="subtitle-1">業務や委託などでの利用（商用）</div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>  
        </section>

        <section class="personal mb-16">
          <div>
            <h3 class="text-h5 font-weight-bold mb-3">
              <v-icon large class="pr-2 pb-1 primary--text">mdi-face</v-icon>
              個人に関する情報入力</h3>
            <v-text-field
              v-model="mail"
              label="メールアドレス"
              readonly
              prepend-inner-icon="mdi-email"
            ></v-text-field>
            <v-text-field
              v-model="name"
              label="お名前"
              placeholder="お名前を入力してください"
              prepend-inner-icon="mdi-account"
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="address"
              label="お住まい"
              required
              placeholder="お住まいの住所を入力してください"
              prepend-inner-icon="mdi-map-marker"
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="tel"
              label="携帯電話番号 （入力例 09094351093 ）"
              placeholder="確実に連絡が取れる番号を入力してください"
              prepend-inner-icon="mdi-cellphone-android"
              :rules="[rules.required, rules.phoneNumberLength, rules.phonePattern]"
            ></v-text-field>
          </div>
        </section>
        <div class="submit mb-14">
            <v-btn
              height="55px"
              color="primary"
              x-large
              rounded
              depressed
              @click="validate"
              class="pl-10 pr-10"
            >
              <v-icon left class="pr-6">fas fa-check</v-icon>
              <span class="text-h6 font-weight-bold">利用申請確認に進む</span>
            </v-btn>
          <p class="text-subtitle-2 font-weight-bold pt-4">※ご利用料金はかかりません</p>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import firebase from 'firebase'
import flightSpots from '@/assets/flightSpots.json'

export default {
  data: function() {   
    return{
      drones: [
        'DJI Mavic Mini',
        'DJI Mini 2',
        'DJI Mavic Air',
        'DJI Mavic Air 2',
        'DJI Mavic 2 PRO',
        'DJI Mavic 2 ZOOM',
        'DJI Mavic 2 Enterprise',
        'DJI Spark',
        'DJI Inspire 2',
        'DJI Phantom 3',
        'DJI Phantom 4',
        'その他'
      ],
      purposes: [
        'SNSで公開するため（Twitter・Facebook・Youtube・Instagramなど）',
        '飛行練習のため',
        '撮影練習のため',
        '作品制作のため',
        'その他'
      ],
      times: [
        { time: '00:00', value: 1 },
        { time: '01:00', value: 2 },
        { time: '02:00', value: 3 },
        { time: '03:00', value: 4 },
        { time: '04:00', value: 5 },
        { time: '05:00', value: 6 },
        { time: '06:00', value: 7 },
        { time: '07:00', value: 8 },
        { time: '08:00', value: 9 },
        { time: '09:00', value: 10 },
        { time: '10:00', value: 11 },
        { time: '11:00', value: 12 },
        { time: '12:00', value: 13 },
        { time: '13:00', value: 14 },
        { time: '14:00', value: 15 },
        { time: '15:00', value: 16 },
        { time: '16:00', value: 17 },
        { time: '17:00', value: 18 },
        { time: '18:00', value: 19 },
        { time: '19:00', value: 20 },
        { time: '20:00', value: 21 },
        { time: '21:00', value: 22 },
        { time: '22:00', value: 23 },
        { time: '23:00', value: 24 }
      ],
      endTimes: [],
      flightSpot: [],
      date: '',
      minDate: '',
      radios: 0,
      drone: '',
      mail: this.$store.getters.idToken.email,
      name: '',
      address: '',
      tel: '',
      isEndtime: true,
      start: '',
      end: '',
      t: 0,
      isOtherDrone: false,
      otherDrone: '',
      purpose: '',
      isOtherPurpose: '',
      otherPurpose: '',
      isCommercial: 1,
      rules: {
        required: v => !!v || '必須です.',
        phoneNumberLength: v => v.length <= 13 ||  '電話番号の桁数が多いです。入力内容を確認してください。',
        phonePattern: v => {
          const pattern = /^[0-9-]*$/
          return pattern.test(v) || '数字またはハイフン以外が入力されています'
        },
      },
      selectDate:''
    }
  },
  methods: {
    submit (){
      this.$router.push({name: 'Confirm', params: {
        date: this.date,
        radios: this.radios,
        drone: this.isOtherDrone ? this.otherDrone : this.drone,
        mail: this.mail,
        name: this.name,
        address: this.address,
        tel: this.tel,
        start: this.times[this.start-1]['time'],
        end: this.times[this.end-1]['time'],
        t: this.t,
        purpose: this.isOtherPurpose ? this.otherPurpose : this.purpose,
        isCommercial: this.isCommercial
      }})
    },
    changeStartTime() {
      this.endTimes = []
      if(this.start !== ''){
        for (let i = 0; i < this.times.length-this.start; i++) {
          this.endTimes.push(this.times[this.start+i])
        }
        this.isEndtime = false
      }
      if(this.end !== ''){
        this.changeEndTime()
        if(this.t < 0) {
          this.t = 0
        }
      }
    },
    changeEndTime() {
      this.t = this.end - this.start 
    },
    changeDrone() {
      this.isOtherDrone = this.drone == 'その他' ?  true : false
    },
    changePurpose() {
      this.isOtherPurpose = this.purpose == 'その他' ?  true : false
    },
    validate () {
      if(this.$refs.form.validate()) {
        this.submit()
      }
    },
    dateChange () {
      this.selectDate = this.formatDate(new Date(this.date))
    },
    formatDate(date) {
      var year = date.getFullYear();
      var month = ('00' + (date.getMonth()+1)).slice(-2);
      var day = ('00' + date.getDate()).slice(-2);
      var week = ['日','月','火','水','木','金','土'][date.getDay()]

      return (year + '年' + month + '月' + day + '日（' + week +'曜日）')
    },    
    getFlightSpot() {
      var result = {}

      flightSpots.forEach(element => {
        if(element.id == this.$route.params.id) {
          result = element
        }
      })

      return result
    },
    getTypeIconSrc(type) {
      switch (type) {
        case parseInt(process.env.VUE_APP_FLIGHT_SPOT_VALUE):
        default:
        return require('@/assets/img/y.svg')
        case parseInt(process.env.VUE_APP_NOT_FLIGHT_SPOT_VALUE):
        return require('@/assets/img/z.svg')
      }
    },
  },
  mounted() {
    document.title = this.$route.meta.title
  },
  created() {
    var self = this

    firebase.firestore().collection("userFlightSpots")
    .where("id", "==", this.$route.params.id)
    .get()
    .then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
          self.flightSpot = doc.data()
      })

      var shiftDay = 0
      var now = new Date(Date.now()+1000*60*60*14)
      var reservedMinDay = parseInt(self.flightSpot.reservedMinDay)
      var nowWeekDays = now.getDay()

      if(nowWeekDays == 0){
        nowWeekDays = 7
      }

      var isWeekend = (reservedMinDay + nowWeekDays) / 7 >= 1

      if(isWeekend) {
        shiftDay = 2 + reservedMinDay
      } else {
        shiftDay = reservedMinDay
      }

      now.setDate(now.getDate() + shiftDay)
      self.minDate = new Date(now).toISOString().substr(0, 10)

      firebase.analytics().logEvent('form', {
        id: self.$route.params.id,
        spotName: self.flightSpot.name
      });
    });
  }
}
</script>