<template>
  <div>
    <header>
      <Headersp/>
        <div class="pc">
          <div class="headerr">
            <div class="navigation">
              <div>
                <router-link to="/home">
                  <img src="@/assets/img/betalogo.svg">
                </router-link>
              </div>
              <div class="hoge">
              <v-row
                  align="center"
                  justify="space-around"
                >
                  <router-link to="/home">
                    <v-btn 
                      text
                    >
                      ホーム
                    </v-btn>
                  </router-link>
                  <router-link to="/userspot">
                    <v-btn
                    text
                    >
                      みんなのフライトスポット
                    </v-btn>
                  </router-link>
                  <router-link to="/spotrequest">
                    <v-btn
                    text
                    >
                      スポットリクエスト
                    </v-btn>
                  </router-link>
                </v-row>
              </div>
            </div>
            <div class="hage">
              <v-row
                align="center"
                justify="space-around"
              >
                <router-link to="/post">
                  <v-btn
                    rounded
                    color="primary"
                    class="mr-6"
                    depressed
                  >
                    <span class="font-weight-bold">スポット情報を共有する</span>
                  </v-btn>
                </router-link>
                <router-link to="/users/home">
                  <p class="subtitle-2 mb-0 mr-4" v-if="isLoggedIn">
                    <!-- {{ this.$store.getters.displayName }}  -->
                    <v-avatar
                    size="40">
                      <v-img
                        :src="this.$store.state.userIconSrc"
                      ></v-img>
                    </v-avatar>
                    <v-icon small class="pl-2 pb-1 primary--text">fas fa-caret-down</v-icon>
                  </p>
                </router-link>
                <router-link to="/auth/login">
                  <v-btn 
                    text
                    v-if="!isLoggedIn"
                  >
                  ログイン
                  </v-btn>
                </router-link>
                <router-link to="/auth/signup">
                  <v-btn 
                    rounded
                    depressed
                    outlined
                    color="primary"
                    class="ml-6 signupbtn"
                    v-if="!isLoggedIn"
                  >
                  ベータ版アカウント作成（無料）
                  </v-btn>
                </router-link>
              </v-row>
            </div>
          </div>
        </div>
      </header>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import firebase from 'firebase'
import Footer from '@/components/Footer.vue'
import Headersp from '@/components/Headersp.vue'

export default {
    components: {
    Footer,
    Headersp
  },
  data() {
    return {
    }
  },
  methods: {
    logout: function () {
      this.$store.commit('updateDisplayName', null)
      this.$store.commit('updateIdToken', null)
      this.$store.commit('updateUser', null)
      firebase.auth().signOut()
      firebase.analytics().logEvent('logout', {})
      this.$router.push('/')
    },
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.idToken !== null;
    },
  },
  created() {

    if(!this.$store.state.userIconSrc){
      this.$store.state.userIconSrc =  '/img/icon_blank.svg'
    }
    
    if (this.$store.getters.idToken == null) {
      this.$router.push('/auth/login')
    }
  }
}
</script>