<template>
  <div class="container">
    <v-form ref="form">
      <h1 class="text-h4 font-weight-bold mb-6 mt-8">スポット情報編集</h1>
      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1">スポットID</h3>
        <p>{{ flightSpot.id }}</p>
      </div>

      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1">作成者</h3>
        <p>{{ userid }}</p>
      </div>

      <v-divider class="mb-6 mt-12"></v-divider>
      <h2 class="text-h5 font-weight-bold mb-8">共通情報</h2>

      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1 fco">ステータス</h3>
        <v-radio-group
          v-model="flightSpot.status" 
          class="mt-0"
          row
        >
        <v-radio :value="1">
          <template v-slot:label>
            <div class="subtitle-2">表示</div>
          </template>
        </v-radio>
        <v-radio :value="0">
          <template v-slot:label>
            <div class="subtitle-2">非表示</div>
          </template>
        </v-radio>
        </v-radio-group>
      </div>

      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1 fco">スポットタイプ</h3>
        <v-radio-group
          v-model="flightSpot.type" 
          class="mt-0"
          row
        >
          <v-radio :value="1">
            <template v-slot:label>
              <div class="subtitle-2">フライトスポット</div>
            </template>
          </v-radio>
          <v-radio :value="2">
            <template v-slot:label>
              <div class="subtitle-2">禁止スポット</div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>


      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1 fco">認証</h3>
        <v-radio-group
          v-model="flightSpot.certify" 
          class="mt-0"
          row
        >
          <v-radio :value="true">
            <template v-slot:label>
              <div class="subtitle-2">認証済</div>
            </template>
          </v-radio>
          <v-radio :value="false">
            <template v-slot:label>
              <div class="subtitle-2">未認証</div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>

      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1">スポット名</h3>
        <v-text-field
          placeholder="スポット名"
          v-model="flightSpot.name"
          outlined
          :rules="[rules.required]"
          dense
        />
      </div>

      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1">都道府県</h3>
        <v-select
          v-model="flightSpot.prefCode"
          :items="prefectures"
          outlined
          placeholder="都道府県"
          :rules="[rules.required]"
          item-text="prefName"
          item-value="prefCode"
          dense
        />
      </div>

      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1">市町村</h3>
        <v-text-field
          placeholder="市町村"
          v-model="flightSpot.municipality"
          dense
          outlined
        />
      </div>

      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1">住所</h3>
        <v-text-field
          placeholder="住所"
          v-model="flightSpot.address"
          dense
          outlined
        />
      </div>

      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1">タグ</h3>
        <v-text-field
          placeholder="タグ"
          v-model="flightSpot.tags"
          dense
          outlined
        />
      </div>

      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1">管轄の警察署</h3>
        <v-text-field
          placeholder="管轄する警察署名"
          outlined
          dense
          v-model="flightSpot.police"
        />
      </div>

      <h3 class="subtitle-1 font-weight-bold mb-1">土地管理者・確認先がわかる場合は入力してください</h3>
      <div class="" v-for="placeAdmin in flightSpot.placeAdmins" :key="placeAdmin.id">
        <div class="mb-0">
          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <v-text-field
                placeholder="土地管理者名・確認先の団体名"
                outlined
                dense
                v-model="placeAdmin.name"
                class="mr-0 mr-sm-2"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                placeholder="連絡先"
                outlined
                dense
                v-model="placeAdmin.contact"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </div>
      </div>
      <div>
        <v-btn
          @click="addPlaceAdmin"
          dense
          depressed
          small
          class="mb-12 mr-3"
          >
          <span class="font-weight-bold">追加</span>
        </v-btn>
        <v-btn
          @click="subPlaceAdmin"
          dense
          depressed
          small
          class="mb-12"
          >
          <span class="font-weight-bold">削除</span>
        </v-btn>
      </div>

      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1 fco">駐車場</h3>
        <v-radio-group
          v-model="flightSpot.parking" 
          class="mt-0"
          row
        >
          <v-radio :value="'yes'">
            <template v-slot:label>
              <div class="subtitle-2">有り</div>
            </template>
          </v-radio>
          <v-radio :value="'no'">
            <template v-slot:label>
              <div class="subtitle-2">無し</div>
            </template>
          </v-radio>
          <v-radio :value="'unknown'">
            <template v-slot:label>
              <div class="subtitle-2">わからない</div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>

      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1 fco">トイレ</h3>
        <v-radio-group
          v-model="flightSpot.restroom" 
          class="mt-0"
          row
        >
          <v-radio :value="'yes'">
            <template v-slot:label>
              <div class="subtitle-2">有り</div>
            </template>
          </v-radio>
          <v-radio :value="'no'">
            <template v-slot:label>
              <div class="subtitle-2">無し</div>
            </template>
          </v-radio>
          <v-radio :value="'unknown'">
            <template v-slot:label>
              <div class="subtitle-2">わからない</div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>

      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1">DID</h3>
        <v-radio-group
          v-model="flightSpot.did" 
          class="mt-0"
          row
        >
          <v-radio :value="true">
            <template v-slot:label>
              <div class="subtitle-2">True</div>
            </template>
          </v-radio>
          <v-radio :value="false">
            <template v-slot:label>
              <div class="subtitle-2">False</div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>

      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1">緯度経度</h3>
        <v-text-field
          placeholder="緯度経度"
          v-model="flightSpot.center"
          outlined
          dense
        />
      </div>

      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1">ズーム値</h3>
        <v-text-field
          placeholder="初期ズーム値"
          v-model="flightSpot.zoom"
          outlined
          dense
        />
      </div>

      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1">最小予約日</h3>
        <v-text-field
          placeholder="最小予約日"
          v-model="flightSpot.reservedMinDay"
          outlined
          dense
        />
      </div>

      <v-divider class="mb-6 mt-12"></v-divider>
      <h2 class="text-h5 font-weight-bold mb-8">認証済関連まわりで必要な項目</h2>

      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1">ルール</h3>
        <v-textarea
          placeholder="ルール（改行区切り）"
          v-model="flightSpot.rules"
          dense
          outlined
        />
      </div>

      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1">注意事項</h3>
        <v-textarea
          placeholder="注意事項（改行区切り）"
          v-model="flightSpot.cautions"
          outlined
        />
      </div>

      <div class="mt-8 mb-8"></div>
      <h3 class="text-h6 font-weight-bold mb-6">飛行範囲</h3>

      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1">飛行範囲の緯度経度</h3>
        <div v-for="(latlng, index) in flightSpot.latlngs" :key="`first-${index}`">
          <v-textarea
            :placeholder="'飛行範囲' + (index+1) + 'の緯度経度'"
            v-model="latlng.value"
            outlined
          ></v-textarea>
        </div>
        <v-btn
          class="mb-12"
          @click="addLatlng"
          depressed
          color="teal lighten-2"
          small
        >
          <span class="font-weight-bold white--text">＋ 追 加</span>
        </v-btn>
        <v-btn
          class="mb-12"
          @click="subLatlng"
          depressed
          color="teal lighten-2 ml-4"
          small
        >
          <span class="font-weight-bold white--text">− 削 除</span>
        </v-btn>
      </div>

      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1">離発着場所の緯度経度</h3>
        <div v-for="(launchSite, index) in flightSpot.launchSites" :key="`second-${index}`">
          <v-textarea
            :placeholder="'離発着場所' + (index+1) + 'の緯度経度'"
            v-model="launchSite.value"
            outlined
          ></v-textarea>
        </div>
        <v-btn
          class="mb-12"
          @click="addLaunchSite"
          depressed
          color="teal lighten-2"
          small
        >
          <span class="font-weight-bold white--text">＋ 追 加</span>
        </v-btn>
        <v-btn
          class="mb-12"
          @click="subLaunchSite"
          depressed
          color="teal lighten-2 ml-4"
          small
        >
          <span class="font-weight-bold white--text">− 削 除</span>
        </v-btn>
      </div>

      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1">駐車場の緯度経度</h3>
        <div v-for="(parking, index) in flightSpot.parkings" :key="`therd-${index}`">
          <v-textarea
            :placeholder="'駐車場' + (index+1) + 'の緯度経度'"
            v-model="parking.value"
            outlined
          ></v-textarea>
        </div>
        <div>
          <v-btn
            class="mb-12"
            @click="addParking"
            depressed
            color="teal lighten-2"
            small
          >
            <span class="font-weight-bold white--text">＋ 追 加</span>
          </v-btn>
          <v-btn
            class="mb-12"
            @click="subParking"
            depressed
            color="teal lighten-2 ml-4"
            small
          >
            <span class="font-weight-bold white--text">− 削 除</span>
          </v-btn>
        </div>
      </div>

      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1">マップ中央用の緯度経度</h3>
        <v-text-field
          placeholder="マップ中央用の緯度経度"
          v-model="flightSpot.mapCenter"
          outlined
          dense
        />
      </div>

      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1">作成日時</h3>
        <v-row>
          <v-col>
            <v-text-field
                  v-model="formattedCreatedAt.year"
                  label="年"
                  :rules="[rules.required, rules.yearTooFuture, rules.yearTooPast]"
                  type="number"
                  :min="2000"
                  :max="2050"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
                  v-model="formattedCreatedAt.month"
                  label="月"
                  :rules="[rules.required, rules.withinMonths]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
                  v-model="formattedCreatedAt.date"
                  label="日"
                  :rules="[rules.required, rules.withinDays]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
                  v-model="formattedCreatedAt.hour"
                  label="時"
                  :rules="[rules.withinHours, rules.notEmpty]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
                  v-model="formattedCreatedAt.minute"
                  label="分"
                  :rules="[rules.within60, rules.notEmpty]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
                  v-model="formattedCreatedAt.second"
                  label="秒"
                  :rules="[rules.within60, rules.notEmpty]"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <div>
        <h3 class="subtitle-1 font-weight-bold mb-1">更新日時</h3>
        <p>{{UpdateAtForDisplay}}</p>
      </div>

      <v-btn
        @click="checkValidate"
        color="primary"
        rounded
        large
        depressed
      >
        <span class="font-weight-bold">この内容で{{ isUpdate ? '更新' : '登録' }}する</span>
      </v-btn>
      <p class="subtitle-2 mt-10"><router-link to="/admin/flightspot/list" class="no-under-line">一覧に戻る</router-link></p>
      <v-snackbar
        v-model="snackBar.show"
        :color="snackBar.color"
        top
        center
        transition="slide-y-transition"
        :timeout="6000"
      >
      <span class="subtitle-2 font-weight-bold">{{snackBar.message}}</span>
      </v-snackbar>
    </v-form>
  </div>
</template>

<script>
import firebase from 'firebase'
import prefectures from '@/assets/prefcode.json'

export default {
  data() {
    return {
      isUpdate:false,
      flightSpot: [],
      userid:'',
      rules: {
        required: v => !!v || '必須です.',
        yearTooFuture: v => v < 2100 || '数字が未来すぎます',
        yearTooPast: v => v > 2019 || '数字が過去すぎます',
        withinMonths: v => v>0 && v<=12 || '月の範囲から逸脱しています',
        withinDays: v => v>0 && v<=31 || '日の範囲から逸脱しています',
        withinHours: v => v>=0 && v<=24 || '時の範囲から逸脱しています',
        within60: v => v>=0 && v<60 || '60を逸脱しています',
        notEmpty: v=> String(v) != '' || '値がありません'+v
      },
      snackBar: {
        show: false,
        color: '',
        message: ''
      },
      prefectures:prefectures,
      formattedCreatedAt:{
        year:'',
        month:'',
        date:'',
        hour:'',
        minute:'',
        second:''
      },
      UpdateAtForDisplay:''
    }
  },
  beforeCreate() {
    if(this.$store.getters.idToken.email != 'info@flyers.plus' && this.$store.getters.idToken.email != 'supercub47@gmail.com'){
      this.$router.push('/home')
    }
  },
  methods: {
    checkValidate() {
      if(this.$refs.form.validate()) {
        this.update()
      } else {
        this.snackBar.show = true
        this.snackBar.message = "入力漏れなどがあるよ"
        this.snackBar.color = "#FF3300"
      }
    },
    update(){
      var self = this
      this.flightSpot.tags = this.setTagData(this.flightSpot.tags)
      this.flightSpot.rules = this.setArrayData(this.flightSpot.rules)
      this.flightSpot.cautions = this.setArrayData(this.flightSpot.cautions)
      this.flightSpot.updateAt = firebase.firestore.FieldValue.serverTimestamp()

      this.flightSpot.createdAt.seconds = this.calculateUnixTime(this.formattedCreatedAt)

      firebase.firestore().collection("userFlightSpots")
      .doc(this.$route.params.id)
      .update(this.flightSpot)
      .then(function() {
        self.snackBar.show = true
        self.snackBar.message = self.flightSpot.name + "の更新が完了しました"
        self.snackBar.color = "success"

        self.loadData()
      })
      .catch(function(error) {
        console.error("Error writing document: ", error);
      });
    },
    addPlaceAdmin() {
      this.flightSpot.placeAdmins.push({name:'',contact:''})
    },
    subPlaceAdmin() {
      this.flightSpot.placeAdmins.pop()
    },
    addLatlng() {
      this.flightSpot.latlngs.push({ value:''})
    },
    subLatlng() {
      this.flightSpot.latlngs.pop()
    },
    addLaunchSite() {
      this.flightSpot.launchSites.push({ value:''})
    },
    subLaunchSite() {
      this.flightSpot.launchSites.pop()
    },
    addParking() {
      this.flightSpot.parkings.push({ value:''})
    },
    subParking() {
      this.flightSpot.parkings.pop()
    },
    setArrayData(array) {
      var arr = array.split('\n')
      return arr == '' ? []: arr
    },
    setTagData(array) {
      var arr = []
      if(array.length != 0){
        arr = array.split(',')
      }
      return arr
    },
    loadData(){
      var self = this

      firebase.firestore().collection("userFlightSpots")
      .where("id", "==", this.$route.params.id)
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          self.flightSpot = doc.data()
          self.userid = doc.data().user.id
          self.flightSpot.tags = self.flightSpot.tags.join(',')
          self.flightSpot.rules = self.flightSpot.rules.join('\n')
          self.flightSpot.cautions = self.flightSpot.cautions.join('\n')
          self.flightSpot.updateAt = self.flightSpot.updateAt.toDate()
        })
        self.isUpdate = querySnapshot.size >= 1

        self.formattedCreatedAt.year = self.flightSpot.createdAt.toDate().getFullYear()
        self.formattedCreatedAt.month = self.flightSpot.createdAt.toDate().getMonth()+1
        self.formattedCreatedAt.date = self.flightSpot.createdAt.toDate().getDate()
        self.formattedCreatedAt.hour = self.flightSpot.createdAt.toDate().getHours()
        self.formattedCreatedAt.minute = self.flightSpot.createdAt.toDate().getMinutes()
        self.formattedCreatedAt.second = self.flightSpot.createdAt.toDate().getSeconds()
        
        self.UpdateAtForDisplay = self.formatUpdateAt(self.flightSpot.updateAt)
      })
      .catch(function(error) {
        console.log("Error getting documents: ", error)
      })
    },
    //calculate Unixtime for registering firebase timestamp
    calculateUnixTime(FormattedDate){
      var UnixTimeFromFormattedDate = new Date (FormattedDate.year, FormattedDate.month-1, FormattedDate.date, FormattedDate.hour, FormattedDate.minute).getTime()
      return Math.round(UnixTimeFromFormattedDate/1000)
    },

    // Function to convert updateAt from UnixTime to a readable format for display
    formatUpdateAt(updateAt) {
      var year = updateAt.getFullYear();
      var month = ('00' + (updateAt.getMonth()+1)).slice(-2);
      var day = ('00' + updateAt.getDate()).slice(-2);
      var week = ['日','月','火','水','木','金','土'][updateAt.getDay()]
      var hour = updateAt.getHours()
      var minute = ('00' + updateAt.getMinutes()).slice(-2)
      var second = updateAt.getSeconds()
      return (year + '年' + month + '月' + day + '日（' + week +'） '+ hour + '時' + minute + '分' +second+'秒')
    },    
  },
  created() {
    this.loadData()
  }
}
</script>