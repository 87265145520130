<template>
  <div>
    <h1 class="mb-6">
      <div class="pc">
        <v-img
          :src="require('@/assets/img/agent_back.jpg')" 
          class="back"
        >
        <v-img
          width="60%"
          :src="require('@/assets/img/agent_copy.svg')" 
          class="copy"
        />
        <v-img
          width="150px"
          :src="require('@/assets/img/agent_top.svg')" 
          class="top"
        />
        <p class="caption text-right mr-1 mt-1">
        Photo by <a href="https://unsplash.com/@kenart?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Kenart Abrasaldo</a> on <a href="https://unsplash.com/@supercub47/likes?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
        </p>
        </v-img>
      </div>

      <div class="sp mt-13">
        <v-img
          :src="require('@/assets/img/agent_back_sp.jpg')" 
          class="back"
        >
        <v-img
          width="80%"
          :src="require('@/assets/img/agent_copy.svg')" 
          class="copy"
        />
        <v-img
          width="130px"
          :src="require('@/assets/img/agent_top.svg')" 
          class="top"
        />
        <p class="caption text-right mr-2 mt-2">
        Photo by <a href="https://unsplash.com/@kenart?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Kenart Abrasaldo</a> on <a href="https://unsplash.com/@supercub47/likes?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
        </p>
        </v-img>
      </div>
    </h1> 

    <section id="service">
      <h2> 
        <div class="d-flex align-end justify-center mb-0 sm-mb-4">
          <span class="text-h5 text-sm-h4 font-weight-bold">飛行手続申請代行</span>
        </div>
      </h2>
      <p class="subtitle-1 text-sm-h6 mb-0 text-center mb-4 mb-sm-16 mt-2">面倒な土地管理者の飛行手続きを最小限に</p>
      <v-img
        maxWidth="900px"
        width="80%"
        class="ma-auto mb-16"
        :src="require('@/assets/img/agent_flow.svg')" 
      />
    </section>

    <v-img
      :src="require('@/assets/img/guide_cando_top.svg')" 
    />
    <section id="cando">
      <h2 class="text-h5 text-sm-h4 font-weight-bold mb-0 sm-mb-4">飛行手続申請代行の内容</h2>
      <p class="subtitle-1 text-sm-h6 mb-0 text-center mb-12 mt-2">土地管理者への飛行手続きと、管轄の警察署への連絡まで行います</p>
      <div class="grid text-left text-sm-center pb-6 pb-sm-16">
        <div>
          <div>
            <v-img
              class="ml-14 mr-14 mb-5 ml-sm-8 mr-sm-8 mb-sm-6"
              :src="require('@/assets/img/agent_description01.svg')" 
            />
          </div>
          <div>
            <h3 class="text-h6 font-weight-bold mb-2 mt-4">土地管理者の確認と連絡</h3>
            <p class="subtitle-1">申請場所に関するすべての土地管理者を調べ連絡をし、ドローン飛行に必要な手続きを確認します。</p>
          </div>
        </div>

        <div>
          <div>
            <v-img
              class="ml-14 mr-14 mb-5 ml-sm-8 mr-sm-8 mb-sm-6"
              :src="require('@/assets/img/agent_description02.svg')" 
            />
          </div>
          <div>
            <h3 class="text-h6 font-weight-bold mb-2 mt-4">届け出・申請書作成</h3>
            <p class="subtitle-1">飛行手続きにあたり、届け出や申請書が必要な場合も、Flyersが作成し手続きを行います。</p>
          </div>
        </div>

        <div>
          <div>
            <v-img
              class="ml-14 mr-14 mb-5 ml-sm-8 mr-sm-8 mb-sm-6"
              :src="require('@/assets/img/agent_description03.svg')" 
            />
          </div>
          <div>
            <h3 class="text-h6 font-weight-bold mb-2 mt-4">管轄の警察署への連絡</h3>
            <p class="subtitle-1">不意の通報やトラブルに備えるため、管轄の警察署に飛行計画の事前共有を行います。</p>
          </div>
        </div>
      </div>

      <div class="mt-16">
        <h2 class="text-h5 text-sm-h4 font-weight-bold mb-0 sm-mb-4">飛行確認証明書の発行</h2>
        <p class="subtitle-1 text-sm-h6 mb-0 text-center mb-2 mt-2">Flyersが確認や連絡を行った管理者情報、管轄の警察署情報は書面として発行します</p>
        <p class="subtitle-2 text-sm-subtitle-1 text-center mb-12">当日現地でドローンを飛行させている際、第三者に確認を求められた場合などにご利用ください。</p>
        <v-img
          maxWidth="500px"
          width="80%"
          class="ma-auto"
          :src="require('@/assets/img/agent_certificate.jpg')" 
        />
      </div>

    </section>
    <v-img
      :src="require('@/assets/img/guide_cando_bottom.svg')" 
    />

    <section id="fee">
      <h2 class="text-h5 text-sm-h4 font-weight-bold mb-4">ご利用料金</h2>
      <p class="text-h4 mb-4 text-center">無料</p>
      <p class="subtitle-2 text-sm-subtitle-1 mb-0 text-center">ベータ期間中のため5月末まで無料でご提供しております。</p>
      <p class="subtitle-2 text-sm-subtitle-1 text-center">この機会にぜひ一度ご利用ください。</p>
    </section>

    <section id="agent-qa">
      <h2 class="text-h5 text-sm-h4 font-weight-bold mb-4">よくあるご質問</h2>

    <v-expansion-panels
      accordion
      multiple
      flat
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h3 class="subtitle-1 text-sm-h6 font-weight-bold"><span class="text-h6">Q</span> ： ドローンに関する資格や免許を持っていなくてもFlyersを利用できますか？</h3>
          </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="mt-0 mb-0">大丈夫です、ぜひご利用ください。</p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <h3 class="subtitle-1 text-sm-h6 font-weight-bold"><span class="text-h6">Q</span> ： 利用申請は飛ばしに行く何日までにする必要がありますか？</h3>
          </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="mt-0 mb-0">フライトスポットごとに手続きの内容が変わるため必要な日数は違いますが、平均約7営業日お時間をいただいています。</p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <h3 class="subtitle-1 text-sm-h6 font-weight-bold"><span class="text-h6">Q</span> ： なんで無料で出来るんですか？</h3>
          </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="mt-0 mb-0">現在ご利用いただいているFlyersはβ版で、今夏の正式サービス開始に向け様々な検証を行っているためです。</p>
          <p class="mb-0">この機会にぜひご利用いただき、ご意見・ご感想などフィードバックをいただくことで、より充実したサービスのご提供に繋がると考えています。</p>
          <p class="mb-0">皆さまのご協力よろしくお願いいたします。</p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <h3 class="subtitle-1 text-sm-h6 font-weight-bold"><span class="text-h6">Q</span> ： 5月末まで無料とのことですが、6月以降はいくらになる予定ですか？</h3>
          </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="mt-0 mb-0">現在まだご利用料金は決まっていません。<br>実際に利用申請をご利用いただいた方々へのアンケートへ行いいただいたご回答を元に決定する予定です。</p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <h3 class="subtitle-1 text-sm-h6 font-weight-bold"><span class="text-h6">Q</span> ： キャンセル料金はかかりますか？</h3>
          </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="mt-0 mb-0">かかりません。</p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <h3 class="subtitle-1 text-sm-h6 font-weight-bold"><span class="text-h6">Q</span> ： どのスポットでも利用申請できますか？</h3>
          </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="mt-0 mb-0">現在は『認証済フライトスポット』でのみ利用申請が可能です。</p>
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>

    </section>
  </div>
</template>
<script>
export default {
  mounted() {
    document.title = this.$route.meta.title
  },
}
</script>
<style lang="scss" scoped>
$maxWidth: 1000px;

p {
  text-align: justify;
}

.accent-color {
  color: #00A99D;
}

.back {
  position: relative;
  .copy {
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
  }
  .top {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}

#feature {
  max-width: $maxWidth;
  margin: 0 auto 100px auto;
  padding: 0 40px;
}

#cando {
  margin: 0 auto;
  background-color: #F8F8F8;
  padding: 100px 40px;
  .grid {
    max-width: $maxWidth;
    margin: 0 auto;
    padding: 0 40px;
    display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-content: center;
    gap: 40px;
  }
}

#fee {
  max-width: $maxWidth;
  margin: 100px auto;
  padding: 0 40px;
}

#agent-qa {
  width: 95%;
  max-width: 700px;
  margin: 0 auto;

}

@media (max-width: 768px) {

  #fee {
    padding: 0 20px;
    margin: 60px auto 100px auto;
  } 

  #cando {
    padding: 100px 40px;
    .grid {
      padding: 0 10px;
    }
  }

}

</style>