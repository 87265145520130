<template>
  <div class="user-home mt-10">
    <UserMenu 
     :userName="this.$store.getters.displayName" 
    />
      <section class="user-profile-main">
        <h1 class="text-h4 font-weight-bold mb-8">プロフィール編集</h1>
        <v-form ref="form">
          <div class="mb-8">
            <h3 class="subtitle-2 font-weight-bold mb-4">ユーザーアイコン</h3>
            <div class="user-icon">
              <div class="mr-6">
                <v-avatar
                size="80">
                  <img
                    :src="src"
                  >
                </v-avatar>
              </div>
              <div>
                <v-file-input 
                  outlined
                  accept="image/*" 
                  label="画像をアップロードして設定する。" 
                  @change="onFileUpload"
                  ref="preview"
                  class="pt-3"
                ></v-file-input>
              </div>
            </div>
          </div>

          <div class="mb-4">
            <h3 class="subtitle-2 font-weight-bold mb-1">ユーザー名</h3>
            <v-text-field
            placeholder="ユーザー名を設定してください"
            outlined
            v-model="form.newDisplayName"
            :rules="[rules.required]"
            counter="40"
            maxlength="40"
            >
            </v-text-field>
          </div>

          <div class="mb-4">
            <h3 class="subtitle-2 font-weight-bold mb-1">自己紹介</h3>
            <v-textarea
            placeholder="自己紹介"
            outlined
            v-model="form.selfIntroduction"
            counter="500"
            maxlength="500"
            >
            </v-textarea>
          </div>

          <div>
            <h3 class="subtitle-2 font-weight-bold mb-1">おもな活動場所</h3>
            <v-text-field
            placeholder="場所"
            v-model="form.activityBase"
            outlined
            >
            </v-text-field>
          </div>

          <div>
            <h3 class="subtitle-2 font-weight-bold mb-1">Youtube（URL）</h3>
            <v-text-field
            placeholder="チャンネルのURLまたは動画のURL"
            v-model="form.youtube"
            outlined
            :rules="[rules.youtubeUrl]"
            >
            </v-text-field>
          </div>

          <div>
            <h3 class="subtitle-2 font-weight-bold mb-1">Twitter（ID）</h3>
            <v-text-field
            placeholder="例）@flyers_plus"
            v-model="form.twitter"
            outlined
            >
            </v-text-field>
          </div>

          <div>
            <h3 class="subtitle-2 font-weight-bold mb-1">Instagram（ID）</h3>
            <v-text-field
            placeholder="例）flyers_plus"
            v-model="form.instagram"
            outlined
            >
            </v-text-field>
          </div>

          <div class="mb-4">
            <h3 class="subtitle-2 font-weight-bold mb-1">Webサイト（URL）</h3>
            <v-text-field
            placeholder="https://"
            v-model="form.web"
            outlined
            :rules="[rules.siteUrl]"
            >
            </v-text-field>
          </div>
          <v-btn
            depressed
            color="primary font-weight-bold"
            @click="submit"
            :loading="status"
            :disabled="status"
          >
            保存する
          </v-btn>
        </v-form>

        <v-snackbar
          v-model="snackBar.show"
          :color="snackBar.color"
          top
          center
          :timeout="6000"
        >
          <span class="font-weight-bold">{{snackBar.message}}</span>
        </v-snackbar>
      </section>
  </div>
</template>

<script>
import firebase from 'firebase'
import UserMenu from '@/components/UserMenu.vue'
import Compressor from 'compressorjs'

export default {
  components: {
    UserMenu
  },
  data() {
    return {
      src:'/img/icon_blank.svg',
      isUpdate: false,
      status: false,
      docId: '',
      user: [],
      displayName: '',
      newDisplayName: '',
      rules: {
        valid:false,
        required: v => !!v || '必須です.',
        youtubeUrl: v => this.checkYoutubeUrl(v) || 'URLが正しくありません',
        siteUrl: v => this.checkUrl(v) || 'URLが正しくありません',
      },
      snackBar: {
        show: false,
        color: '',
        message: ''
      },
      form: {
        newDisplayName: '',
        selfIntroduction: '',
        activityBase: '',
        youtube: '',
        twitter: '',
        instagram: '',
        web: '',
      },
      a:false,
      file:''
    }
  },
  methods: {
    onFileUpload(file) {
      var self = this

      if(file != null){
        new Compressor(file, {
          quality: 0.6,
          width: 512,
          height: 512,
          success(result) {
            self.file = result
            self.src = URL.createObjectURL(result)
          },
          error(err) {
            console.log(err.message);
          }
        })
      }
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.status = true
        var self = this
        if(this.file != ""){
          var storage = firebase.storage()
          var storageRef = storage.ref()
          var imagesRef = storageRef.child('users/'+ self.$store.getters.user.uid +'/icon')
          var spaceRef = imagesRef.child('iconFile')
          var contentType = this.file.type
          var uploadTask = spaceRef.put(this.file,contentType)

          uploadTask.on('state_changed', function(snapshot){ // eslint-disable-line
            }, function(error) { // eslint-disable-line
            }, function() {
            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
              self.src = downloadURL
              self.userSave()
            })
          })
        } else {
          self.userSave()
        }
      } else {
        this.snackBar.show = true
        this.snackBar.message = '必須項目が入力されていません'
        this.snackBar.color = "error"
        this.status = false
      }
    },
    getUserData() {
      var db = firebase.firestore()
      var docs = []

      db.collection("users").where('userId','==', this.$store.getters.user.uid)
      .get()
      .then(querySnapshot => {
        if(querySnapshot.size >=1){
          querySnapshot.forEach(doc => {
            docs = doc.data()
            this.docId = doc.id
          })
          this.src = docs.userIconSrc
          this.form.newDisplayName = docs.displayName
          this.form.selfIntroduction = docs.selfIntroduction
          this.form.activityBase = docs.activityBase
          this.form.youtube = docs.youtube
          this.form.twitter = docs.twitter
          this.form.instagram = docs.instagram != null ? docs.instagram : ''
          this.form.web = docs.web

          this.isUpdate = true
        }
      })
      .catch(function(error) {
        console.log("ERROR: ", error);
      })
    },
    userSave() {
      if(this.isUpdate){
        this.userUpdate()
      } else {
        this.userAdd()
      }
    },
    userUpdate() {
      var db = firebase.firestore()
      var self = this
      db.collection("users").doc(this.docId).update({
        userId: self.$store.getters.user.uid,
        displayName: self.form.newDisplayName,
        userIconSrc: self.src,
        selfIntroduction: self.form.selfIntroduction,
        activityBase: self.form.activityBase,
        youtube: self.form.youtube,
        twitter: self.form.twitter,
        instagram: self.form.instagram,
        web: self.form.web,
        updateAt: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(function() {
        self.snackBar.show = true
        self.snackBar.message = '更新しました'
        self.snackBar.color = "success"
        self.status = false

        self.$store.commit('updateUserIconSrc', self.src)
        self.$store.commit('updateDisplayName', self.form.newDisplayName)
      })
      .catch(function(error) {
        console.error("Error writing document: ", error);
      })
    },
    userAdd() {
      var db = firebase.firestore()
      var self = this
      db.collection("users").add({
        userId: self.$store.getters.user.uid,
        displayName: self.form.newDisplayName,
        userIconSrc: self.src,
        selfIntroduction: self.form.selfIntroduction,
        activityBase: self.form.activityBase,
        youtube: self.form.youtube,
        twitter: self.form.twitter,
        instagram: self.form.instagram,
        web: self.form.web,
        updateAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(function() {
        self.snackBar.show = true
        self.snackBar.message = '保存しました'
        self.snackBar.color = "success"
        self.status = false

        self.isUpdate = true

        self.$store.commit('updateUserIconSrc', self.src)
        self.$store.commit('updateDisplayName', self.form.newDisplayName)

        self.getUserData()
      })
      .catch(function(error) {
        console.error("Error writing document: ", error);
      })
    },
    checkYoutubeUrl(url) {
      if ( url.match(/https:\/\/www.youtube.com\//) || url.match(/https:\/\/youtu.be\//) || url == '') {
        return true
      } else {
        return false
      }
    },
    checkUrl(url) {
      if ( url.match(/https:\/\//) || url.match(/http:\/\//) || url == '') {
        return true
      } else {
        return false
      }
    },
  },
  created() {
    this.getUserData()
  },
  mounted() {
    document.title = this.$route.meta.title
  },
}
</script>

<style lang="scss">
.user-home {
  margin: 0 40px 0 40px;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 230px 1fr;
}

.user-profile-main{
  text-align: left;
  margin-left: 90px;
  .tops {
    display: flex;
    align-items: center;
  }
  .user-icon {
    display: grid;
    grid-template-columns: 100px 1fr;
  }
}

@media (max-width: 768px) {

  .user-profile-main {
    margin-top: 40px;
    min-width: initial;
    margin-left: 0px;
  }
  
}

</style>