<template>
  <div class="container">
      <router-link :to="'/post'" class="no-under-line">
      <v-btn
        color="primary"
        depressed
      >
        <span class="font-weight-bold">スポット新規登録</span>
      </v-btn>
    </router-link>
    <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    <template>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :loading="isTableLoad"
        class="elevation-1 mt-6"
      >
        <template v-slot:item.actions="{ item }"> 
          <router-link :to="{ path: 'edit/' + item.id, params: { id:item.id }}" class="no-under-line">
            <v-btn
              small
              depressed
            >
            <v-icon small class="mr-1">mdi-pencil</v-icon>
            <span class="">編集</span>
            </v-btn>
          </router-link>
        </template>
        <template v-slot:item.name="{ item }">
          <router-link :to="{ path: '/userspot/' + item.id + '/detail', params: { id:item.id }}" class="no-under-line">
            {{ item.name }}
          </router-link>
        </template>

        <template v-slot:item.did="{ value }">
            {{ value ? '該当':'非該当' }}
        </template>

        <template v-slot:item.did="{ value }">
            {{ value ? '該当':'非該当' }}
        </template>

        <template v-slot:item.type="{ value }">
            <v-img
            width="24"
            :src="getTypeIconSrc(value)"
            />
        </template>

        <template v-slot:item.certify="{ value }">
          <v-img 
            width="24"
            v-bind:class="{'opa': !value }"
            :src="require('@/assets/img/icon_cirtify.svg')"/>
        </template>

        <template v-slot:item.status="{ value }">
          <v-chip
            :color="getColor(value)"
            label
            small
            text-color="white"
            class="font-weight-bold"
          >
            {{ status[value] }}
          </v-chip>
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
import firebase from 'firebase'

  export default {
    data () {
      return {
        search:'',
        headers: [
          { text: '認証', value: 'certify' },
          { text: 'タイプ', value: 'type' },
          { text: 'status', value: 'status' },
          { text: 'フライトスポット名', value: 'name' },
          { text: '都道府県No', value: 'prefCode' },
          { text: '都道府県', value: 'prefecture' ,sortable:false },
          { text: 'DID', value: 'did' ,sortable:false },
          { text: '最小予約日', value: 'reservedMinDay' },
          { text: '', value: 'actions', sortable:false }
        ],
        desserts: [],
        lastId: 0,
        status: ['非表示','表示'],
        isTableLoad:true
      }
    },
    beforeCreate() {
      if(this.$store.getters.idToken.email != 'info@flyers.plus' && this.$store.getters.idToken.email != 'supercub47@gmail.com'){
        this.$router.push('/home')
      }
    },
    created() {
      var db = firebase.firestore()
      var self = this

      db.collection("userFlightSpots")
      .orderBy("createdAt", "desc")
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            self.desserts.push(doc.data())
        });

        self.desserts.sort(function(a, b) {
            return b.id - a.id 
        })

        self.lastId = self.desserts[0].id + 1
        self.isTableLoad = false
      });
    },
    methods: {
      getColor (value) {
        if (value == 1) return 'teal lighten-2'
        else if (value == 0) return 'grey lighten-1'
        else return 'orange'
      },
      getTypeIconSrc(type) {
        switch (type) {
          case parseInt(process.env.VUE_APP_CIRTIFICATE_FLIGHT_SPOT_VALUE):
          return require('@/assets/img/c.svg')
          case parseInt(process.env.VUE_APP_FLIGHT_SPOT_VALUE):
          default:
          return require('@/assets/img/y.svg')
          case parseInt(process.env.VUE_APP_NOT_FLIGHT_SPOT_VALUE):
          return require('@/assets/img/z.svg')
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.opa {
  opacity: 0.15;
}
</style>