import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    idToken: null,
    user: null,
    flightSpot: null
  }, 
  getters: {
    idToken: state => state.idToken,
    displayName: state => state.displayName,
    userIconSrc: state => state.userIconSrc,
    user: state => state.user,
    flightSpot: state => state.flightSpot,
    userFlightSpotSelectValue: state => state.userFlightSpotSelectValue,
    searchValue: state => state.searchValue,
  },
  mutations: {
    updateIdToken(state, idToken) { 
      state.idToken = idToken
    },
    updateDisplayName(state, displayName) { 
      state.displayName = displayName
    },
    updateUserIconSrc(state, userIconSrc) { 
      state.userIconSrc = userIconSrc
    },
    updateUser(state, user) { 
      state.user = user
    },
    updateFlightSpot(state, flightSpot) { 
      state.flightSpot = flightSpot
    },
    userFlightSpotSelectValue(state, userFlightSpotSelectValue) { 
      state.userFlightSpotSelectValue = userFlightSpotSelectValue
    },
    searchValue(state, searchValue) { 
      state.searchValue = searchValue
    }
  },
  plugins: [createPersistedState(
    { // ストレージのキーを指定。デフォルトではvuex
      // ストレージの種類を指定する。デフォルトではローカルストレージ
      storage: window.localStorage
    }
  )]
})