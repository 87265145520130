<template>
  <footer>
    <div class="links text-left">
      <v-img 
      max-width="80"
      class="mb-3"
      :src="require('../assets/img/corplogo.svg')" @click="scrollTop">
      </v-img>
      <div class="caption mb-1">
        <router-link to="/about" class="mr-4 no-under-line">運営会社</router-link>
        <router-link to="/terms" class="mr-4 no-under-line">利用規約</router-link>
        <router-link to="/privacy" class="mr-4 no-under-line">プライバシーポリシー</router-link>
        <router-link to="/guidelines" class="mr-4 no-under-line">コミュニティガイドライン</router-link>
        <router-link to="/faq" class="mr-4 no-under-line">よくある質問</router-link>
        <a href="https://trello.com/b/nsjpwRIu/flyersroadmap" target="_blank" class="mr-4 no-under-line">開発ロードマップ</a>
        <a href="https://forms.gle/8ZddA4qq4K839ngh7" target="_blank" class="mr-4 no-under-line">お問い合わせ</a>
      </div>
      <p class="caption">©Flyers,Inc All Rights Reserved.</p>
    </div>
    <div class="sns">
      <a href="https://twitter.com/flyers_plus" target="_blank" class="no-under-line"><v-icon class="mr-4">fab fa-twitter</v-icon></a>
      <a href="https://www.facebook.com/flyers.plus" target="_blank" class="no-under-line"><v-icon>fab fa-facebook</v-icon></a>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {
    scrollTop: function() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  },
}
</script>

<style lang="scss" scoped>
footer {
  display: flex;
  padding: 80px 20px 20px 20px;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0 !important;
    padding: 0;
  }
  .links {
    a {
      color: initial;
    }
  }
}

@media (max-width: 600px) {
  footer {
    padding: 80px 20px 20px 15px;
    flex-direction: column;
    align-items: initial;
    .sns {
      margin: 20px auto 0 0;
    }
    .links {  
      a {
        display: block;
        margin-bottom: 5px;
      }
    }
  }
}
</style>