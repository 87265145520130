<template>
  <div class="aaaaa">
      <div class="bababa text-left">
        <div class="spot-list-header">
          <div class="pa-5 pt-0 pb-0"> 
            <h1 class="text-h6 text-sm-h5 font-weight-bold mb-0">行ってみたいリスト</h1>
          </div>
        </div>

        <div v-for="(userFlightSpot,index) in userFlightSpots" :key="index">
          <div class="userspot-list" @click="panSpot(userFlightSpot)"> 
            <div class="grid">
              <div>
                <div class="d-flex align-center">
                  <v-img 
                    max-height="26px"
                    max-width="23px"
                    class="mr-1"
                    v-bind:class="{'opa': !userFlightSpot.data.certify }"
                    :src="require('@/assets/img/icon_cirtify.svg')"/>

                  <v-img 
                    max-height="26px"
                    max-width="20px"
                    class="mr-1"
                    :src="getTypeIconSrc(userFlightSpot.data.type)"/>
                  <p class="caption mb-0 pt-0 mr-2">{{ userFlightSpot.data.prefecture }} / {{ userFlightSpot.data.municipality }}</p>
                  <div>
                    <v-img 
                    width="27px"
                    height="18px"
                    :src="getDidIcon(userFlightSpot.data.did)" />
                  </div>
                </div>
                <div class="d-flex align-center mb-1">
                  <h3 class="subtitle-1 text-sm-h6 font-weight-bold ma-0 pa-0 mt-2 omit">{{ userFlightSpot.data.name }}</h3>
                </div>

                <div class="tag mt-1">
                  <p class="chip" v-for="tag in userFlightSpot.data.tags" :key="tag">{{ tag }}</p>
                </div>
              </div>
              <v-btn
                dense
                depressed
                small
                @click.stop="transition(userFlightSpot.data)"
                width="90%"
                height="36px"
                color="primary"
                text
              >
                <span class="caption font-weight-bold">詳細をみる</span>
              </v-btn>

              <v-menu
                transition="slide-y-transition"
                bottom
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <div>
                    <v-btn
                      icon  
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </div>
                </template>
                <v-list class="text-left pa-0">
                  <v-list-item
                  dense
                  link>
                    <v-list-item-title @click="deleteBookmark(userFlightSpot.data.id,userFlightSpot.data.name)"><v-icon small color="gray lighten-1" class="mb-1">mdi-delete</v-icon><span class="subtitle-2 ml-1">このリストから削除</span></v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
        <div v-if="userFlightSpots.length == 0">
          <div class="pl-5 pr-5 pb-5 pt-0">
            <p class="mt-1">まだこのリストにスポットは登録されていません</p>
            <v-btn
            rounded
            outlined
            @click="$router.go(-1)"
            block
            color="primary"
            >
            <p class="mb-0">もどる</p>
            </v-btn>
          </div>
        </div>
      </div>
      <gmap-map
        :center="center"
        :zoom="zoom"
        style="width:100%; height:100%;"
        :options="{'mapTypeId':'hybrid','scrollwheel':'true','disableDefaultUI':'false','zoomControl':'true','gestureHandling':'greedy'}"
        @click="mapClick($event)"
        ref="mapRef"
        class="hhhaha"
        >
        <gmap-info-window 
          :options="infoOptions" 
          :position="infoWindowPos" 
          :opened="infoWinOpen" 
          @closeclick="infoWinOpen=false"
        >
        </gmap-info-window>
        <GmapCluster
        :gridSize="5"
        >
          <GmapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
            :ref="`marker${index}`"
            :icon="getMakerOptions(m.type)"
            @click="hoge(m)"
          />
        </GmapCluster>
      </gmap-map>

        <v-snackbar
          v-model="snackBar.isShow"
          :color="snackBar.color"
          bottom
          left
          :timeout="6000"
          class="mb-0 ml-0 mb-md-6 ml-md-6"
          transition="slide-x-transition"
        >
          <p class="mb-0">リストから <span class="font-weight-bold">{{snackBar.message}}</span> を削除しました</p>
        </v-snackbar>
    </div>
</template>

<script>
import firebase from 'firebase'
import { gmapApi } from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import prefcode from '@/assets/prefcode.json'

export default {
  components:{
    GmapCluster
  },
  data() {
    return {
      userBookmarks:[],
      bookmarkSpots:[],
      areaCenters: [
        {lat: 43.48199922430349,lng: 142.61745920163608},
        {lat: 38.66881394307953,lng: 140.5262803075931},
        {lat: 36.01077181036632,lng: 139.68546945197176},
        {lat: 36.22470367428857,lng: 138.46448499304842},
        {lat: 35.3619193140985,lng: 137.43069726569246},
        {lat: 36.173899577475765,lng: 136.72182160956453},
        {lat: 34.77142932272786,lng: 135.49439984900545},
        {lat: 34.86254090768355,lng: 132.78249341589998},
        {lat: 33.73639121834227,lng: 133.53986341101717},
        {lat: 32.88283656264372,lng: 130.91895893191023},
        {lat: 26.671677227585057,lng: 128.04415518572438},
        {lat: 36.61324257078164,lng: 138.81704797727085},
      ],
      areaZooms: [7,7,8,8,8,9,8,8,8,8,7,4],
      date:[],
      userFlightSpots:[],
      photo:[],
      markers:[],
      markerOptions: {
        url: require('../../assets/img/c.svg'),
        size: {width: 24, height: 24, f: 'px', b: 'px',},
        scaledSize: {width: 24, height: 24, f: 'px', b: 'px',},
      },
      prefectures: this.getprefectures(),
      prefCode: 0,
      spotTypes: this.getSpotTypes(),
      spotTypeValue: 0,
      infoOptions: {
        content: '',
        maxWidth: 450,
        minWidth: 200,
        pixelOffset: {
          width: 0,
          height: -28
        }
      },
      infoWindowPos: null,
      infoWinOpen: false,
      areaName:'',
      snackBar:{
        isShow:false,
        color:'rgba(0,0,0,0.9)',
        message:''
      },
    }
  },
  computed: {
    google: gmapApi,
    postButtonWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'sm':
        case 'xs':
        return '100%'
        default:
        return '280px'
      }
    },
    zoom () {
      return this.areaZooms[11]
    },
    center () {
      return this.areaCenters[11]
    }
  },
  mounted() {
    document.title = this.areaName + ' - Flyers（フライヤーズ）' 
  },
  methods: {
    getBlankImg(type){
      switch (type) {
        case parseInt(process.env.VUE_APP_FLIGHT_SPOT_VALUE):
          return require('@/assets/img/blank_ok.svg')
        case parseInt(process.env.VUE_APP_NOT_FLIGHT_SPOT_VALUE):
          return require('@/assets/img/blank_ng.svg')
        default:
          break;
      }
    },   
    getDidIcon(did){
      var result = did ? require('../../assets/img/icon_did_small_on.svg') : require('../../assets/img/icon_did_small_off.svg')
      return result
    },
    getMakerOptions(type) {
      switch (type) {
        case parseInt(process.env.VUE_APP_FLIGHT_SPOT_VALUE):
        default:
        return {
          url: require('../../assets/img/y.svg'),
          size: {width: 24, height: 24, f: 'px', b: 'px',},
          scaledSize: {width: 24, height: 24, f: 'px', b: 'px',},
        }
        case parseInt(process.env.VUE_APP_NOT_FLIGHT_SPOT_VALUE):
        return {
          url: require('../../assets/img/z.svg'),
          size: {width: 24, height: 24, f: 'px', b: 'px',},
          scaledSize: {width: 24, height: 24, f: 'px', b: 'px',},
        }
      }
    },
    formatDate(date) {
      var year = date.getFullYear();
      var month = ('00' + (date.getMonth()+1)).slice(-2);
      var day = ('00' + date.getDate()).slice(-2);
      var week = ['日','月','火','水','木','金','土'][date.getDay()]
      var hour = date.getHours()
      var second = ('00' + date.getMinutes()).slice(-2)

      return (year + '年' + month + '月' + day + '日（' + week +'） '+ hour + ':' + second)
    },    
    hoge(marker) {
      this.$store.commit('updateFlightSpot', this.userFlightSpots[marker.tag].data)

      var src = this.userFlightSpots[marker.tag].photo.src != null ? this.userFlightSpots[marker.tag].photo.src : this.getBlankImg(this.userFlightSpots[marker.tag].data.type)

      this.infoWindowPos = marker.position
      this.infoOptions.content = 
        '<div class="info-box">' +
        '<a href="/userspot/'+ this.userFlightSpots[marker.tag].data.id +'/detail"><img src="'+ src +'"></a>' +
        '<p class="subtitle-2 subtitle-sm-1 font-weight-bold ml-3 mt-2 mb-3 text-left">' + this.userFlightSpots[marker.tag].data.name + '</p>' + 
        '</div>'

      this.infoWinOpen = true

    },
    mapClick() {
      this.infoWinOpen = false
    },
    transition(userFlightSpot){
      this.$store.commit('updateFlightSpot', userFlightSpot)
      this.$router.push({name:'UsersSpotDetail', params:{
        id:userFlightSpot.id
      }})
    },
    panSpot(userFlightSpot) {
      this.$store.commit('updateFlightSpot', userFlightSpot.data)

      var latlngStr = userFlightSpot.data.center.split(",", 2)
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.panTo(new this.google.maps.LatLng(parseFloat(latlngStr[0]), parseFloat(latlngStr[1])))
      })

      var src = userFlightSpot.photo.src != null ? userFlightSpot.photo.src : this.getBlankImg(userFlightSpot.data.type)

      this.infoWindowPos = {lat: parseFloat(latlngStr[0]),lng: parseFloat(latlngStr[1])}
      this.infoOptions.content = 
        '<div class="info-box">' +
        '<a href="/userspot/' + userFlightSpot.data.id + '/detail"><img src="' + src + '"></a>' +
        '<p class="subtitle-2 subtitle-sm-1 font-weight-bold ml-3 mt-2 mb-2 text-left">' + userFlightSpot.data.name + '</p>' + 
        '</div>'

      this.infoWinOpen = true
    },
    setLatLng(center,tag,type){
      var latlngStr = center.split(",", 2);
      var array = { position:{lat: parseFloat(latlngStr[0]),lng: parseFloat(latlngStr[1])}, tag:tag, type:type }
      return array
    },
    getTypeIconSrc(type) {
      switch (type) {
        case parseInt(process.env.VUE_APP_CIRTIFICATE_FLIGHT_SPOT_VALUE):
        return require('@/assets/img/c.svg')
        case parseInt(process.env.VUE_APP_FLIGHT_SPOT_VALUE):
        default:
        return require('@/assets/img/y.svg')
        case parseInt(process.env.VUE_APP_NOT_FLIGHT_SPOT_VALUE):
        return require('@/assets/img/z.svg')
      }
    },
    getprefectures() {
      var result = [{prefName:'すべて', value:0}]

      prefcode.forEach(element => {
        result.push({prefName:element.prefName,value:element.prefCode})
      });
      return result
    },
    getSpotTypes() {
      var result = [{spotType:'すべて', value:0}]
      result.push({spotType:process.env.VUE_APP_FLIGHT_SPOT, value:parseInt(process.env.VUE_APP_FLIGHT_SPOT_VALUE)})
      result.push({spotType:process.env.VUE_APP_NOT_FLIGHT_SPOT, value:parseInt(process.env.VUE_APP_NOT_FLIGHT_SPOT_VALUE)})
      return result
    },
    resetMarker(userFlightSpots) {
      this.markers = []
      var self = this
      userFlightSpots.forEach(function(element, index) {
          self.markers.push(self.setLatLng(element.data.center,index,element.data.type))
      })
      this.infoWinOpen = false
    },
    getAreaName() {
      var result = this.$store.getters.searchValue.data.areaName

      if(this.$store.getters.searchValue.isArea){
        result = result + 'エリア'
        result = result.replace('　', '')
      }
      return result
    },
    getPrefList() {
      var date = this.$store.getters.searchValue.data
      var resultArr = []

      date.childs.forEach(element => {
          resultArr.push(element.prefCode)
      })

      return resultArr
    },
    checkUid() {
      if(this.$store.getters.idToken != null){
        this.$router.push('/post')
      } else {
        this.$router.push('/auth/signup')
      }
    },
    deleteBookmark(spotId,spotName){
      var self = this
      firebase.firestore().collection("userBookmarks")
      .doc(this.userBookmarks.id)
      .update({
        spots: firebase.firestore.FieldValue.arrayRemove(spotId),
        updateAt: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(function() {
        self.snackBar.isShow = true
        self.snackBar.message = spotName

        self.reloadUserBookmarks()

      })
      .catch(function(error) {
        console.error("Error writing document: ", error);
      })
    },
    reloadUserBookmarks(){
      this.userFlightSpots = []
      this.userBookmarks = []
      this.markers = []
      this.loadUserBookmarks()
    },
    loadUserBookmarks(){
      var self = this
      var cnt = 0 

      if(this.$store.getters.user != null){
        firebase.firestore().collection("userBookmarks")
        .where("uid", "==",this.$store.getters.user.uid)
        .limit(1)
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            self.userBookmarks = doc.data()
          })

          self.userBookmarks.spots.reverse().forEach(element => {
            firebase.firestore().collection("userFlightSpots")
            .where("id", "==", element)
            .get()
            .then(function(querySnapshot) {
              querySnapshot.forEach(function(doc) {
                self.markers.push(self.setLatLng(doc.data().center,cnt++,doc.data().type))
                firebase.firestore().collection('spotPhotos')
                .where('spotid','==',doc.data().id)
                .orderBy('createdAt', 'desc')
                .limit(1)
                .get().then(function(querySnapshot) {
                  if(!querySnapshot.empty){
                    querySnapshot.forEach(function(ddoc) {
                      self.userFlightSpots.push({data:doc.data(),photo:ddoc.data()})
                    })
                  } else {
                    self.userFlightSpots.push({data:doc.data(),photo:[]})
                  }
                })
              })
            })
          })
        })
      } else {
        this.$router.push('/auth/login')
      }
    }
  },
  created() {
    this.loadUserBookmarks()
  },
}
</script>

<style lang="scss">

.spot-list-header {
  // background-color: #f5f5f5;
  padding: 30px 00px 00px 00px;
}

.omit {
  max-width: 350px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.info-box {
  img {
    width: 100%;
    max-height: 253px;
    object-fit: cover;
  }
}

.aaaaa {
  display: grid;
  grid-template-columns: 600px 1fr;
  height: calc(100vh - 56px);
}

  .bababa {
    overflow-y: auto;
    z-index: 5;
    a {
      color: #2c3e50;
    }
    .userspot-list {
      .opa {
        opacity: 0.15;
      }
      padding: 20px 20px 14px 20px;
      border-bottom: solid 1px rgba(0, 0, 0, 0.1);
      transition-duration:0.4s;
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        transition-duration:0.2s;
      }
      .grid {
        min-height: 100px;
        display: grid;
        grid-template-columns: 1fr 100px 30px;
        align-items: center;
      }
      .tag {
        display: flex;
        flex-wrap: wrap;
        .chip {
          margin-top: 3px;
          padding-top: 4px !important;
          margin-right: 5px;
          margin-bottom: 4px;
          font-size: 11px;
          padding:2px 8px 3px 8px;
          border: 1px rgba(0, 0, 0, 0.2) solid;
          border-radius: 3px;
          font-family: YakuHanJP;
          letter-spacing: 0.09em;
          color:rgba(0, 0, 0, .85);
        }
      }
      .createdAt {
        font-size: 10px;
        margin-top: 3px;
        margin-bottom: 0px;
        color:#333333;
      }
    }
  }

  .hhhaha {
    z-index: 1;
  }

@media (max-width: 600px) {

  .omit {
    max-width: 170px;
  }

  .aaaaa {
    grid-template-columns: 1fr;
    grid-template-rows: 50% 50%;
    height: calc(100vh - 56px);
    margin-top: 56px;
  }
  .bababa {
    order: 2;
  }

  .info-box {
    img {
      max-height: 153px;
    }
  }
}

</style>