<template>
  <div>
    <header>
      <Headersp/>
        <div class="pc">
          <div>
            <div class="new-header pl-4 pr-0">
              <div class="d-flex align-center">
                <div>
                  <router-link to="/">
                    <v-img 
                    class="mr-5"
                    :src="require('@/assets/img/betalogo.svg')"/>
                  </router-link>
                </div>
                  <v-btn
                    depressed
                    :to="'/'"
                    icon
                    plain
                  >
                    <v-icon>mdi-home-outline</v-icon>
                  </v-btn>
              </div>
              <div class="d-flex align-center"> 
                    <v-btn
                      tile
                      height="56"
                      color="white"
                      depressed
                      :to="'/beginner'"
                    >
                      <span class="subtitle-2 mb-0"><v-icon small class="mr-1 mb-1">far fa-lightbulb</v-icon>はじめての方へ</span>
                    </v-btn>
                  <div v-if="!isLoggedIn">
                    <v-btn
                      tile
                      height="56"
                      color="white"
                      depressed
                      class="pl-10 pr-10"
                      :to="'/auth/login'"
                    >
                      <span class="subtitle-2">ログイン</span>
                    </v-btn>
                    <v-btn
                      tile
                      height="56"
                      color="primary"
                      depressed
                      :to="'/auth/signup'"
                    >
                      <span class="font-weight-bold">無料ユーザー登録</span>
                    </v-btn>
                  </div>
                  <div v-else>
                    <div class="d-flex align-center">
                    <v-btn
                      color="primary"
                      depressed
                      rounded
                      small
                      class="mr-5"
                      :to="'/post'"
                    >
                      <span class="font-weight-bold">スポット情報を共有する</span>
                    </v-btn>
                    <a :href="'/users/'+ this.$store.getters.user.uid +'/detail'" class="no-under-line">
                        <v-avatar
                        class="avater"
                        size="36">
                          <v-img
                            :src="this.$store.state.userIconSrc"
                          />
                        </v-avatar>
                    </a>
                    <v-menu
                      bottom
                      left
                      class="z999"
                      :offset-y="true"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          small
                          depressed
                          v-bind="attrs"
                          v-on="on"
                          color="blue-grey lighten-1"
                          class="ml-2 mr-3"
                        >
                          <v-icon small>fas fa-caret-down</v-icon>
                        </v-btn>
                      </template>

                      <v-list class="text-left">
                        <v-list-item
                          to="/"
                        >
                        <v-list-item-title>ホーム</v-list-item-title>
                        </v-list-item>

                        <v-divider class="mt-2 mb-2"></v-divider>

                        <v-list-item
                          to="/users/home"
                          v-if="isLoggedIn"
                        >
                        <v-list-item-title>設定</v-list-item-title>
                        </v-list-item>

                        <div v-if="isLoggedIn">
                          <a :href="'/users/'+ this.$store.getters.user.uid +'/detail'" class="no-under-line">
                          <v-list-item>
                          <v-list-item-title>ユーザーページ</v-list-item-title>
                          </v-list-item>
                          </a>
                        </div>

                        <div v-if="isLoggedIn">
                          <v-list-item
                            :to="'/users/'+ this.$store.getters.user.uid +'/list'"
                          >
                            <v-list-item-title>リスト</v-list-item-title>
                          </v-list-item>
                        </div>

                        <v-divider class="mt-2 mb-2"></v-divider>

                        <v-list-item
                          v-if="isLoggedIn"
                          @click="logout"
                        >
                        <v-list-item-title class="subtitle-2">
                          <v-icon class="pb-1">mdi-logout</v-icon>
                          ログアウト</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    <router-view/>
  </div>
</template>

<script>
import firebase from 'firebase'
import Headersp from '@/components/Headersp.vue'

export default {
    components: {
    Headersp
  },
  data() {
    return {
      email: ''
    }
  },
  methods: {
    logout: function () {
      this.$store.commit('updateIdToken', null);
      this.$store.commit('updateDisplayName', null);
      this.$store.commit('updateUserIconSrc', null);
      this.$store.commit('updateUser', null);
      this.$store.commit('updateFlightSpot', null);
      this.$store.commit('userFlightSpotSelectValue', null);
      this.$store.commit('searchValue', null);

      firebase.auth().signOut()
      firebase.analytics().logEvent('logout', {});
      this.$router.go({path: this.$router.currentRoute.path, force: true})
    },
    redirect: function () {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          this.$store.commit('updateIdToken', user)
        } else {
          this.$router.push('/auth/login')
        }
      });
    },
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.idToken !== null
    }
  },
  created() {
  },
}
</script>

<style lang="scss" scoped>
.new-header {
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.07);
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.avater {
  opacity: 1;
  transition-duration:0.6s;
  &:hover {
    opacity: 0.7;
    transition-duration:0.2s;
  }
}
</style>