<template>
  <div class="container">
    <v-form ref="form">

      <v-radio-group
        v-model="flightSpot.status" 
        class="mb-4"
      >
        <p class="mb-1">ステータス</p>
        <v-radio :value="0">
          <template v-slot:label>
            <div class="subtitle-2">0:非表示</div>
          </template>
        </v-radio>
        <v-radio :value="1">
          <template v-slot:label>
            <div class="subtitle-2">1:表示</div>
          </template>
        </v-radio>
        <v-radio :value="3">
          <template v-slot:label>
            <div class="subtitle-2">3:調整中</div>
          </template>
        </v-radio>
      </v-radio-group>

      <v-text-field
        label="フライトスポットID （原則調整不要）"
        v-model="flightSpot.id"
        outlined
        :rules="[rules.required]"
      ></v-text-field>

      <h2 class="mb-6">必須項目</h2>

      <v-text-field
        label="フライトスポット名"
        v-model="flightSpot.name"
        outlined
        :rules="[rules.required]"
      ></v-text-field>

      <v-select
        v-model="flightSpot.prefCode"
        :items="prefectures"
        outlined
        label="都道府県"
        :rules="[rules.required]"
        item-text="name"
        item-value="value"
      ></v-select>

      <v-text-field
        label="市町村"
        v-model="flightSpot.municipality"
        outlined
      ></v-text-field>

      <v-text-field
        label="住所（郵便番号不要）"
        v-model="flightSpot.address"
        outlined
      ></v-text-field>

      <v-text-field
        label="GoogleMapの短縮URL"
        v-model="flightSpot.googleMapUrl"
        outlined
      ></v-text-field>

      <v-text-field
        label="タグ（カンマ区切り）"
        v-model="flightSpot.tags"
        outlined
      ></v-text-field>

      <v-text-field
        label="最小予約日"
        v-model="flightSpot.reservedMinDay"
        outlined
        :rules="[rules.required]"
      ></v-text-field>

      <v-radio-group
        v-model="flightSpot.did" 
        colums
        class="mt-0 mb-4"
      >
        <p class="mb-1">DID地区</p>
        <v-radio :value="true">
          <template v-slot:label>
            <div class="subtitle-2">該当</div>
          </template>
        </v-radio>
        <v-radio :value="false">
          <template v-slot:label>
            <div class="subtitle-2">非該当</div>
          </template>
        </v-radio>
      </v-radio-group>

      <h2 class="mt-10 mb-6">任意項目</h2>

      <v-textarea
        label="概要"
        v-model="flightSpot.overview"
        outlined
      ></v-textarea>

      <v-textarea
        label="ハイライト（改行区切り）"
        v-model="flightSpot.highlights"
        outlined
      ></v-textarea>

      <v-textarea
        label="フライトスポット詳細"
        v-model="flightSpot.description"
        outlined
      ></v-textarea>

      <v-text-field
        label="画像ファイル名"
        v-model="flightSpot.src"
        outlined
      ></v-text-field>

      <v-text-field
        label="サムネ画像ファイル名"
        v-model="flightSpot.thumb"
        outlined
      ></v-text-field>

      <v-radio-group
        v-model="flightSpot.parking" 
        class="mt-0 mb-4"
      >
        <p class="mb-1">駐車場</p>
        <v-radio :value="true">
          <template v-slot:label>
            <div class="subtitle-2">有</div>
          </template>
        </v-radio>
        <v-radio :value="false">
          <template v-slot:label>
            <div class="subtitle-2">無</div>
          </template>
        </v-radio>
      </v-radio-group>

      <v-radio-group
        v-model="flightSpot.restroom"
        class="mb-4"
      >
        <p class="mb-1">トイレ</p>
        <v-radio :value="true">
          <template v-slot:label>
            <div class="subtitle-2">有</div>
          </template>
        </v-radio>
        <v-radio :value="false">
          <template v-slot:label>
            <div class="subtitle-2">無</div>
          </template>
        </v-radio>
      </v-radio-group>

      <v-textarea
        label="ルール（改行区切り）"
        v-model="flightSpot.rules"
        outlined
      ></v-textarea>

      <v-textarea
        label="注意事項（改行区切り）"
        v-model="flightSpot.cautions"
        outlined
      ></v-textarea>

      <v-textarea
        label="youtube（改行区切り）"
        v-model="flightSpot.youtubes"
        outlined
      ></v-textarea>

      <v-textarea
        label="twitter（改行区切り）"
        v-model="flightSpot.twitters"
        outlined
      ></v-textarea>

      <v-textarea
        label="飛行範囲の緯度経度（改行区切り）"
        v-model="flightSpot.area.latlng"
        outlined
      ></v-textarea>

      <div v-for="(launchSite, index) in flightSpot.area.launchSites" :key="`first-${index}`">
        <v-textarea
          :label="'離発着場所' + (index+1) + 'の緯度経度'"
          v-model="launchSite.value"
          outlined
        ></v-textarea>
      </div>
      <v-btn
        class="mb-12"
        @click="addLaunchSite"
        depressed
        color="teal lighten-2"
        small
      >
        <span class="font-weight-bold white--text">離発着場所{{ flightSpot.area.launchSites.length +1 }}の追加</span>
      </v-btn>

      <div v-for="(parking, index) in flightSpot.area.parkings" :key="`second-${index}`">
        <v-textarea
          :label="'駐車場' + (index+1) + 'の緯度経度'"
          v-model="parking.value"
          outlined
        ></v-textarea>
      </div>
      <v-btn
        class="mb-12"
        @click="addParking"
        depressed
        color="teal lighten-2"
        small
      >
        <span class="font-weight-bold white--text">駐車場{{ flightSpot.area.parkings.length +1 }}の追加</span>
      </v-btn>

      <v-text-field
        label="Mapセンターの緯度経度"
        v-model="flightSpot.area.center"
        outlined
      ></v-text-field>

      <v-text-field
        label="初期ズーム値"
        v-model="flightSpot.area.zoom"
        outlined
      ></v-text-field>

      <v-btn
        @click="checkValidate"
        color="primary"
        rounded
        large
        depressed
      >
        <span class="font-weight-bold">この内容で{{ isUpdate ? '更新' : '登録' }}する</span>
      </v-btn>
      <p class="subtitle-2 mt-10"><router-link to="/admin/flightspot/list" class="no-under-line">一覧に戻る</router-link></p>
      <v-snackbar
        v-model="snackBar.show"
        :color="snackBar.color"
        top
        center
        transition="slide-y-transition"
        :timeout="6000"
      >
      <span class="subtitle-2 font-weight-bold">{{snackBar.message}}</span>
      </v-snackbar>
    </v-form>
  </div>
</template>

<script>
import firebase from 'firebase'

export default {
  data() {
    return {
      isUpdate:false,
      docId: '',
      flightSpot: {
        id: this.$route.params.id ? this.$route.params.id: '',
        name: '',
        status: 0,
        overview: '',
        tags: '',
        prefCode: 0,
        reservedMinDay: 5,
        prefecture: '',
        municipality: '',
        did: false,
        src: '',
        thumb: '',
        highlights: '',
        description: '',
        address: '',
        googleMapUrl: '',
        parking: false,
        restroom: false,
        rules: '',
        cautions: '',
        youtubes: '',
        twitters: '',
        area: {
          latlng: '',
          parking: '',
          parkings: [{ value:''}],
          launchSite: '',
          launchSites: [{ value:''}],
          center: '',
          zoom: 15
        },
      },
      rules: {
        required: v => !!v || '必須です.',
      },
      snackBar: {
        show: false,
        color: '',
        message: ''
      },
      prefectures:[
        { name: '北海道', value: 1 },
        { name: '青森県', value: 2 },
        { name: '岩手県', value: 3 },
        { name: '宮城県', value: 4 },
        { name: '秋田県', value: 5 },
        { name: '山形県', value: 6 },
        { name: '福島県', value: 7 },
        { name: '茨城県', value: 8 },
        { name: '栃木県', value: 9 },
        { name: '群馬県', value: 10 },
        { name: '埼玉県', value: 11 },
        { name: '千葉県', value: 12 },
        { name: '東京都', value: 13 },
        { name: '神奈川県', value: 14 },
        { name: '新潟県', value: 15 },
        { name: '富山県', value: 16 },
        { name: '石川県', value: 17 },
        { name: '福井県', value: 18 },
        { name: '山梨県', value: 19 },
        { name: '長野県', value: 20 },
        { name: '岐阜県', value: 21 },
        { name: '静岡県', value: 22 },
        { name: '愛知県', value: 23 },
        { name: '三重県', value: 24 },
        { name: '滋賀県', value: 25 },
        { name: '京都府', value: 26 },
        { name: '大阪府', value: 27 },
        { name: '兵庫県', value: 28 },
        { name: '奈良県', value: 29 },
        { name: '和歌山県', value: 30 },
        { name: '鳥取県', value: 31 },
        { name: '島根県', value: 32 },
        { name: '岡山県', value: 33 },
        { name: '広島県', value: 34 },
        { name: '山口県', value: 35 },
        { name: '徳島県', value: 36 },
        { name: '香川県', value: 37 },
        { name: '愛媛県', value: 38 },
        { name: '高知県', value: 39 },
        { name: '福岡県', value: 40 },
        { name: '佐賀県', value: 41 },
        { name: '長崎県', value: 42 },
        { name: '熊本県', value: 43 },
        { name: '大分県', value: 44 },
        { name: '宮崎県', value: 45 },
        { name: '鹿児島県', value: 46 },
        { name: '沖縄県', value: 47 }
      ],
    }
  },
  beforeCreate() {
    if(this.$store.getters.idToken.email != 'info@flyers.plus' && this.$store.getters.idToken.email != 'r-tatsumi@flyers.plus'){
      this.$router.push('/home')
    }
  },
  methods: {
    checkValidate() {
      if(this.$refs.form.validate()) {
        if(this.isUpdate){
          this.update()
        } else {
          this.add()
        }
      } else {
        this.snackBar.show = true
        this.snackBar.message = "入力漏れなどがあるよ"
        this.snackBar.color = "#FF3300"
      }
    },
    add() {
      var self = this
      var db = firebase.firestore()
      db.collection("flightSpot").add({
        id: parseInt(this.flightSpot.id),
        status: parseInt(this.flightSpot.status),
        name: this.flightSpot.name,
        overview: this.flightSpot.overview,
        tags: this.flightSpot.tags.split(','),
        reservedMinDay: parseInt(this.flightSpot.reservedMinDay),
        prefCode: parseInt(this.flightSpot.prefCode),
        prefecture: this.prefectures[this.flightSpot.prefCode-1].name,
        municipality: this.flightSpot.municipality,
        did: this.flightSpot.did,
        src: this.flightSpot.src,
        thumb: this.flightSpot.thumb,
        highlights: this.flightSpot.highlights.split('\n'),
        description: this.flightSpot.description,
        address: this.flightSpot.address,
        googleMapUrl: this.flightSpot.googleMapUrl,
        parking: this.flightSpot.parking,
        restroom: this.flightSpot.restroom,
        rules: this.setArrayData(this.flightSpot.rules),
        cautions: this.setArrayData(this.flightSpot.cautions),
        youtubes: this.setArrayData(this.flightSpot.youtubes),
        twitters: this.setArrayData(this.flightSpot.twitters),
        area: {
          latlng: this.flightSpot.area.latlng,
          launchSites: this.flightSpot.area.launchSites,
          parkings: this.flightSpot.area.parkings,
          center: this.flightSpot.area.center,
          zoom: this.flightSpot.area.zoom
        },
        updateAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(function() {
        self.snackBar.show = true
        self.snackBar.message = self.flightSpot.name + "の登録が完了しました"
        self.snackBar.color = "success"
      })
      .catch(function(error) {
        console.error("Error writing document: ", error);
      });
    },
    update(){
      var self = this
      var db = firebase.firestore()
      db.collection("flightSpot").doc(this.docId).update({
        id: parseInt(this.flightSpot.id),
        status: parseInt(this.flightSpot.status),
        name: this.flightSpot.name,
        overview: this.flightSpot.overview,
        tags: this.flightSpot.tags.split(','),
        reservedMinDay: parseInt(this.flightSpot.reservedMinDay),
        prefCode: parseInt(this.flightSpot.prefCode),
        prefecture: this.prefectures[this.flightSpot.prefCode-1].name,
        municipality: this.flightSpot.municipality,
        did: this.flightSpot.did,
        src: this.flightSpot.src,
        thumb: this.flightSpot.thumb,
        highlights: this.flightSpot.highlights.split('\n'),
        description: this.flightSpot.description,
        address: this.flightSpot.address,
        googleMapUrl: this.flightSpot.googleMapUrl,
        parking: this.flightSpot.parking,
        restroom: this.flightSpot.restroom,
        rules: this.setArrayData(this.flightSpot.rules),
        cautions: this.setArrayData(this.flightSpot.cautions),
        youtubes: this.setArrayData(this.flightSpot.youtubes),
        twitters: this.setArrayData(this.flightSpot.twitters),
        area: {
          latlng: this.flightSpot.area.latlng,
          launchSites: this.flightSpot.area.launchSites,
          parkings: this.flightSpot.area.parkings,
          center: this.flightSpot.area.center,
          zoom: this.flightSpot.area.zoom
        },
        updateAt: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(function() {
        self.snackBar.show = true
        self.snackBar.message = self.flightSpot.name + "の更新が完了しました"
        self.snackBar.color = "success"
      })
      .catch(function(error) {
        console.error("Error writing document: ", error);
      });
    },
    addLaunchSite() {
      this.flightSpot.area.launchSites.push({ value:''})
    },
    addParking() {
      this.flightSpot.area.parkings.push({ value:''})
    },
    setArrayData(array) {
      var arr = array.split('\n')
      return arr == '' ? []: arr
    }
  },
  created() {
    var db = firebase.firestore()
    var self = this

    db.collection("flightSpot").where("id", "==", parseInt(this.$route.params.id))
    .get()
    .then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        var flightSpot = doc.data()

        self.flightSpot.name = flightSpot.name
        self.flightSpot.status = flightSpot.status
        self.flightSpot.overview = flightSpot.overview
        self.flightSpot.tags = flightSpot.tags.toString()
        self.flightSpot.prefCode = flightSpot.prefCode
        self.flightSpot.reservedMinDay = flightSpot.reservedMinDay
        self.flightSpot.municipality = flightSpot.municipality
        self.flightSpot.did = flightSpot.did
        self.flightSpot.src = flightSpot.src
        self.flightSpot.thumb = flightSpot.thumb
        self.flightSpot.highlights = flightSpot.highlights.join('\n')
        self.flightSpot.description = flightSpot.description
        self.flightSpot.address = flightSpot.address
        self.flightSpot.googleMapUrl = flightSpot.googleMapUrl
        self.flightSpot.parking = flightSpot.parking
        self.flightSpot.restroom = flightSpot.restroom
        self.flightSpot.rules = flightSpot.rules.join('\n')
        self.flightSpot.cautions = flightSpot.cautions.join('\n')
        self.flightSpot.youtubes = flightSpot.youtubes.join('\n')
        self.flightSpot.twitters = flightSpot.twitters.join('\n')
        self.flightSpot.area.latlng = flightSpot.area.latlng
        self.flightSpot.area.parkings = flightSpot.area.parkings
        self.flightSpot.area.launchSites = flightSpot.area.launchSites
        self.flightSpot.area.center = flightSpot.area.center
        self.flightSpot.area.zoom = flightSpot.area.zoom

        self.docId = doc.id
      });

      self.isUpdate = querySnapshot.size >= 1
    })
    .catch(function(error) {
      console.log("Error getting documents: ", error);
    })
  },
}
</script>