<template>
  <div>
    <div class="head" v-if='flightSpot.src != ""'>
      <img :src="'/img/flightspot/' + flightSpot.src" />
    </div>
    <div class="container">
      <v-row
        justify="center"
        class="mt-5"
      >
      </v-row>
      <article class="main">
        <Information 
          class="mt-6 mb-6"
          :prefecture="flightSpot.prefecture" 
          :municipality="flightSpot.municipality" 
          :did="flightSpot.did" 
        />
        <section class="mb-15">
          <h1 class="text-sm-h2 text-h3 font-weight-bold">{{ flightSpot.name }}</h1>
          <div class="point title font-weight-bold mb-6" v-if="flightSpot.highlights != ''">
              <p class="mb-1" v-for="highlight in flightSpot.highlights" :key="highlight">・{{ highlight }}</p>
          </div>
          <p>{{ flightSpot.description }}</p>
          <div class="tag">
            <v-chip
              label
              outlined
              small
              v-for="tag in flightSpot.tags" :key="tag"
              class="mr-2 mb-2"
            >{{ tag }}</v-chip>
          </div>
        </section>
        <section class="flightspot-information">    
          <h2 class="text-sm-h4 text-h5 font-weight-bold mb-4">フライトスポット情報</h2>

          <div>
            <gmap-map 
              :center="center" 
              :zoom="zoom" 
              style="width:100%; height:600px;"
              :options="{'mapTypeId':'hybrid'}"
              ref="gmap"
              >

              <span v-if="isLatlng">
                <gmap-polygon
                  :options="{'strokeColor': '#00b3ff','fillColor': '#0089FF','strokeWeight': '2', 'fillOpacity': '0.2' }" 
                  :path="latlngs" 
                >
                </gmap-polygon>
              </span>

              <span v-if="islaunchSite">
                <span v-for="launchSite in launchSites" :key="launchSite.key">
                  <gmap-polygon
                    :options="{'strokeColor': '#ff8a00','fillColor': '#ff8a00','strokeWeight': '2', 'fillOpacity': '0.2'}" 
                    :path="launchSite"
                  >
                  </gmap-polygon>
                </span>
              </span>

              <span v-if="isParking">
                <span v-for="parking in parkings" :key="parking.key">
                  <gmap-polygon
                    :options="{'strokeColor': '#eb5ddc','fillColor': '#eb5ddc','strokeWeight': '2', 'fillOpacity': '0.2', 'zIndex': '4'}" 
                    :path="parking" 
                  >
                  </gmap-polygon>
                </span>
              </span>

            </gmap-map>
          </div>

          <div class="mt-4">
            <v-btn 
              outlined
              color="#0093ff"
              @click="isLatlng = !isLatlng"
              class="mb-4 mr-4"
            >
            <v-icon class="mr-2" v-if="isLatlng">mdi-eye</v-icon>
            <v-icon class="mr-2" v-else>mdi-eye-off</v-icon>
            飛行申請範囲
            </v-btn>
            <v-btn 
              outlined
              color="#ff8a00"
              @click="islaunchSite = !islaunchSite"
              class="mb-4 mr-4"
              Depressed
              elevation="0"
              :disabled="launchSites.length < 1"
            >
            <v-icon class="mr-2" v-if="islaunchSite">mdi-eye</v-icon>
            <v-icon class="mr-2" v-else>mdi-eye-off</v-icon>
            オススメの離着陸場所
            </v-btn>
            <v-btn 
              outlined
              color="#eb5ddc"
              @click="isParking = !isParking"
              class="mb-4 mr-4"
              :disabled="parkings.length < 1"
            >
            <v-icon class="mr-2" v-if="isParking">mdi-eye</v-icon>
            <v-icon class="mr-2" v-else>mdi-eye-off</v-icon>
            駐車場
            </v-btn>
            <v-btn 
              outlined
              color="#FF0000"
              @click="showDid"
              class="mb-4"
            >
            <v-icon class="mr-2" v-if="isDid">mdi-eye-off</v-icon>
            <v-icon class="mr-2" v-else>mdi-eye</v-icon>
            人口集中地区（DID）
            </v-btn>
          </div>

          <p class="mt-3 subtitle-2">※上記範囲外での飛行をご希望の方は <router-link to="/spotrequest" class="font-weight-bold no-under-line">スポットリクエスト</router-link> をご利用ください。</p>
          
          <div class="description">
            <div class="address">
              <h3 class="text-h6 font-weight-bold mb-1">住所</h3>
              <p class="mb-2">{{ flightSpot.address }}</p>
              <a class="subtitle-2 forGmap no-under-line pt-1 pl-3 pr-3 pb-1" :href="flightSpot.googleMapUrl" target="_blank">GoogleMapでひらく</a>
            </div>
            <div class="facility">
              <h3 class="text-h6 font-weight-bold mb-1">設備について</h3>
              <h4 class="subtitle-1 font-weight-bold">駐車場</h4>
                <p>{{ flightSpot.parking ? '有' : '無'}}</p>
              <h4 class="subtitle-1 font-weight-bold">トイレ</h4>
                <p>{{ flightSpot.restroom ? '有' : '無' }}</p>
            </div>
            <div class="rule" v-if="flightSpot.rules != ''">
              <h3 class="text-h6 font-weight-bold mb-1">現地ルール</h3>
              <ul class="red--text text--darken-1">
                <li v-for="rule in flightSpot.rules" :key="rule">{{ rule }}</li>
              </ul>
            </div>
            <div class="caution" v-if="flightSpot.cautions != ''">
              <h3 class="text-h6 font-weight-bold mb-1">注意事項</h3>
              <ul class="red--text text--darken-1">
                <li v-for="caution in flightSpot.cautions" :key="caution">{{ caution }}</li>
              </ul>
            </div>
          </div>
          <div class="submit">
            <v-btn
              width="100%"
              height="55px"
              color="primary"
              x-large
              rounded
              depressed
              :disabled="!isLoggedIn"
              @click="submit"
              class="mb-4"
            >
              <v-icon left class="pr-6">fas fa-file-alt</v-icon>
              <span class="text-h6 font-weight-bold">ドローンを飛ばすための飛行手続きをFlyersが無料で行います</span>
            <span class="text-h6 font-weight-bold">ドローンを飛ばすための飛行手続きをFlyersが無料で行います</span>

            </v-btn>

            <p class="text-subtitle-2 font-weight-bold" v-if="isLoggedIn">利用申請は最短１分で完了します</p>
            <router-link to="/auth/signup" v-else class="no-under-line"><span class="text-subtitle-2 font-weight-bold pt-4">フライトスポット利用申請のため、無料でベータ版アカウント作成をしましょう!</span></router-link>
          </div>
        </section>
      </article>

      <article class="pickup" v-if="flightSpot.youtubes != '' || flightSpot.twitters != ''">
        <h2 class="text-sm-h3 text-h4 font-weight-bold">ピックアップ</h2>
        <section id="youtube" v-if="flightSpot.youtubes != ''">
          <h3 class="text-h5 font-weight-bold">Youtubeから<span class="blue--text">{{ flightSpot.name }}</span>をピックアップ</h3>
          <p class="caption mb-2 text-left mb-4">※動画再生時音量に注意</p>
          <div class="contents">
            <div class="wrapper" v-for="youtube in flightSpot.youtubes" :key="youtube">
              <iframe width="560" height="315" :src="'https://www.youtube.com/embed/' + youtube " frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </section>
        <section id="twitter" v-if="flightSpot.twitters != ''">
          <h3 class="text-h5 font-weight-bold">Twitterから<span class="blue--text">{{ flightSpot.name }}</span>をピックアップ</h3>
          <p class="caption mb-2 text-left">※動画再生時音量に注意</p>
          <div class="hogehoge">
            <span v-for="twitter in flightSpot.twitters" :key="twitter"><Tweet :id="twitter"></Tweet></span>
          </div>
        </section>
        <div class="submit mt-16">
          <v-btn
            width="100%"
            color="primary"
            x-large
            rounded
            depressed
            :disabled="!isLoggedIn"
            @click="submit"
            class="mb-4"
          >
            <v-icon left class="pr-6">fas fa-file-alt</v-icon>
            <span class="text-h6 font-weight-bold">利用申請をする（無料）</span>
          </v-btn>
          <p class="text-subtitle-2 font-weight-bold" v-if="isLoggedIn">利用申請は最短１分で完了します</p>
          <router-link to="/auth/signup" v-else class="no-under-line"><span class="text-subtitle-2 font-weight-bold pt-4">フライトスポット利用申請のため、無料でベータ版アカウント作成をしましょう!</span></router-link>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import { Tweet } from 'vue-tweet-embed'
import Information from '@/components/Information.vue'
import { gmapApi } from 'vue2-google-maps'

export default {
  components: {
    Tweet,
    Information
  },
  data: function() {
    return {
      flightSpot: [],
      center: {'lat':41.957847822253894 , 'lng':140.12988176118338},
      paths: [{}],
      launchSite: [{}],
      zoom: 15,
      islaunchSite:true,
      isLatlng:true,
      isParking:true,
      isDid:true,
      latlngs: [{'lat':41.957847822253894 , 'lng':140.12988176118338}],
      launchSites:[],
      parkings:[],
    };
  },
  beforeCreate() {
    var db = firebase.firestore()
    var self = this

    db.collection("flightSpot").where("id", "==", parseInt(this.$route.params.id))
    .get()
    .then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
          self.flightSpot = doc.data()
      });
      self.latlngs = self.setLatLngs(self.flightSpot.area.latlng)
      self.launchSites = self.setlaunchSite(self.flightSpot.area.launchSites)
      self.parkings = self.setlaunchSite(self.flightSpot.area.parkings)
      self.center = self.setCenter(self.flightSpot.area.center)
      self.zoom = Number(self.flightSpot.area.zoom)

      self.$refs.gmap.$mapPromise.then((map) => {
        map.data.loadGeoJson('https://fogfrog.jp/ddd/did_'+ self.flightSpot.prefCode + '.geojson')
        map.data.setStyle({
          strokeWeight: 0,
          fillOpacity: 0
        });
      })

      firebase.analytics().logEvent('flight_spot_detail', {
        id: self.$route.params.id,
        spotName: self.flightSpot.name
      });
    });
  },
  computed: {
    google: gmapApi,
    isLoggedIn: function() {
      return this.$store.getters.idToken !== null;
    }
  },
  methods: {
    submit() {
        this.$router.push('/flightspot/'+this.$route.params.id+'/form')
    },
    setLatLngs(latlng) {
      var result = []
      var arr = latlng.split('\n')
      arr.forEach(element => {
        var arrs = element.split(',')
        result.push({'lat': parseFloat(arrs[0]) , 'lng':parseFloat(arrs[1])})
      });
      return result
    },
    setlaunchSite(value){
      var result = []
      value.forEach(element => {
        var arrs = element.value.split('\n')
        var r = []
        arrs.forEach(element2 => {
          var arrss = element2.split(',')
          r.push({'lat': parseFloat(arrss[0]) , 'lng':parseFloat(arrss[1])})
        });
        result.push(r)
      });
      return result
    },
    setCenter(center){
      var arrs = center.split(',')
      var result = {'lat': parseFloat(arrs[0]) , 'lng':parseFloat(arrs[1])}
      return result
    },
    showDid() {

      if(this.isDid){
        this.$refs.gmap.$mapPromise.then((map) => {
          map.data.setStyle({
            fillColor: process.env.VUE_APP_DID_FILL_COLOR,
            strokeWeight: process.env.VUE_APP_DID_STROKE_WEIGHT,
            fillOpacity: process.env.VUE_APP_DID_FILL_OPACITY
          });
        })
      } else {
        this.$refs.gmap.$mapPromise.then((map) => {
          map.data.setStyle({
            strokeWeight: 0,
            fillOpacity: 0
          });
        })
      }

      this.isDid = !this.isDid
    }
  },
}
</script>

<style lang="scss">
.head {
  img {
    width: 100%;
    height: 550px;
    object-fit: cover;
  }
}

.description {
  margin-bottom: 60px;
  .address, .facility {
    border-bottom: 1px rgba(0,0,0,0.2) solid;
  }
  div {
    padding:20px 0;
  }
  p,a,h4 {
    margin-left: 20px;
  }
  p,li {
    font-size: 90%;
    margin-bottom: 0;
  }
  .caution {
    padding-top: 0;
  }
}

.flightspot-information {
  .vue-map {
    border-radius: 4px;
  }
}

.submit {
  text-align: center;
}

#twitter {
  .hogehoge {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px,1fr));
    gap: 18px;
    grid-auto-flow: dense;
  }
}

#youtube {
  width: 100%;
  height: 100%;
  margin: 30px 0 120px 0;
  .contents {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(550px,1fr));
    gap: 16px 18px;
    .wrapper {
      position: relative;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
      &::before {
        content: "";
        display: inline-block;
        padding-top: 56.25%;
      }
    }
  }
}

.pickup {
  margin-top: 120px;
  text-align: center;
  h2,h3 {
    text-align: left;
  }
}

.forGmap {
  border-radius: 100px;
  border: 1px rgba(0,0,0,0.2) solid;
}

@media (max-width: 900px) {

  .head {
    img {
      height: 300px;
    }
  }

  #youtube {
    .contents {
      grid-template-columns: 1fr;
    }
  }
}

</style>