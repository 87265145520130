<template>
  <div>
    <v-app>
      <router-view/>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="90%"
        z-index=20
        overlay-opacity="0.93"
        >
        <v-card>
          <v-card-title class="text-h6">
            お知らせ
          </v-card-title>
          <v-card-text class="subtitle-1 text-left">
            現在システムメンテナンスを行っているため、Flyersをご利用いただくことができません。<br>
            ご不便をおかけいたしますが、ご理解いただきますようお願い申し上げます。<br><br>
            メンテナンス予定<br>
            2021年4月18日（日）18:30 〜 2021年4月19日（月）10:00
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      
    }
  },
  computed: {
    dialog:function () {
      var a = false
      if(this.$store.getters.idToken != null && (this.$store.getters.idToken.email != 'info@flyers.plus' || this.$store.getters.idToken.email != 'r-tatsumi@flyers.plus')) {
        a = false
      }
      return a
    }
  },
}
</script>

<style lang="scss">
.gm-style-iw {
  max-height: initial !important;
}

.gm-style-iw-c {
  padding: 0 !important;
}

.gm-style-iw-d {
  overflow: auto !important;
  max-height: initial !important;
}

#app {
  font-family: YakuHanJP,Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  letter-spacing: 0.05em;
  font-feature-settings: "palt";
}

.yaku {
  font-family: YakuHanJP;
  letter-spacing: 0.05em;
  font-feature-settings: "palt";
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

.default-color {
  color: #2c3e50 !important;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 100;
}

.headerr {
  display: flex;
  align-items: center;
  padding: 20px 40px 0 40px;
  justify-content: space-between;
  background: white;
  height: 90px;
  .navigation {
    display: flex;
    flex-direction: column;
      align-items: flex-start;
    .hoge {
      display: flex;
      align-items: center;
      p {
        margin: 0;
        padding: 0;
      }
    }
  }
  .hage {
    display: flex;
  }
  a {
    text-decoration: none;
  }
}

.no-under-line {
  text-decoration: none;
}
</style>