<template>
  <div class="user-home mt-10">
    <UserMenu 
     :userName="this.$store.getters.displayName != null ? this.$store.getters.displayName : ''" 
    />
    <section class="user-account-main">
      <h1 class="text-h4 font-weight-bold mb-8">アカウント</h1>
      <div class="mb-6">
        <h3 class="subtitle-2 font-weight-bold mb-1">ユーザーID</h3>
        <p class="subtitle-1">{{ userId }}</p>
      </div>
      <div class="mb-6">
        <h3 class="subtitle-2 font-weight-bold mb-1">ログイン方法</h3>
        <p>メールアドレスでログイン</p>
      </div>
      <div class="mb-6">
        <h3 class="subtitle-2 font-weight-bold mb-1">メールアドレス</h3>
        <p>{{ mail }}</p>
      </div>
      <div>
        <h3 class="subtitle-2 font-weight-bold mb-3">パスワード変更</h3>
        <v-btn
        depressed
        color="primary font-weight-bold"
        :loading="status"
        :disabled="status"
        @click="sendMail"
        >
          パスワード変更用メールを送信する
        </v-btn>
      </div>
    </section>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      >
      <v-card>
        <v-card-title class="text-h6">
          お知らせ
        </v-card-title>
        <v-card-text class="subtitle-1 text-left">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="closeDialog"
          >
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import firebase from 'firebase'
import UserMenu from '@/components/UserMenu.vue'

export default {
  components: {
    UserMenu
  },data() {
    return {
      mail: this.$store.getters.idToken.email,
      status: false,
      dialog: false,
      message:'',
      userId: this.$store.getters.user.uid
    }
  },methods: {
    sendMail () {
      var self = this
      self.status = true
      firebase.auth().sendPasswordResetEmail(this.mail).then(function() {
        self.message = '再設定メールを送信しました'
        self.dialog = true
      }).catch(function(error) {
        console.log(error)
      });
      firebase.analytics().logEvent('reset_password_complete', {});
    },
    closeDialog() {
      this.dialog = false
      this.status = false
    },
    sendVerifiedMail() {
      this.user.sendEmailVerification().then(function() {
        alert('メール送信しました')
      }).catch(function(error) {
        // An error happened.
        console.log(error)
      });
    }
  },
  created() {
  },
  mounted() {
    document.title = this.$route.meta.title
  },
}
</script>

<style lang="scss">
.user-account-main {
  min-width: 400px;
  text-align: left;
  margin-left: 90px;
}

@media (max-width: 768px) {

  .user-account-main {
    margin-top: 40px;
    min-width: initial;
    margin-left: 0px;
  }
  
}

</style>