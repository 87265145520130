<template>
  <div class="aaaaa">
    <div class="bababa text-left">
      <div class="pr-7 pl-7 pt-1 pt-sm-3 pb-0"> 
        <h1 class="text-h6 text-sm-h4 font-weight-bold mb-0">スポット情報を共有</h1>
        <div class="subtitle-2 pb-2 mt-1">
          <p class="mb-0 yaku">みんなが安全にトラブルのない社会づくりのため<br>あなたの知ってるスポット情報を、ぜひご共有ください😉</p>
        </div>
        <v-divider></v-divider>
      </div>

      <v-form ref="form" class="pr-7 pl-7 pt-6 pb-0 cccccccc">
          <v-radio-group
            v-model="form.status" 
            class="mb-0 mt-0"
            v-if="isAdmin"
          >
            <h3 class="subtitle-1 font-weight-bold mb-1 fco">ステータス</h3>
            <v-radio :value="1">
              <template v-slot:label>
                <div class="subtitle-2">表示</div>
              </template>
            </v-radio>
            <v-radio :value="0">
              <template v-slot:label>
                <div class="subtitle-2">非表示</div>
              </template>
            </v-radio>
          </v-radio-group>

          <v-radio-group
            v-model="form.certify" 
            class="mb-0 mt-0"
            v-if="isAdmin"
          >
            <h3 class="subtitle-1 font-weight-bold mb-1 fco">認証</h3>
            <v-radio :value="true">
              <template v-slot:label>
                <div class="subtitle-2">認証あり</div>
              </template>
            </v-radio>
            <v-radio :value="false">
              <template v-slot:label>
                <div class="subtitle-2">認証なし</div>
              </template>
            </v-radio>
          </v-radio-group>

          <v-radio-group
            v-model="form.type" 
            class="mb-0 mt-0"
          >
            <h3 class="subtitle-1 font-weight-bold mb-1 fco">登録するのはどんなスポットですか？</h3>
            <v-radio :value="parseInt(VUE_APP_FLIGHT_SPOT_VALUE)">
              <template v-slot:label>
                <div class="subtitle-2">{{ VUE_APP_FLIGHT_SPOT }}</div>
              </template>
            </v-radio>
            <v-radio :value="parseInt(VUE_APP_NOT_FLIGHT_SPOT_VALUE)">
              <template v-slot:label>
                <div class="subtitle-2">{{ VUE_APP_NOT_FLIGHT_SPOT }}</div>
              </template>
            </v-radio>
          </v-radio-group>

          <div v-if="form.type != 0">
            <div class="mt-1 mb-8">
              <v-divider></v-divider>
            </div>
            <div class="mt-3">
              <h3 class="subtitle-1 font-weight-bold mb-0">そのスポットはどこにありますか？<span class="caption"> （必須）</span></h3>
              <p class="subtitle-2 mt-0 mb-2">マップをズームすると正確な位置を設定できます</p>
              <div>
                <v-text-field
                placeholder="Mapを選択すると緯度経度が入力されます"
                outlined
                readonly
                dense
                v-model="form.center"
                :rules="[rules.required]"
                required
                >
                </v-text-field>
              </div>
              <!-- <v-btn
                @click="hoge"
                dense
                depressed
                small
                :loading="isGeoStatus"
                class="mb-8"
                >
                <span class="font-weight-bold">現在位置をMAPに反映する</span>
              </v-btn> -->
            </div>

            <div class="mt-1">
              <h3 class="subtitle-1 font-weight-bold mb-0">そのスポットの名称をおしえてください</h3>
              <p class="subtitle-2 mt-0 mb-2">例：○○海岸、○○ダム、○○浜、○◯湖 など</p>
              <v-text-field
              placeholder="スポット名として表示されます"
              outlined
              dense
              v-model="form.name"
              :rules="[rules.required]"
              counter="30"
              maxlength="30"
              >
              </v-text-field>
            </div>

            <div v-if="form.type == parseInt(VUE_APP_FLIGHT_SPOT_VALUE)" class="mt-1">
              <h3 class="subtitle-1 font-weight-bold mb-1 fco">そのスポットに駐車場はありますか？</h3>
              <v-radio-group
                v-model="form.parking" 
                :rules="[rules.required]"
                class="mt-0"
                row
              >
                <v-radio :value="VUE_APP_YES">
                  <template v-slot:label>
                    <div class="subtitle-2">ある</div>
                  </template>
                </v-radio>
                <v-radio :value="VUE_APP_NO">
                  <template v-slot:label>
                    <div class="subtitle-2">ない</div>
                  </template>
                </v-radio>
                <v-radio :value="VUE_APP_UNKNOWN">
                  <template v-slot:label>
                    <div class="subtitle-2">わからない</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>

            <div v-if="form.type == parseInt(VUE_APP_FLIGHT_SPOT_VALUE)" class="mt-3">
              <h3 class="subtitle-1 font-weight-bold mb-1 fco">そのスポットにトイレはありますか？</h3>
              <v-radio-group
                v-model="form.restroom"
                class="mb-2 mt-0"
                :rules="[rules.required]"
                row
              >
                <v-radio :value="VUE_APP_YES">
                  <template v-slot:label>
                    <div class="subtitle-2">ある</div>
                  </template>
                </v-radio>
                <v-radio :value="VUE_APP_NO">
                  <template v-slot:label>
                    <div class="subtitle-2">ない</div>
                  </template>
                </v-radio>
                <v-radio :value="VUE_APP_UNKNOWN">
                  <template v-slot:label>
                    <div class="subtitle-2">わからない</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>

            <div class="mt-1 mb-8">
              <v-divider></v-divider>
            </div>

            <div v-if="form.type == parseInt(VUE_APP_FLIGHT_SPOT_VALUE)" class="mt-3">
              <h3 class="subtitle-1 font-weight-bold mb-1">クチコミ</h3>
              <p class="subtitle-2 mt-0 mb-2">届け出に関すること・近隣の飲食店・観光情報などがあれば入力してください</p>
              <v-textarea
                :placeholder="notePlaceholderText"
                outlined
                dense
                v-model="note.description"
                :counter="VUE_APP_REVIEW_COUNT"
                :maxlength="VUE_APP_REVIEW_COUNT"
              >
              </v-textarea>
            </div>

            <div class="mt-1 mb-8">
              <v-divider></v-divider>
            </div>

            <div class="mt-3">
              <h3 class="subtitle-1 font-weight-bold mb-0 fco">タグ</h3>
              <p class="subtitle-2 mt-0 mb-2">スポットの特徴をキーワードで設定できます</p>
              <template>
                <v-combobox
                  v-model="form.tags"
                  :items="items"
                  small-chips
                  placeholder="カンマまたはスペースで区切れます"
                  multiple
                  clearable
                  :delimiters="delimiters"
                  outlined
                  dense
                >
                <template v-slot:selection="{ attrs, item, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click:close="remove(item)"
                    dense
                    outlined
                    label
                    class="mb-1 mt-1"
                  >
                    <span class="caption font-weight-bold">{{ item }}</span>
                  </v-chip>
                </template>
                </v-combobox>
              </template>
            </div>

            <div v-if="form.type == parseInt(VUE_APP_NOT_FLIGHT_SPOT_VALUE)" class="mt-3">
              <h3 class="subtitle-1 font-weight-bold mb-1">禁止されている事がわかる情報元など教えてください</h3>
              <v-textarea
                placeholder="禁止されていることがわかるWebページのURLや、立て札や張り紙の内容など"
                outlined
                dense
                v-model="source.description"
                :counter="VUE_APP_SOURCE_COUNT"
                :maxlength="VUE_APP_SOURCE_COUNT"
              >
              </v-textarea>
            </div>

            <div class="mt-3" v-if="form.type == parseInt(VUE_APP_NOT_FLIGHT_SPOT_VALUE)">
              <h3 class="subtitle-1 font-weight-bold mb-1">画像の設定</h3>
              <p class="subtitle-2 mt-0 mb-2">立て札や張り紙の画像などを投稿できます</p>
              <v-file-input 
                outlined
                accept="image/*" 
                placeholder="画像ファイルを選択してください"
                @change="onFileUpload"
                ref="preview"
                dense
                prepend-inner-icon="mdi-image-area"
                @click:clear="ahoge()"
              ></v-file-input>
            </div>

            <div v-if="src != ''">
              <v-img
                contain
                :src="src"
              >
              </v-img>
            </div>

            <div class="mt-4 mb-8">
              <v-divider></v-divider>
            </div>

            <div v-if="form.type == parseInt(VUE_APP_FLIGHT_SPOT_VALUE)" class="mt-3">
              <h3 class="subtitle-1 font-weight-bold mb-0">管轄の警察署</h3>
              <p class="subtitle-2 mt-0 mb-2">わかる場合は入力してください</p>
              <v-text-field
                placeholder="管轄の警察署名"
                outlined
                dense
                v-model="form.police"
              >
              </v-text-field>
            </div>

            <div class="mt-2">
              <h3 class="subtitle-1 font-weight-bold mb-0">土地管理者・確認先</h3>
              <p class="subtitle-2 mt-0 mb-2">わかる場合は入力してください</p>
              <div class="" v-for="placeAdmin in form.placeAdmins" :key="placeAdmin.id">
                <div class="mb-0">
                  <v-row no-gutters>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        placeholder="土地管理者名・確認先の団体名"
                        outlined
                        dense
                        v-model="placeAdmin.name"
                        class="mr-0 mr-sm-2"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        placeholder="連絡先"
                        outlined
                        dense
                        v-model="placeAdmin.contact"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          <v-btn
            @click="addPlaceAdmin"
            dense
            depressed
            small
            class="mb-12 mr-3"
            :disabled="this.form.placeAdmins[this.form.placeAdmins.length - 1].name == ''"
            >
            <span class="font-weight-bold">追加</span>
          </v-btn>
          <v-btn
            @click="subPlaceAdmin"
            dense
            depressed
            small
            :disabled="this.form.placeAdmins.length < 2"
            class="mb-12"
            >
            <span class="font-weight-bold">削除</span>
          </v-btn>
        </div>

        <div>
          <v-btn
            width="100%"
            color="primary"
            depressed
            rounded
            large
            :disabled="form.type == '' || status"
            @click="checkForm()"
            :loading="status"
          >
          <span class="font-weight-bold">この内容で登録する</span>
          </v-btn>
        </div>

      </v-form>
      <div class="zzzz">
        <v-dialog
          v-model="dialog.status"
          persistent
          max-width="600px"
          z-index=20
          >
          <v-card>
            <v-card-title class="text-h6">
              投稿ありがとうございます
            </v-card-title>
            <v-card-text class="subtitle-1 text-left" v-html="dialog.message">
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="closeDialog"
              >
                閉じる
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <div class="zzzz">
        <v-dialog
          v-model="checkErrorDialog.status"
          persistent
          max-width="600px"
          z-index=20
          >
          <v-card>
            <v-card-title class="text-h6">
              投稿の確認
            </v-card-title>
            <v-card-text class="subtitle-1 text-left" v-html="'未入力の項目があります'">
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="checkErrorDialog.status = false"
              >
                閉じる
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
    
    <gmap-map
      :center="center"
      :zoom="zoom"
      :options="{'mapTypeId':'hybrid','scrollwheel':'true','disableDefaultUI':'false','zoomControl':'true','gestureHandling':'greedy'}"
      @click="mapClick($event)"
      class="hogegoge"
      >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="true"
        :ref="`marker${index}`"
        :icon="markerOptions"
        @dragstart="aaa()"
        @dragend="bbb()"
        @drag="makerMove"
        :animation = anim
      />
    </gmap-map>

    <v-snackbar
      v-model="snackBar.show"
      :color="snackBar.color"
      top
      center
      :timeout="6000"
    >
      <span class="font-weight-bold"> {{ snackBar.message }} </span>
    </v-snackbar>
  </div>
</template>

<script>
// import axios from 'axios'
import firebase from 'firebase'
import { gmapApi } from 'vue2-google-maps'
import Compressor from 'compressorjs'
import prefcode from '@/assets/prefcode.json'

export default {
  data() {
    return {
      markers: [],
      isAdmin:this.$store.getters.idToken.email == 'info@flyers.plus' || this.$store.getters.idToken.email == 'supercub47@gmail.com',
      isGeoStatus: false,
      status: false,
      anim: 4,
      center:{lat: 38.05450058864057,lng: 138.47647180539585},
      form:{
        user: {
          id: this.$store.getters.idToken.uid,
        },
        id: '',
        status: this.isAdmin ? 0 : 1,
        certify: false,
        name: '',
        overview: '',
        tags: [],
        reservedMinDay: 14,
        prefCode: 0,
        prefecture: '',
        municipality: '',
        did: false,
        src: '',
        thumb: '',
        highlights: '',
        description: '',
        address: '',
        googleMapUrl: '',
        parking: process.env.VUE_APP_UNKNOWN,
        restroom: process.env.VUE_APP_UNKNOWN,
        police:'',
        rules: ['Flyers確認中'],
        cautions: ['Flyers確認中'],
        youtubes: [{url:''}],
        twitters: [],
        center: '',
        placeAdmins: [{name:'',contact:''}],
        updateAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        type: 0,
        source: '',
        zoom:16,
        latlngs: [{ value:''}],
        parkings: [{ value:''}],
        launchSites: [{ value:''}],
        mapCenter: '',
      },
      rules: {
        valid:false,
        required: v => !!v || '必須です.',
      },
      markerOptions: {
        url: require('../../assets/img/c.svg'),
        size: {width: 36, height: 36, f: 'px', b: 'px',},
        scaledSize: {width: 36, height: 36, f: 'px', b: 'px',},
      },
      lats:'',
      lngs:'',
      dialog: {
        status: false,
        message: ''
      },
      checkErrorDialog: {
        status: false,
        message: ''
      },
      items: [],
      VUE_APP_REVIEW_COUNT:process.env.VUE_APP_REVIEW_COUNT,
      VUE_APP_SOURCE_COUNT:process.env.VUE_APP_SOURCE_COUNT,
      VUE_APP_FLIGHT_SPOT: process.env.VUE_APP_FLIGHT_SPOT,
      VUE_APP_FLIGHT_SPOT_VALUE: process.env.VUE_APP_FLIGHT_SPOT_VALUE,
      VUE_APP_NOT_FLIGHT_SPOT: process.env.VUE_APP_NOT_FLIGHT_SPOT,
      VUE_APP_NOT_FLIGHT_SPOT_VALUE: process.env.VUE_APP_NOT_FLIGHT_SPOT_VALUE,
      VUE_APP_YES: process.env.VUE_APP_YES,
      VUE_APP_NO: process.env.VUE_APP_NO,
      VUE_APP_UNKNOWN: process.env.VUE_APP_UNKNOWN,
      delimiters:[',',' ','　'],
      file: '',
      src: '',
      snackBar: {
        show: false,
        color: '',
        message: ''
      },
      photo:{
        id: '',
        spotid: '',
        uid: this.$store.getters.idToken.uid,
        src:'',
        title:'',
        description:'',
        status: 0,
        ban: false,
        updateAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      },
      note:{
        id: '',
        spotid: '',
        uid: this.$store.getters.idToken.uid,
        description:'',
        status: 0,
        ban: false,
        updateAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      },
      source:{
        id: '',
        spotid: '',
        uid: this.$store.getters.idToken.uid,
        description:'',
        status: 0,
        ban: false,
        updateAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      },
      notePlaceholderText:"届け出は電話で終わります。\n帰りに食べた近くの中華料理さんの回鍋肉が最高でした。"
    }
  },
  computed: {
    google: gmapApi,
    zoom () {
      switch (this.$vuetify.breakpoint.name) {
        case 'sm':
        case 'xs':
        return 5
        default:
        return 6
      }
    }
  },
  mounted() {
    document.title = this.$route.meta.title
  },
  methods: {
    remove (item) {
      this.form.tags.splice(this.form.tags.indexOf(item), 1)
      this.form.tags = [...this.form.tags]
    },
    hoge(){
      this.isGeoStatus = true

      var self = this
      navigator.geolocation.getCurrentPosition(
        function( position )
        {
          var lat = position.coords.latitude
          var lng = position.coords.longitude

          self.center = {lat:lat,lng:lng}
          self.zoom = 10
          self.isGeoStatus = false

        },
        function( error )
        {
          var errorInfo = [
            "原因不明のエラーが発生しました…。" ,
            "位置情報の取得が許可されませんでした…。" ,
            "電波状況などで位置情報が取得できませんでした…。" ,
            "位置情報の取得に時間がかかり過ぎてタイムアウトしました…。"
          ] ;

          var errorNo = error.code
          var errorMessage = "[エラー番号: " + errorNo + "]\n" + errorInfo[ errorNo ]
          alert( errorMessage )
        },
        {
          "enableHighAccuracy": false,
          "timeout": 8000,
          "maximumAge": 2000,
        }
      )
    },
    mapClick(event) {
      var lats = event.latLng.lat()
      var lngs = event.latLng.lng()

      this.markers = []

      var arr = { position: { lat: lats, lng: lngs }, tag: this.markers.length}

      this.markers.push(arr)
      this.setLatlng(lats,lngs)
      this.lats = lats
      this.lngs = lngs
    },
    setLatlng(lats,lngs) {
      this.form.center = lats+','+lngs
    },
    submit() {

      this.status = true
      var self = this

      var geocoder = new this.google.maps.Geocoder();

      const latlng = {
        lat: parseFloat(this.lats),
        lng: parseFloat(this.lngs),
      }

      geocoder.geocode({ location: latlng }, (results, status) => { // eslint-disable-line
        if(results.length != 0) {
          results.forEach(element => {
            switch (element.types[0]) {
              case 'political':
                if(this.form.address == ''){
                  this.form.address = element.formatted_address.split(' ')[1]
                }
                break
              case 'street_address':
                this.form.address = element.formatted_address.split(' ')[1]
                break
              case 'administrative_area_level_1':
                this.form.prefecture = element.address_components[0].long_name
                this.form.prefCode = this.getPrefCode(this.form.prefecture)
                break
              case 'locality':
                this.form.municipality = element.address_components[0].long_name
                break
              case 'plus_code':
                if(element.address_components.length == 4){
                  if(this.form.prefecture == ''){
                    this.form.prefecture = element.address_components[2].long_name
                    this.form.prefCode = this.getPrefCode(this.form.prefecture)
                  }
                  if(this.form.municipality == ''){
                    this.form.municipality = element.address_components[1].long_name
                  }
                } else if(element.address_components.length == 5){
                  if(this.form.prefecture == ''){
                    this.form.prefecture = element.address_components[3].long_name
                    this.form.prefCode = this.getPrefCode(this.form.prefecture)
                  }
                  if(this.form.municipality == ''){
                    this.form.municipality = element.address_components[1].long_name
                  }
                } 
                else {
                  this.form.address = '不明'
                  this.form.prefecture = '不明'
                  this.form.municipality = '不明'
                }
                break
            
              default:
                break
            }
          })
        } else {
          this.form.address = '不明'
          this.form.prefecture = '不明'
          this.form.municipality = '不明'
        }
        if(this.file != ""){
          var storage = firebase.storage()
          var storageRef = storage.ref()
          var imagesRef = storageRef.child('users/'+ this.$store.getters.user.uid +'/photo')
          var spaceRef = imagesRef.child(this.creatFileName(this.file.name))
          var contentType = this.file.type
          var uploadTask = spaceRef.put(this.file,contentType)

          uploadTask.on('state_changed', function(snapshot){ // eslint-disable-line
            }, function(error) { // eslint-disable-line
            }, function() {
            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
              self.saveData(downloadURL)
            })
          })
        } else {
          self.saveData('')
        }
      })
    },
    makerMove(params) {
      this.setLatlng(params.latLng.lat(),params.latLng.lng())
    },
    addPlaceAdmin() {
      this.form.placeAdmins.push({name:''})
    },
    subPlaceAdmin() {
      this.form.placeAdmins.pop()
    },
    addYoutubes() {
      this.form.youtubes.push({url:''})
    },
    subYoutubes() {
      this.form.youtubes.pop()
    },
    closeDialog() {
      this.$router.push({name:'UsersSpotDetail', params:{
        id: this.form.id
      }})
    },
    aaa() {
      this.anim = 3
    },
    bbb() {
      this.anim = 4
    },
    onFileUpload(file) {
      var self = this

      if(file != null){
        new Compressor(file, {
          quality: 0.6,
          maxWidth: 1600,
          maxHeight: 1600,
          success(result) {
            self.file = result
            self.src = URL.createObjectURL(result)
          },
          error(err) {
            console.log(err.message);
          }
        })
      }
    },
    ahoge() {
      this.file = ''
      this.src = ''
    },
    saveData(src) {
      var db = firebase.firestore().collection("userFlightSpots").doc()
      this.form.id = db.id

      if(src != ''){
        this.photo.spotid = db.id
        this.photo.src = src
      }

      if(this.source.description != ''){
        this.source.spotid = db.id
      }

      if(this.note.description != ''){
        this.note.spotid = db.id
      }

      var self = this

      db.set(self.form)
      .then(function() {

        if(src != ''){

          var spdb = firebase.firestore().collection("spotPhotos").doc()
          self.photo.id = spdb.id

          spdb.set(self.photo)
          .then(function() {

          })
          .catch(function(error) {
            console.error("Error writing document: ", error);
          })
        } 

        if(self.source.description != ''){

          var sdb = firebase.firestore().collection("spotSources").doc()
          self.source.id = sdb.id
          
          sdb.set(self.source)
          .then(function() {

          })
          .catch(function(error) {
            console.error("Error writing document: ", error);
          })
        }

        if(self.note.description != ''){

          var db = firebase.firestore().collection("spotNotes").doc()
          self.note.id = db.id
          
          db.set(self.note)
          .then(function() {

          })
          .catch(function(error) {
            console.error("Error writing document: ", error);
          })
        }

        self.dialog.status = true
        self.dialog.message = 'スポット情報が登録されました。<br/> 以下の情報の修正をご希望の場合は、お問い合わせよりお願いします。<br>・スポット名<br>・ピンの位置<br>・駐車場の有無<br>・トイレの有無<br>・管轄の警察書<br>・土地管理者情報'
        self.status = false
      })
      .catch(function(error) {
        console.error("Error writing document: ", error);
      })
    },
    getPrefCode(prefName){

      var result = 0

      prefcode.forEach(element => {
        if(element.prefName == prefName){
          result = element.prefCode
        }
      })

      return result
    },
    creatFileName(name) {
      var lastIndex = name.lastIndexOf('.')
      var pureName = name.substring(0,lastIndex)
      var ex = name.substring(lastIndex,name.length)
      return pureName + '_' + new Date().getTime().toString() + ex
    },
    checkForm() {
      if(this.$refs.form.validate()) {
        this.submit()
      } else {
        this.checkErrorDialog.status = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.aaaaa {
  display: grid;
  grid-template-columns: 600px 1fr;
  height: calc(100vh - 56px);
  .bababa {
    overflow-y: auto;
    padding: 20px 00px 40px 00px;
    a {
      color: #2c3e50;
    }
    .userspot {
      padding: 20px 10px 20px 20px;
      border-bottom: solid 1px rgba(0, 0, 0, 0.1);
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }
}
.cccccccc {
  overflow-y: auto;
}
.fco {
  color:#2c3e50;
}

@media (max-width: 600px) {

  .aaaaa {
    grid-template-columns: 1fr;
    grid-template-rows: 50% 50%;
    height: calc(100vh - 56px);
    margin-top: 56px;
  }
  .bababa {
    order: 2;
  }
}


</style>