<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
import firebase from 'firebase'

export default {
    components: {
  },
  data() {
    return {
      email: ''
    }
  },
  methods: {
    logout: function () {
      this.$store.commit('updateIdToken', null);
      this.$store.commit('updateDisplayName', null);
      this.$store.commit('updateUserIconSrc', null);
      this.$store.commit('updateUser', null);
      this.$store.commit('updateFlightSpot', null);
      this.$store.commit('userFlightSpotSelectValue', null);
      this.$store.commit('searchValue', null);

      firebase.auth().signOut()
      firebase.analytics().logEvent('logout', {});
      this.$router.go({path: this.$router.currentRoute.path, force: true})
    },
    redirect: function () {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          this.$store.commit('updateIdToken', user)
        } else {
          this.$router.push('/auth/login')
        }
      });
    },
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.idToken !== null
    }
  },
  created() {
  },
}
</script>

<style lang="scss" scoped>
.new-header {
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.07);
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.avater {
  opacity: 1;
  transition-duration:0.6s;
  &:hover {
    opacity: 0.7;
    transition-duration:0.2s;
  }
}
</style>