<template>
  <div class="information">
    <v-icon left medium dense class="black--text">fas fa-map-marker-alt</v-icon>
    <p class="subtitle-2 address mr-4 ml-1">{{ prefecture }} / {{ municipality }}</p>
    <img src="@/assets/img/did.svg" v-if="did">
    <img src="@/assets/img/undid.svg" v-if="!did">
  </div>
</template>

<script>
export default {
  props:{
    prefecture: String,
    municipality: String,
    did: Boolean
  }
}
</script>

