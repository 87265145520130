import Vue from 'vue'
import VueRouter from 'vue-router'
import Confirm from '../views/app/Confirm.vue'
import ReserveComplete from '../views/app/Complete.vue'
import Form from '../views/app/Form.vue'
import Complete from '../views/auth/Complete.vue'
import Login from '../views/auth/Login2.vue'
import Signup from '../views/auth/Signup2.vue'
import Details from '../views/flightSpot/Detail.vue'
import List from '../views/flightSpot/List2.vue'
import Index from '../views/Index2.vue'
import Index3 from '../views/Index4.vue'
import SpotRequest from '../views/SpotRequest.vue'
import About from '../views/About.vue'
import Privacy from '../views/Privacy.vue'
import Terms from '../views/Terms.vue'
import Faq from '../views/Faq.vue'
import ResetPass from '../views/auth/ResetPass2.vue'
import Userspot from '../layout/Userspot2.vue'
import UserspotList from '../layout/UserspotList.vue'
import Home from '../layout/Home.vue'
import Dev from '../views/Devtool.vue'
import FlightSpotEdit2 from '../views/admin/flightspot/Regist.vue'
import FlightSpotEdit from '../views/admin/flightspot/UserSpotEdit.vue'
import OldFlightSpotList from '../views/admin/flightspot/List.vue'
import FlightSpotList from '../views/admin/flightspot/NewList.vue'
import UsersPost from '../views/app/UsersPost2.vue'
import UsersHome from '../views/users/Home.vue'
import UsersAccount from '../views/users/Account.vue'
import UsersQualification from '../views/users/Qualification.vue'
import UsersProfile from '../views/users/Profile.vue'
import UsersSpotDetail from '../views/flightSpot/UserSpotDetail.vue'
import Beginner from '../views//Beginner.vue'
import Agent from '../views/AgentService.vue'
import Community from '../views//CommunityGuideLine.vue'
import UserDetail from '../views/users/Detail.vue'
import UserBookmarks from '../views/users/UserBookmarks.vue'

Vue.use(VueRouter)

var titleBase = ' - Flyers（フライヤーズ）'

const routes = [
  {
    path: '/home',
    component: Home,
    children:[
      {
        path: '/home',
        name: 'Index',
        component: Index
      },

    ]
  },
  {
    path: '/userspot',
    name: 'UserspotList',
    component: UserspotList,
    children: [
      {
        path: '/userspot',
        name: 'List',
        component: List
      },
      {
        path: '/post',
        name: 'UsersPost',
        component: UsersPost,
        meta:{ title:'スポット情報投稿' + titleBase }
      },
      {
        path: '/users/:id/detail',
        name: 'UserDetail',
        component: UserDetail,
        meta:{title:'ユーザー詳細' + titleBase}
      },
      {
        path: '/users/:id/list',
        name: 'UserBookmarks',
        component: UserBookmarks,
        meta:{title:'リスト' + titleBase}
      },
    ]
  },
  {
    path: '/',
    component: Userspot,
    children: [
      {
        path: '/',
        name: 'Index3',
        component: Index3,
        meta:{title:'Flyers（フライヤーズ） | ドローンをもっと気軽に、より安全に。'}
      },
      {
        path: '/auth/login',
        name: 'Login',
        component: Login,
        meta:{title:'ログイン' + titleBase}
      },
      {
        path: '/auth/signup',
        name: 'Signup',
        component: Signup,
        meta:{title:'ユーザ登録' + titleBase}
      },
      {
        path: '/auth/complete',
        name: 'Complete',
        component: Complete,
        props: true,
        meta:{title:'ユーザ登録完了' + titleBase}
      },
      {
        path: '/auth/reset',
        name: 'ResetPass',
        component: ResetPass,
        meta:{title:'パスワードを忘れた方へ' + titleBase}
      },
      {
        path: '/dev',
        name: 'Dev',
        component: Dev
      },
    ]
  },
  {
    path: '/userspot',
    component: Userspot,
    children: [
      {
        path: '/guidelines',
        name: 'Community',
        component: Community,
        meta:{title:'コミュニティガイドライン' + titleBase}
      },
      {
        path: '/beginner',
        name: 'Beginner',
        component: Beginner,
        meta:{title:'はじめての方へ' + titleBase}
      },
      {
        path: '/agentservice',
        name: 'Agent',
        component: Agent,
        meta:{title:'飛行手続申請代行' + titleBase}
      },
      {
        path: '/userspot/:id/detail',
        name: 'UsersSpotDetail',
        component: UsersSpotDetail,
        props: true,
      },
      {
        path: '/users/home',
        name: 'UsersHome',
        component: UsersHome,
        meta:{title:'アカウントホーム' + titleBase}
      },
      {
        path: '/users/account',
        name: 'UsersAccount',
        component: UsersAccount,
        meta:{title:'アカウント' + titleBase}
      },
      {
        path: '/users/qualification',
        name: 'UsersQualification',
        component: UsersQualification
      },
      {
        path: '/users/profile',
        name: 'UsersProfile',
        component: UsersProfile,
        meta:{title:'プロフィール編集' + titleBase}
      },
      {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy,
        meta:{title:'プライバシーポリシー' + titleBase}
      },
      {
        path: '/terms',
        name: 'Terms',
        component: Terms,
        meta:{title:'利用規約' + titleBase}
      },
      {
        path: '/spotrequest',
        name: 'SpotRequest',
        component: SpotRequest,
        meta:{title:'スポットリクエスト' + titleBase}
      },
      {
        path: '/userspot/:id/form/',
        name: 'Form',
        component: Form,
        meta:{title:'利用申請フォーム' + titleBase}
      },
      {
        path: '/userspot/:id/confirm/',
        name: 'Confirm',
        component: Confirm,
        props: true,
        meta:{title:'利用申請確認' + titleBase}
      },
      {
        path: '/complete',
        name: 'ReserveComplete',
        component: ReserveComplete,
        meta:{title:'利用申請完了' + titleBase}
      },
      {
        path: '/faq',
        name: 'Faq',
        component: Faq,
        meta:{title:'よくあるご質問' + titleBase}
      },
      {
        path: '/about',
        name: 'About',
        component: About,
        meta:{title:'運営会社' + titleBase}
      },
      {
        path: '/admin/flightspot/list',
        name: 'FlightSpotList',
        component: FlightSpotList,
        props: route => ({ id: Number(route.params.id) })
      },
      {
        path: '/admin/flightspot/oldlist',
        name: 'OldFlightSpotList',
        component: OldFlightSpotList,
        props: route => ({ id: Number(route.params.id) })
      },
      {
        path: '/admin/flightspot/edit/:id',
        name: 'FlightSpotEdit',
        component: FlightSpotEdit
      },
      {
        path: '/admin/flightspot/edit2/:id',
        name: 'FlightSpotEdit2',
        component: FlightSpotEdit2
      },
      {
        path: '/flightspot/:id/detail/',
        name: 'Details',
        component: Details
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
