<template>
  <div>
    <section class="faq-container pt-4">
      <h2 class="text-h4 font-weight-bold text-center mt-16 mb-16">よくあるご質問</h2>
      <span class="text-left">
        <div>
          <h3 class="text-h6 font-weight-bold"><span class="text-h5 font-weight-bold">Q</span> ： アカウント登録すると何ができますか？</h3>
          <p class="mt-1">Flyersβ版サービスを利用し、各自治体や管理者へ飛行申請を行うことが出来ます。</p>
        </div>
        <div>
          <h3 class="text-h6 font-weight-bold"><span class="text-h5 font-weight-bold">Q</span> ： フライトスポットリクエスト ってどんな場所でも大丈夫ですか？</h3>
          <p class="mt-1">小型無人機等飛行禁止法で指定される重要施設及びその周囲おおむね300mの周辺地域はリクエストすることはできません。</p>
          <blockquote class="subtitle-2">
            <p class="mb-0">＜小型無人機等禁止法に基づき指定する施設＞</p>
            <ul class="mb-4">
              <li>国の重要な施設等</li>
              <ul>
                <li>国会議事堂、内閣総理大臣官邸、最高裁判所、皇居等</li>
                <li>危機管理行政機関の庁舎</li>
                <li>対象政党事務所</li>
              </ul>
              <li>対象外国公館等</li>
              <li>対象防衛関係施設（令和元年改正で追加）</li>
              <li>対象空港（令和２年改正で追加）</li>
              <li>対象原子力事業所</li>
            </ul>
            <p class="caption mb-0">引用：警察庁（<a href="https://www.npa.go.jp/bureau/security/kogatamujinki/index.html" target="_blank">https://www.npa.go.jp/bureau/security/kogatamujinki/index.html</a>）</p>
          </blockquote>
          <p class="mt-6 mb-0">県単位または市町村単位での範囲指定もご遠慮ください。</p>
          <p class="mb-6">フライトスポット詳細の飛行可能範囲を参考に<span class="font-weight-bold">『施設や景勝地の周辺で概ねバッテリー1本で往復出来る範囲』</span>をご指定ください。</p>
        </div>
        <div>
          <h3 class="text-h6 font-weight-bold"><span class="text-h5 font-weight-bold">Q</span> ： スポットリクエストに料金はかかりますか？</h3>
          <p class="mt-1">β版では無料でご利用いただけます。</p>
        </div>
        <div>
          <h3 class="text-h6 font-weight-bold"><span class="text-h5 font-weight-bold">Q</span> ： 飛行申請範囲ってなんですか？</h3>
          <p class="mt-1 mb-0">Flyersで飛行申請が可能な範囲です。</p>
          <p class="mt-1 mb-0">もしこの範囲よりも広い範囲でドローンを飛ばしたい場合は、スポットリクエストをご利用ください。</p>
          <p class="mt-4 mb-0">スポットリクエストは以下リンクをご利用ください</p>
          <router-link to="/spotrequest" class="mt-1 mb-0">スポットリクエストフォーム</router-link>
        </div>
        <div>
          <h3 class="text-h6 font-weight-bold  mt-4"><span class="text-h5 font-weight-bold">Q</span> ： 予約した日が天候不順だった場合どうなりますか？</h3>
          <p class="mt-1">申請時に設定した予備日をご利用ください。</p>
        </div>
        <div>
          <h3 class="text-h6 font-weight-bold"><span class="text-h5 font-weight-bold">Q</span> ： キャンセルしたいのですがどうしたらいいですか？</h3>
          <p class="mt-1 mb-0">キャンセルは以下リンクをご利用ください </p>
          <a href="https://forms.gle/BXuHdHQXMNhCAZao7" target="_blank" class="mt-0 mb-0">キャンセルフォーム</a><v-icon x-small class="pb-1 pl-1">fas fa-external-link-alt</v-icon>
        </div>
        <div>
          <h3 class="text-h6 font-weight-bold  mt-4"><span class="text-h5 font-weight-bold">Q</span> ： 人口集中地区（DID地区）ってなんですか？</h3>
          <p class="mt-1 mb-3">無人航空機を飛行させる場合に、あらかじめ国土交通大臣の許可を受ける必要がある地区です。</p>
          <blockquote class="subtitle-2">
            <p>人口集中地区の設定に当たっては、国勢調査の基本単位区（※Q&A2-1参照）及び基本単位区内に複数の調査区がある場合は調査区（以下「基本単位区等」という。）を基礎単位として、</p>
            <p class="mb-0">1、原則として人口密度が1平方キロメートル当たり4,000人以上の基本単位区等が市区町村の境域内で互いに隣接して、</p>
            <p>2、それらの隣接した地域の人口が国勢調査時に5,000人以上を有する</p>
            <p class="mb-0">この地域を「人口集中地区」としています。</p>
            <p>個々の基本単位区等が人口密度の基準を満たしていても、隣接した地域の人口が5,000人未満の場合は人口集中地区とはなりません。</p>
            <p class="caption mb-0">引用：総務省統計局（<a href="https://www.stat.go.jp/data/chiri/qa/qa-1.html" target="_blank">https://www.stat.go.jp/data/chiri/qa/qa-1.html</a>）</p>
          </blockquote>
        </div>
        <div>
          <h3 class="text-h6 font-weight-bold"><span class="text-h5 font-weight-bold">Q</span> ： フライトスポットって増えますか？</h3>
          <p class="mt-1 mb-0">はい！随時追加していく予定です。</p>
          <p class="mt-1">ご期待ください👍</p>
        </div>
        <div>
          <h3 class="text-h6 font-weight-bold"><span class="text-h5 font-weight-bold">Q</span> ： 自分のSNSでの発信もフライトスポット詳細に掲載してほしいんですが、そういうことは可能ですか？</h3>
          <p class="mt-1 mb-0">ありがとうございます、 掲載依頼は以下リンクをご利用ください。</p>
          <a href="https://forms.gle/RtwPXpuL4MBEMz9A7" target="_blank" class="mt-0">Flyersβ版 SNS掲載依頼フォーム</a><v-icon x-small class="pb-1 pl-1">fas fa-external-link-alt</v-icon>
        </div>
        <div>
          <h3 class="text-h6 font-weight-bold mt-4"><span class="text-h5 font-weight-bold">Q</span> ： ドローンの保険に入っていないんですが大丈夫ですか？</h3>
          <p class="mt-1">回答作成中</p>
        </div>
        <div>
          <h3 class="text-h6 font-weight-bold"><span class="text-h5 font-weight-bold">Q</span> ： ドローンを飛ばせる場所を知ってますが、情報提供可能ですか？</h3>
          <p class="mt-1">回答作成中</p>
        </div>
        <div>
          <h3 class="text-h6 font-weight-bold"><span class="text-h5 font-weight-bold">Q</span> ： 他のユーザと予約がかぶった場合はどうなりますか？</h3>
          <p class="mt-1">回答作成中</p>
        </div>
        
      </span>
    </section>
  </div>
</template>

<script>
export default {
  mounted() {
    document.title = this.$route.meta.title
  },
}
</script>

<style lang="scss" scoped>
blockquote {
  padding: 30px;
  border: 1px solid rgba(0,0,0,0.2);
}
</style>

https://forms.gle/EfHNFVyce7BUME5S8