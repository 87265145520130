<template>
  <div>
    <div class="container">
      <v-row
        justify="center"
        class="mt-10"
      >
      </v-row>
      <div class="information mt-6 mb-3 mt-md-6">
        <div class="d-flex align-center">
          <v-img 
            max-height="26px"
            max-width="23px"
            class="mr-1"
            v-bind:class="{'opa': !flightSpot.certify }"
            :src="require('@/assets/img/icon_cirtify.svg')"/>
          <v-img 
            max-height="26px"
            max-width="20px"
            class="mr-2"
            :src="getTypeIconSrc(flightSpot.type)"/>
          <p class="caption text-sm-subtitle-2 mb-0 pt-0">{{ flightSpot.prefecture }} / {{ flightSpot.municipality }}</p>
        </div>
      </div>
      <h2 class="text-sm-h2 text-h4 font-weight-bold mb-8">{{ flightSpot.name }}</h2>

      <h1 class="mb-4">利用申請確認</h1>      

      <div class="mb-8">
        <h3 class="text-h6 font-weight-bold mb-0">
          <v-icon medium class="pr-1 pb-2 primary--text">far fa-calendar-alt</v-icon>
          フライト予定日
        </h3>
        <p><span class="text-h6">{{ formatDate(new Date(date)) }}</span></p>
      </div>

      <div class="mb-8">
        <h3 class="text-h6 font-weight-bold mb-0">
          <v-icon medium class="pr-1 pb-2 primary--text">far fa-clock</v-icon>
          利用予定時間
        </h3>
        <p><span class="text-h6">{{ start }}</span> から <span class="text-h6">{{ end }}</span> まで、<span class="text-h6">{{ t }}</span>時間の利用予定</p>
      </div>

      <div class="mb-8">
        <h3 class="text-h6 font-weight-bold mb-0">
          <v-icon medium class="pr-1 pb-1 primary--text">mdi-quadcopter</v-icon>
          使用する機体
        </h3>
        <p><span class="text-h6">{{ drone }}</span>（損害賠償保険の保証対象）</p>
      </div>

      <div class="mb-8">
        <h3 class="text-h6 font-weight-bold mb-0">
          <v-icon medium class="pr-1 pb-1 primary--text">fas fa-bullseye</v-icon>
          ご利用目的
        </h3>
        <p><span class="text-h6">{{ purpose }}</span></p>
      </div>

      <div class="mb-8">
        <h3 class="text-h6 font-weight-bold mb-0">
          <v-icon medium class="pr-1 pb-1 primary--text">fas fa-coins</v-icon>
          ご利用形態
        </h3>
        <p><span class="text-h6">{{ isCommercial ? '個人での利用（非商用）':'業務や委託での利用（商用）' }}</span></p>
      </div>

      <div class="mb-16">
        <h3 class="text-h6 font-weight-bold mb-0">
          <v-icon medium class="pr-1 pb-2 primary--text">far fa-calendar-alt</v-icon>
          個人に関する情報入力
        </h3>
        <p class="mb-0 mt-2">メールアドレス：<span class="subtitle-1 font-weight-bold">{{ mail }}</span></p>
        <p class="mb-0 mt-1">お名前：<span class="subtitle-1 font-weight-bold">{{ name }}</span></p>
        <p class="mb-0 mt-1">お住まい：<span class="subtitle-1 font-weight-bold">{{ address }}</span></p>
        <p class="mb-0 mt-1">携帯電話番号:<span class="subtitle-1 font-weight-bold">{{ tel }}</span></p>
      </div>

      <section class="flightspot-information">
        <h2 class="text-h4 font-weight-bold mb-4">フライトスポット情報</h2>
          <div>
            <gmap-map 
              :center="center" 
              :zoom="zoom" 
              style="width:100%; height:600px;"
              :options="{'mapTypeId':'hybrid'}"
              >

              <span v-if="isLatlng">
                <gmap-polygon
                  :options="{'strokeColor': '#00b3ff','fillColor': '#0089FF','strokeWeight': '2', 'fillOpacity': '0.2' }" 
                  :path="latlngs" 
                >
                </gmap-polygon>
              </span>

              <span v-if="islaunchSite">
                <span v-for="launchSite in launchSites" :key="launchSite.key">
                  <gmap-polygon
                    :options="{'strokeColor': '#ff8a00','fillColor': '#ff8a00','strokeWeight': '2', 'fillOpacity': '0.2'}" 
                    :path="launchSite"
                  >
                  </gmap-polygon>
                </span>
              </span>

              <span v-if="isParking">
                <span v-for="parking in parkings" :key="parking.key">
                  <gmap-polygon
                    :options="{'strokeColor': '#eb5ddc','fillColor': '#eb5ddc','strokeWeight': '2', 'fillOpacity': '0.2', 'zIndex': '4'}" 
                    :path="parking" 
                  >
                  </gmap-polygon>
                </span>
              </span>
            </gmap-map>
          </div>

          <div class="mt-4">
            <v-btn 
              outlined
              color="#0093ff"
              @click="isLatlng = !isLatlng"
              class="mb-4 mr-4"
            >
            <v-icon class="mr-2" v-if="isLatlng">mdi-eye</v-icon>
            <v-icon class="mr-2" v-else>mdi-eye-off</v-icon>
            飛行申請範囲
            </v-btn>
            <v-btn 
              outlined
              color="#ff8a00"
              @click="islaunchSite = !islaunchSite"
              class="mb-4 mr-4"
              Depressed
              elevation="0"
              :disabled="launchSites.length < 1"
            >
            <v-icon class="mr-2" v-if="islaunchSite">mdi-eye</v-icon>
            <v-icon class="mr-2" v-else>mdi-eye-off</v-icon>
            オススメの離着陸場所
            </v-btn>
            <v-btn 
              outlined
              color="#eb5ddc"
              @click="isParking = !isParking"
              class="mb-4"
              :disabled="parkings.length < 1"
            >
            <v-icon class="mr-2" v-if="isParking">mdi-eye</v-icon>
            <v-icon class="mr-2" v-else>mdi-eye-off</v-icon>
            駐車場
            </v-btn>
          </div>

        <div class="description">
          <div class="address">
            <h3 class="text-h6 font-weight-bold mb-1">住所</h3>
            <p class="mb-2">{{ flightSpot.address }}</p>
          </div>
          <div class="facility">
            <h3 class="text-h6 font-weight-bold mb-1">設備について</h3>
            <h4 class="subtitle-1 font-weight-bold">駐車場</h4>
              <p>{{ flightSpot.parking ? '有' : '無'}}</p>
            <h4 class="subtitle-1 font-weight-bold">トイレ</h4>
              <p>{{ flightSpot.restroom ? '有' : '無' }}</p>
          </div>
          <div class="rule" v-if="flightSpot.rules != ''">
            <h3 class="text-h6 font-weight-bold mb-1">現地ルール</h3>
            <ul class="red--text text--darken-1">
              <li v-for="rule in flightSpot.rules" :key="rule">{{ rule }}</li>
            </ul>
          </div>
          <div class="caution" v-if="flightSpot.cautions != ''">
            <h3 class="text-h6 font-weight-bold mb-1">注意事項</h3>
            <ul class="red--text text--darken-1">
              <li v-for="caution in flightSpot.cautions" :key="caution">{{ caution }}</li>
            </ul>
          </div>
        </div>
      </section>

      <div class="submit">
        <div class="checkboxs font-weight-bold mb-16">
          <v-checkbox 
            v-if="flightSpot.rules != ''"
            hide-details 
            v-model="checkbox1" 
            label="現地ルールを十分理解しました"
            @change="check"
          >
          </v-checkbox>
          <v-checkbox 
             v-if="flightSpot.cautions != ''"
            hide-details 
            v-model="checkbox2" 
            label="注意事項を十分理解しました"
            @change="check"
          >
          </v-checkbox>
          <v-checkbox 
            hide-details
            v-model="checkbox3"
            label="利用規約に同意します"
            @change="check"
          >
          </v-checkbox>
          <p class="mt-2 subtitle-2 text-left"><a href="/terms" target="_blank" >利用規約</a> はこちら</p>
        </div>
        <v-btn
          height="55px"
          color="primary"
          x-large
          rounded
          depressed
          :disabled="aaa"
          @click="sendEmail"
          class="pl-10 pr-10"
        >
        <v-icon left class="pr-6">fas fa-check-circle</v-icon>
        <span class="text-h6 font-weight-bold">利用申請を確定する</span>
        </v-btn>
        <p class="text-subtitle-2 font-weight-bold pt-4">※ご利用料金はかかりません</p>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import emailjs from 'emailjs-com';

export default {
  props:[
    'date',
    'drone',
    'mail',
    'name',
    'address',
    'tel',
    'start',
    'end',
    't',
    'purpose',
    'isCommercial'
    ],
  data:function() {
    return{
      flightSpot: [],
      center: {'lat':41.957847822253894 , 'lng':140.12988176118338},
      paths: [{}],
      zoom: 15,
      checkbox1:false,
      checkbox2:false,
      checkbox3:false,
      aaa: true,
      islaunchSite:true,
      isLatlng:true,
      isParking:true,
      latlngs:[],
      launchSites:[],
      parkings:[],
    }
  },
  beforeCreate() {
    var self = this

    firebase.firestore().collection("userFlightSpots")
    .where("id", "==", this.$route.params.id)
    .get()
    .then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
          self.flightSpot = doc.data()
      })

      self.center = self.getLatLng(self.flightSpot.mapCenter != '' ? self.flightSpot.mapCenter : self.flightSpot.center)

      self.latlngs = self.changeToPath(self.flightSpot.latlngs)
      self.launchSites = self.changeToPath(self.flightSpot.launchSites)
      self.parkings = self.changeToPath(self.flightSpot.parkings)

      if(self.flightSpot.rules == ''){
        self.checkbox1 = true
      }
      if(self.flightSpot.cautions == ''){
        self.checkbox2 = true
      }
      firebase.analytics().logEvent('form_confirm', {});
    });
  },
  mounted() {
    document.title = this.$route.meta.title
  },
  created: function () {
  },
  methods: {
    check() {
      if(this.checkbox1 && this.checkbox2 && this.checkbox3){
        this.aaa = false
      }else {
        this.aaa = true
      }
    },
    submit() {
      this.$router.push('/complete')
    },
    formatDate(date) {
      var year = date.getFullYear();
      var month = ('00' + (date.getMonth()+1)).slice(-2);
      var day = ('00' + date.getDate()).slice(-2);
      var week = ['日','月','火','水','木','金','土'][date.getDay()]

      return (year + '年' + month + '月' + day + '日（' + week +'曜日）')
    },
    sendEmail() {
      var self = this
      var params = {
          reservationNumber:this.getReservationNumber(),
          date: this.formatDate(new Date(this.date)),
          drone: this.drone,
          mail: this.mail,
          name: this.name,
          address: this.address,
          tel: this.tel,
          start: this.start,
          end: this.end,
          t: this.t,
          spotName: this.flightSpot.name,
          purpose: this.purpose,
          isCommercial: this.isCommercial ? '個人での利用（非商用）':'業務や委託での利用（商用）'

        }
      try {
        emailjs.send(process.env.VUE_APP_MAIL_SERVICE_ID, process.env.VUE_APP_MAIL_TEMPLATE_ID, params, process.env.VUE_APP_MAIL_USER_ID)
        .then(function(response) {
          if(response.status == 200){
            self.$router.push('/complete')
          }
        }, function(error) {
          console.log('FAILED...', error);
        });
      } catch(error) {
          console.log('error')
          console.log({error})
      }
    },
    getReservationNumber() {
      var date = new Date();
      var yyyy = date.getFullYear();
      var mm = ("0"+date.getMonth()+1).slice(-2);
      var dd = ("0"+date.getDate()).slice(-2);
      var hh = ("0"+date.getHours()).slice(-2);
      var mi = ("0"+date.getMinutes()).slice(-2);
      return yyyy + mm + dd + hh + mi;
    },   
    setLatLngs(latlng) {

      var result = []
      var arr = latlng.split('\n')

      arr.forEach(element => {
        var arrs = element.split(',')
        result.push({'lat': parseFloat(arrs[0]) , 'lng':parseFloat(arrs[1])})
      });

      return result
    },
    setlaunchSite(value){
      var result = []
      value.forEach(element => {
        var arrs = element.value.split('\n')
        var r = []
        arrs.forEach(element2 => {
          var arrss = element2.split(',')
          r.push({'lat': parseFloat(arrss[0]) , 'lng':parseFloat(arrss[1])})
        });
        result.push(r)
      });
      return result
    },
    setCenter(center){
      var arrs = center.split(',')
      var result = {'lat': parseFloat(arrs[0]) , 'lng':parseFloat(arrs[1])}
      return result
    },
    getTypeIconSrc(type) {
      switch (type) {
        case parseInt(process.env.VUE_APP_FLIGHT_SPOT_VALUE):
        default:
        return require('@/assets/img/y.svg')
        case parseInt(process.env.VUE_APP_NOT_FLIGHT_SPOT_VALUE):
        return require('@/assets/img/z.svg')
      }
    },
    changeToPath(value){
      if(value != null){
        var result = []
        value.forEach(element => {
          var arrs = element.value.split('\n')
          var r = []
          arrs.forEach(element2 => {
            var arrss = element2.split(',')
            r.push({'lat': parseFloat(arrss[0]) , 'lng':parseFloat(arrss[1])})
          });
          result.push(r)
        });
        return result
      } else {
        return []
      }
    },
    getLatLng(center) { 
      var latlngStr = center.split(",", 2)
      var array = {lat: parseFloat(latlngStr[0]),lng: parseFloat(latlngStr[1])}
      return array
    },
  },
}
</script>