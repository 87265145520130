<template>
<div>
  <div class="signup pa-6">
    <v-card 
      class="mx-auto pr-8 pl-8 pt-8 pb-14"
      width="550px"
      > 
      <v-row
        justify="center"
        >
        <v-img 
        class="ma-8"
        src="@/assets/img/betalogo.svg"
        max-width="250"
        ></v-img>
      </v-row>
      <h1 class="text-h4 font-weight-bold mt-1 mb-4">ようこそ</h1>
      <p class="text-h6">{{ displayName }} さん</p>
      <p class="mb-6">さぁ、ドローンを飛ばしに行く場所を見つけよう!</p>
      <router-link :to="{path: '/'}" class="no-under-line">
        <v-btn
          width="100%"
          max-width="400px"
          height="55px"
          color="#FFFF00"
          x-large
          rounded
          depressed
        >
        <span class="text-h6 font-weight-bold">スポット情報を調べてみる</span>
        </v-btn>
        </router-link>
      </v-card>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'

export default {
  props:[
  'displayName',
  ],
  data () {
    return {
    }
  },
  created() {
   firebase.analytics().logEvent('signup_complete', {});
  },
  mounted() {
    document.title = this.$route.meta.title
  }
}
</script>

