<template>
  <div class="container">
      <router-link :to="{ path: 'regist/' + lastId, params: { id:lastId }}" class="no-under-line">
      <v-btn
        color="primary"
        depressed
      >
        <span class="font-weight-bold">フライトスポット新規登録</span>
      </v-btn>
    </router-link>
    <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    <template>
      <v-data-table
        :headers="headers"
        :items="desserts"
        sort-by="id"
        sort-desc="true"
        :search="search"
        :loading="isTableLoad"
        class="elevation-1 mt-6"
      >
        <template v-slot:item.actions="{ item }"> 
          <router-link :to="{ path: 'edit2/' + item.id, params: { id:item.id }}" class="no-under-line">
            <v-btn
              small
              depressed
            >
            <v-icon small class="mr-1">mdi-pencil</v-icon>
            <span class="">編集</span>
            </v-btn>
          </router-link>
        </template>
        <template v-slot:item.name="{ item }">
          <router-link :to="{ path: '/flightspot/' + item.id + '/detail/', params: { id:item.id }}" class="no-under-line">
            {{ item.name }}
          </router-link>
        </template>

        <template v-slot:item.restroom="{ value }">
            {{ value ? '有':'無' }}
        </template>

        <template v-slot:item.parking="{ value }">
            {{ value ? '有':'無' }}
        </template>

        <template v-slot:item.did="{ value }">
            {{ value ? '該当':'非該当' }}
        </template>

        <template v-slot:item.status="{ value }">
          <v-chip
            :color="getColor(value)"
            label
            small
            text-color="white"
            class="font-weight-bold"
          >
            {{ status[value] }}
          </v-chip>
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
import firebase from 'firebase'

  export default {
    data () {
      return {
        search:'',
        headers: [
          { text: 'id', value: 'id' },
          { text: 'sutatus', value: 'status' },
          { text: 'フライトスポット名', value: 'name' },
          { text: '都道府県No', value: 'prefCode' },
          { text: '都道府県', value: 'prefecture' ,sortable:false },
          { text: '市町村', value: 'municipality' ,sortable:false },
          { text: 'DID', value: 'did' },
          { text: '最小予約日', value: 'reservedMinDay' },
          { text: 'トイレ', value: 'restroom' },
          { text: '駐車場', value: 'parking' },
          { text: '', value: 'actions', sortable:false }
        ],
        desserts: [],
        lastId: 0,
        status: ['非表示','公開中','未設定','調整中'],
        isTableLoad:true
      }
    },
    beforeCreate() {
      if(this.$store.getters.idToken.email != 'info@flyers.plus' && this.$store.getters.idToken.email != 'r-tatsumi@flyers.plus'){
        this.$router.push('/home')
      }
    },
    created() {
      var db = firebase.firestore()
      var self = this

      db.collection("flightSpot").get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            self.desserts.push(doc.data())
        });

        self.desserts.sort(function(a, b) {
            return b.id - a.id 
        })

        self.lastId = self.desserts[0].id + 1
        self.isTableLoad = false
      });
    },
    methods: {
      getColor (value) {
        if (value == 1) return 'teal lighten-2'
        else if (value == 0) return 'grey lighten-1'
        else return 'orange'
      },
    },
  }
</script>
