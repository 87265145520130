<template>
  <div>
    <div class="container">
      <v-row
        justify="center"
        class="mt-4 mb-10"
      >
      </v-row>
      <h1 class="text-h4 font-weight-bold mb-3 text-left">フライトスポットリクエスト</h1>
      <p class="mb-0 subtitle-1">Mapで範囲指定した場所に対しFlyersが<span class="font-weight-bold primary--text">「土地管理者・ドローン飛行の可否・申請方法」</span>3点の確認を行います。</p>
      <p class="mb-0 subtitle-2 error--text">※注意事項：小型無人機等飛行禁止法で指定される重要施設及びその周囲おおむね300mの周辺地域はリクエストすることはできません。</p>
      <router-link to="/faq" class="caption">Q：フライトスポットリクエスト ってどんな場所でも大丈夫ですか？</router-link>

      <p class="mt-4">リクエスト回数：あと{{ user.requestLeft == null ? 0 : user.requestLeft }}回</p>

      <div v-if="user.requestLeft >= 1">
        <div class="spotrequest pt-sm-12 pt-6">
          <v-form ref="form" class="up">
            <v-text-field
              label="メールアドレス"
              v-model="requestForm.mail"
              readonly
            ></v-text-field>
            <v-text-field
              label="フライトスポット名（任意）"
              placeholder="名称があれば入力してください"
              v-model="requestForm.spotName"
            ></v-text-field>
            <v-textarea
              label="緯度経度"
              v-model="requestForm.latlngs"
              :auto-grow="true"
              placeholder="Mapをクリックしおおよその範囲を指定してください（緯度経度が3点以上必要です）"
              readonly
              :rules="[rules.minMarkers]"
            ></v-textarea>
            <v-checkbox 
              hide-details
              label="注意事項を十分理解しました"
              v-model="isCaution"
              @change="changeCheck"
            >
            </v-checkbox>
            <v-checkbox 
              hide-details 
              label="利用規約に同意する"
              v-model="isTearms"
              @change="changeCheck"
            >
            </v-checkbox>
            <v-btn
              rounded
              width="100%"
              class="mt-10"
              color="primary font-weight-bold"
              large
              @click="submit"
              :disabled="isSubmit"
              :loading="requestForm.loading"
            >
              この場所をリクエストする
            </v-btn>
          </v-form>
          <div class="maps bottom">
            <gmap-map 
              :center="{lat: 35.707760258554494,lng: 140.83561410886264}"
              :zoom="15"
              style="width:100%; height:600px;"
              :options="{'mapTypeId':'hybrid','scrollwheel':'true','disableDefaultUI':'false','zoomControl':'true','fullscreenControl':'true','scaleControl':'true','gestureHandling':'greedy'}"
              @click="mapClick($event)"
              >
              <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                :ref="`marker${index}`"
                :icon="markerOptions"
                @click="makerClick(m)"
                @dragstart="makerDrag(m)"
                @dragend="makerDrag(m)"
                @drag="makerMove"
              />
              <gmap-polygon
                :options="{'strokeColor': '#00C3ff','fillColor': '#0089FF','strokeWeight': '2', 'fillOpacity': '0.2' }" 
                :path="paths" 
              >
              </gmap-polygon>
            </gmap-map>
            <v-btn 
              small
              @click="clear"
              color="red darken-2"
              class="clearBtn white--text"
              :disabled="isClear">
            <span class="font-weight-bold">範囲指定をクリア</span>
            </v-btn>
          </div>

        </div>

      </div>
    </div>

    <v-snackbar
      v-model="snackBar.show"
      :color="snackBar.color"
      bottom
      right
      :timeout="8000"
      class="font-weight-bold"
    >
    {{snackBar.message}}
    </v-snackbar>
  </div>
</template>

<script>
import firebase from 'firebase'
import { functions } from '../main.js'

export default {
  data() {
    return {
      user:[],
      docId:'',
      markers: [],
      paths: [],
      dragEndLat:'',
      dragEndLng:'',
      dragMakerTag:'',
      hoho:{ position: { lat: 35.7072873607, lng: 140.8362126177 } },
      markerOptions: {
        url: require('../assets/img/c.svg'),
        size: {width: 24, height: 24, f: 'px', b: 'px',},
        scaledSize: {width: 24, height: 24, f: 'px', b: 'px',},
      },
      isTearms: false,
      isCaution: false,
      isEnoughMarks: false,
      isSubmit: true,
      isClear: true,
      rules: {  
        minMarkers: function checkMakersAmount(v) {
          var amout = v.split('\n')
          if(amout.length-1 < process.env.VUE_APP_SPOTREQUEST_MIN_MAKERS){
            return '緯度経度は3点以上必要です'
          }
        }
      },
      snackBar: {
        show: false,
        color: '',
        message: ''
      },
      requestForm: {
        mail:this.$store.getters.idToken.email,
        spotName: '',
        latlngs: '',
        loading: false,
        requestNo: this.getReservationNumber()
      },
    };
  },
  mounted() {
    document.title = this.$route.meta.title
  },
  methods: {
    mapClick: function (event) {
      var lats = event.latLng.lat()
      var lngs = event.latLng.lng()

      var arr = { position: { lat: lats, lng: lngs }, tag: this.markers.length}
      var parr = { lat: lats, lng: lngs }

      this.markers.push(arr)
      this.paths.push(parr)
      this.setLatlngs(this.paths)

      this.clearBtnVisible(this.markers.length)

      this.isEnoughMarks = this.markers.length>=3 ? true : false
      this.changeCheck()

    },
    makerClick: function(marker) {

      var id = marker.tag
      var arr = []
      var arrs = []
      var n = 0

      this.markers.splice( id, 1 )
      this.paths.splice( id, 1 )

      this.markers.forEach(element => {
        arr.push({ position: { lat:element.position.lat, lng:element.position.lng }, tag: n++})
        arrs.push({ lat:element.position.lat, lng:element.position.lng })
      });
      this.markers = arr
      this.paths = arrs
      this.setLatlngs(this.paths)
      this.clearBtnVisible(this.markers.length)
      this.isEnoughMarks = this.markers.length>=3 ? true : false
      this.changeCheck()
    },
    makerDrag: function (marker) {
      this.dragMakerTag = marker.tag
      this.setLatlngs(this.paths)
    },
    makerMove: function(params) {
      this.paths.splice(this.dragMakerTag,1,{lat: params.latLng.lat(), lng: params.latLng.lng()}) 
      this.markers.splice(this.dragMakerTag,1,{ position: { lat: params.latLng.lat(), lng: params.latLng.lng() }, tag:this.dragMakerTag }) 

      this.setLatlngs(this.paths)
    },
    submit: function() {
      if(this.$refs.form.validate()) {

        this.sendMail()
      }
    },
    setLatlngs: function(paths) {
      var text = ''
      paths.forEach(element => {
        text = text + element.lat.toFixed(10) +  ',' + element.lng.toFixed(10) + '\n'
      });
      this.requestForm.latlngs = text
    },
    clear: function () {
      this.markers = []
      this.paths = []
      this.requestForm.latlngs = ''
      this.isClear = true
      this.isEnoughMarks = this.markers.length>=3 ? true : false
      this.changeCheck()
    },
    changeCheck: function () {
      this.isSubmit = this.isTearms && this.isCaution && this.isEnoughMarks ? false : true
    },
    clearBtnVisible: function (markersLength) {
      this.isClear = markersLength == 0 ? true : false
    },
    sendMail: function () {
      this.requestForm.loading = true
      const mailer = functions.httpsCallable('sendMail')

      if(this.requestForm.spotName == ''){
        this.requestForm.spotName = '未入力'
      }

      mailer(this.requestForm)
        .then(() => {
          this.formReset()
          this.showSnackBar(
            'success',
            'お問い合わせありがとうございます。送信完了しました'
          )

          this.user.updateAt = firebase.firestore.FieldValue.serverTimestamp()
          this.user.requestLeft = --this.user.requestLeft

          firebase.firestore().collection("users").doc(this.docId).update(this.user)
          .then(function() {
          })
          .catch(function(error) {
            console.error("Error writing document: ", error);
          })

        })
        .catch(err => {
          this.showSnackBar(
            'error',
            '送信に失敗しました。時間をおいて再度お試しください'
          )
          console.log(err)
        })
        .finally(() => {
          this.requestForm.loading = false
        })
      },
      showSnackBar: function (color, message) {
        this.snackBar.message = message
        this.snackBar.color = color
        this.snackBar.show = true
      },
      formReset: function () {
        // this.$refs.form.reset()
        this.requestForm.spotName = ''
        this.requestForm.latlngs = ''
        this.requestForm.loading = false
        this.requestForm.requestNo = this.getReservationNumber()
        this.isTearms = false
        this.isCaution = false
        this.markers = []
        this.paths = []
      },
      getReservationNumber() {
      var date = new Date();
      var yyyy = date.getFullYear();
      var mm = ("0"+date.getMonth()+1).slice(-2);
      var dd = ("0"+date.getDate()).slice(-2);
      var hh = ("0"+date.getHours()).slice(-2);
      var mi = ("0"+date.getMinutes()).slice(-2);
      return yyyy + mm + dd + hh + mi;
    },
  },
  created() {

    var self = this 

    firebase.firestore().collection("users")
    .where('userId','==',this.$store.getters.idToken.uid)
    .get().then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        self.user = doc.data()
        self.docId = doc.id
      })
    })
    firebase.analytics().logEvent('flight_spot_request', {})
  },
}
</script>

<style lang="scss">
.spotrequest {
  display: grid;
  grid-template-columns: 270px 1fr;
  .maps {
    position: relative;
    min-height: 600px;
    margin-left: 30px;
    .clearBtn {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
}

@media (max-width: 700px) {
  .spotrequest {
    grid-template-columns:repeat(auto-fit, minmax(300px, 1fr));
    .up {
      order: 2;
    }
    .bottom {
      order: 1;
    }
    .maps { 
      margin-left: initial;
      margin-bottom: 30px;
    }
  }
}
</style>