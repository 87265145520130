<template>
  <div>
    <div class="privacy-container text-justify">
      <h1 class="text-center mt-16 mb-10 text-h5 font-weight-bold text-sm-h4">プライバシーポリシー</h1>

      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第１条 個人情報の取扱いに関する基本方針</h2>
        <p>株式会社Flyers (以下、「当社」といいます。)は、個人情報の重要性を認識し、
        個人情報を保護することが社会的責務であると考え、個人情報に関する法令及び社内規程等を遵守し、当社で取扱う個人情報の取得、利用、管理を適正に行います。</p>
      </div>

      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第２条 取得する情報および取得方法</h2>
        <p>当社が運営するサービス、Flyers(以下「本サービス」)において、以下に定めるような情報(以下「個人情報」)を取得します。</p>
        <h3 class="text-h6 font-weight-bold">1. お客様の情報</h3>
        <ul class="mb-4 subtitle-2">
          <li>（１）お客様の氏名、事業所名、生年月日等プロフィールに関する情報</li>
          <li>（２）メールアドレス、電話番号、住所等連絡先情報</li>
          <li>（３）資格、所有するドローン機体などサービスの提供に必要な情報</li>
          <li>（４）クレジットカード情報等決済に関する情報</li>
          <li>（５）当社とお客様との間の通信及び連絡に関する履歴情報（音声、文章を含みます。）</li>
          <li>（６）アクセストークン等API</li>
          <li>（７）連携のために使用する情報</li>
          <li>（８）その他の当社が指定する情報（以下「利用者情報」といいます。）</li>
        </ul>

        <h3 class="text-h6 font-weight-bold">2. 端末情報等</h3>
        <p>当社は、お客様が端末又は携帯端末上で本サービスを利用する場合、お客様が使用する端末情報（端末の種類、端末で使用されている OS やブラウザの情報、端末を識別可能な ID情報等）を収集することがあります。また、当社は、本サービスの利用時に自動で生成、保存される IP アドレス及びお客様からのリクエスト日時、本サービス内での操作履歴の情報や、お客様のサービス利用状況に関する情報（以下「ログ情報」といます。）を収集することがあります。</p>
        
        <h3 class="text-h6 font-weight-bold">3. Cookie及び匿名ID</h3>
        <p>本サービスにおいて、「Cookie（クッキー）」と呼ばれる技術及びピクセル、ビーコンなどのこれに類する技術を使用する場合があります。</p>

        <h3 class="text-h6 font-weight-bold">4. エラーデータ</h3>
        <p>当社は、お客様が本サービスを使用する際に発生することのある技術的又は使用上のエラーに関するデータを収集します。</p>
      </div>

      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第３条 個人情報の利用目的</h2>
        <p>当社は、取得した個人情報を以下に定める目的のために使用します。本サービスの提供に関わる個人情報の利用目的は以下のとおりです。利用目的の範囲を超えて利用することはありません。</p>
        <ul class="mb-4 subtitle-2">
          <li>（１）本サービスの提供</li>
          <li>（２）本サービスの利用に関する各種案内</li>
          <li>（３）本サービスに関する当社の規約、ポリシー等（以下「規約等」といいます）に違反する行為等の調査及び当該調査に基づく登録内容の詳細確認</li>
          <li>（４）本サービスの改善に向けた調査</li>
          <li>（５）当社の新規サービス開発に向けた調査</li>
          <li>（６）本サービスの利用に関する各種問い合わせ対応</li>
          <li>（７）上記の利用目的に付随する利用目的のため</li>
        </ul>
      </div>


      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第４条 個人情報の第三者提供</h2>
        <p>当社は、原則として、お客様の同意を得ずに個人情報を第三者に提供することはありません。ただし、以下の場合は、関係法令に反しない範囲で、お客様の同意なく個人情報を提供することがあります。</p>
        <ul class="mb-4 subtitle-2">
          <li>（１）法令に基づく場合</li>
          <li>（２）人の生命、身体又は財産の保護のために必要がある場合であって、お客様の同意を得ることが困難であるとき</li>
          <li>（３）公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、お客様の同意を得ることが困難であるとき</li>
          <li>（４）国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
          <li>（５）合併、会社分割、事業譲渡その他の事由によりお客様の個人情報を含む事業の承継がなされる場合</li>
        </ul>
      </div>

      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第５条 個人情報の共同利用</h2>
        <p>当社は、利用目的の達成に必要な範囲内において、お客様から取得した個人情報の全部又は一部の取扱いを第三者に委託することがあります。この場合、当社は、当該委託先との間で本ポリシーに準じる内容の秘密保持契約等をあらかじめ締結するとともに、当該委託先において情報の適切な安全管理が図られるよう、必要かつ適切な監督を行います。</p>
      </div>

      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第６条 個人情報の開示、訂正、利用停止等</h2>
        <p>当社は、ご本人より当該本人の個人情報の利用目的の通知、開示、内容の訂正、追加または削除、利用の停止、消去および第三者提供の停止（以下「開示等」といいます）のお申し出に対して、遅滞なく応じます。下記の当社お問い合わせページよりお問い合わせください。ただし、個人情報保護法その他の法令により当社がこれらの義務を負わない場合、正当な理由なく同内容の請求が何度も繰り返される場合、又は過度な技術的作業を要する場合は、これらの手続を行うことができない場合があります。</p>
      </div>

      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第７条 お問合せ</h2>
        <p>当社の利用者情報等の取扱いに関するご意見、ご質問、苦情のお申出その他利用者情報等の取扱いに関するお問い合わせは、下記お問い合わせページからご連絡ください。</p>
        <p>株式会社Flyers<br><a href="https://forms.gle/qJ33Gojrg3fD5JyN6" target="_blank">お問合せフォーム</a></p>
      </div>

      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第８条 改定</h2>
        <p>当社は、継続的な改善に努めるものとし、必要に応じて、本文書を予告なく変更することがあります。
        この場合、変更後の本文書については、当社運営のウェブサイトにて掲示、その他わかりやすい方法により告知します。
        ただし、法令上お客様の同意が必要となるような内容の変更を行うときは、別途当社が定める方法により、お客様の同意を取得します。</p>
      </div>

      <p>以上</p>
      <p>2021年01月07日 制定</p>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.title = this.$route.meta.title
  },
}
</script>

<style lang="scss" scoped>

.privacy-container {
  width: 70%;
  margin: 0 auto;
}


ul {
  list-style: none;
  li {
    margin-bottom: 2px;
  }
}

@media (max-width: 768px) {

  .privacy-container {
    width: 80%;
  }

}


</style>