<template>
  <div class="pc">
    <section class="user-menu text-left">
      <section>          
        <h2 class="subtitle-2 font-weight-bold mb-3"><router-link to="/users/home" class="no-under-line">設定</router-link></h2>
        <div class="ml-1">
          <p class="subtitle-2 mb-2 ml-3"><router-link to="/users/account" class="no-under-line">アカウント</router-link></p>
          <p class="subtitle-2 mb-2 ml-3"><router-link to="/users/profile" class="no-under-line">プロフィール編集</router-link></p>
          <p class="subtitle-2 mb-2 ml-3 disabled">資格・所有機体情報</p>
        </div>
      </section>
      <v-divider class="mt-5 mb-5"></v-divider>
      <section>
        <h2 class="subtitle-2 font-weight-bold mb-3">その他</h2>
        <div class="ml-1">
          <p class="subtitle-2 mb-2 ml-3"><router-link to="/faq" class="no-under-line">よくある質問</router-link></p>
          <p class="subtitle-2 mb-2 ml-3"><a href="https://forms.gle/8ZddA4qq4K839ngh7" target="_blank" class="no-under-line">お問い合わせ</a></p>
          <p class="subtitle-2 mb-2 ml-3"><router-link to="" class="no-under-line" @click.native="logout">ログアウト</router-link></p>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import firebase from 'firebase'

export default {
  props:{
    userName: { type: String, default: ''},
  },
  methods: {
    logout() {
      this.$store.commit('updateIdToken', null);
      this.$store.commit('updateDisplayName', null);
      this.$store.commit('updateUserIconSrc', null);
      this.$store.commit('updateUser', null);
      this.$store.commit('updateFlightSpot', null);
      this.$store.commit('userFlightSpotSelectValue', null);
      this.$store.commit('searchValue', null);
      firebase.auth().signOut()
      firebase.analytics().logEvent('logout', {});
      this.$router.push('/')
    }
  },
}
</script>

<style lang="scss" scoped>
a {
  color: #2c3e50 !important;
}
.user-menu {
  .disabled {
    opacity: .25 !important;
    pointer-events: none;
  }
}
</style>