<template>
  <div>
    <div class="header_img">
      <img src="@/assets/img/about_img.jpg">
    </div>
    <div class="container">
      <section id="overview">
        <h2>会社概要</h2>
        <dl>
          <dt>会社名</dt>
          <dd>株式会社Flyers</dd>
          <dt>設立</dt>
          <dd>2020年08月06日</dd>
          <dt>代表者</dt>
          <dd>代表取締役 品川 広樹</dd>
          <dt>事業内容</dt>
          <dd>ドローンユーザー向けプラットフォーム『Flyers』の企画・開発・運営</dd>
          <dt>所在地</dt>
          <dd>〒060-8711<br>北海道札幌市中央区大通西３丁目６北海道新聞社本社ビル2階 SAPPORO Incubation Hub DRIVE</dd>
        </dl>
      </section>
      <section id="enkaku">
        <h2>沿革</h2>
        <dl>
          <dt>2020.06</dt>
          <dd>Open Network Lab HOKKAIDO 3rd Batchにて採択</dd>
          <dt>2020.08</dt>
          <dd>北海道札幌市中央区にて株式会社Flyersを設立</dd>
          <dt>2020.11</dt>
          <dd>株式会社D2Garage、株式会社インフィニットループより投資を受け増資</dd>
        </dl>
      </section>
      <section id="contact">
        <h2>お問い合わせ先</h2>
        <p>お問合わせは、フォームからご連絡をお願いいたします</p>
        <a href="https://forms.gle/qJ33Gojrg3fD5JyN6" target="_blank" class="mail">問い合わせをする</a>
      </section>
    </div>

    <div class="google-maps pc">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2603.810068864479!2d141.35055892389045!3d43.06151041217773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f0b299da7729073%3A0xb11d4fa2bc1c1a9f!2z44CSMDYwLTAwNDIg5YyX5rW36YGT5pyt5bmM5biC5Lit5aSu5Yy65aSn6YCa6KW_77yT5LiB55uu77yWIOmBk-aWsOODk-ODqw!5e0!3m2!1sja!2sjp!4v1605728764578!5m2!1sja!2sjp" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.title = this.$route.meta.title
  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
  font-family: YakuHanJP;
  font-feature-settings: "palt";
  letter-spacing: 0.15em;
  font-size: 85%; }

dl, dt, dd {
  margin: 0;
  padding: 0; }

#overview, #enkaku, #service, #contact, #member {
  margin-bottom: 160px;
  text-align: center; }

#member .flex {
  display: grid;
  width: 100%;
  margin: 0 auto;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); }
  #member .flex img {
    width: 90%;
    border-radius: 6px; }

#service img {
  width: 100%; }

h2 {
  font-size: 160%;
  margin-bottom: 36px; }

dl {
  color: #333333;
  width: 78%;
  text-align: initial;
  display: grid;
  grid-template-columns: 100px 1fr;
  margin: 0 auto; }
  dl dt {
    padding-top: 2px;
    margin-bottom: 18px; }
  dl dd {
    line-height: 1.4; }

#contact a {
  margin-top: 10px;
  display: inline-block;
  border: #dddddd 1px solid;
  padding: 20px 40px;
  font-size: 130%;
  border-radius: 100px;
  text-decoration: none;
  color: #333333;
  background-color: white;
  transition-duration: 0.4s; }
  #contact a:hover {
    color: white;
    background-color: #0089FF;
    border: white 1px solid;
    transition-duration: 0.2s; }

.header_img {
  margin-bottom: 120px; }
  .header_img img {
    width: 100%; }

.google-maps {
  position: relative;
  padding-bottom: 30%;
  height: 0;
  overflow: hidden; }

.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important; }

@media screen and (max-width: 768px) {
  footer img {
    margin-bottom: 20px; }

  #overview, #enkaku, #service, #contact {
    margin-bottom: 120px; }

  .header_img {
    margin-bottom: 60px; }

  dl {
    width: 100%; }
    dl dt, dl dd {
      line-height: 1.4;
      margin-bottom: 12px; }

  #contact .mail {
    font-size: 120%; } 
}
</style>