<template>
  <div>
    <v-row class="landingg mr-0 ml-0">
      <div class="aaaaaaaaa">
        <v-card width="350px" class="mx-auto mt-5 pa-7 zzz"> 
          <v-img class="ma-6" src="@/assets/img/betalogo.svg"></v-img>
          <h1 class="text-h6 font-weight-bold mt-4 mb-0">ログイン</h1>
        <v-card-text>
          <v-form>
            <v-text-field 
              prepend-icon="mdi-email" 
              label="メールアドレス"
              v-model="email"
              />
            <v-text-field 
              :type="showPassword ? 'text' : 'password'" 
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              label="パスワード"
              prepend-icon="mdi-lock" 
              @click:append="showPassword = !showPassword" 
              v-model="password"
              />
            <router-link to="/auth/reset" class="text-decoration-none"><p class="subtitle-2 mt-2 mb-0">パスワードを忘れた方はこちら</p></router-link>
            <v-card-actions>
              <v-btn 
                rounded
                color="primary"
                width="100%"
                depressed
                class="mt-6 mb-3"
                :loading="status"
                :disabled="status"
                @click="signIn"
              >
              <span class="font-weight-bold">ログイン</span>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
      </div>

      <div class="hoge"></div>
      <video poster="" preload="none" muted loop autoplay playsinline>
        <source src="@/assets/img/top.mp4">
      </video>
    </v-row>

    <div class="zzzz">
        <v-dialog
          v-model="dialog"
          persistent
          max-width="600px"
          z-index=20
          >
          <v-card>
            <v-card-title class="text-h6">
              お知らせ
            </v-card-title>
            <v-card-text class="subtitle-1 text-left">
              {{ message }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="closeDialog"
              >
                閉じる
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'App',
  data (){
    return {
      email: '',
      password: '',
      showPassword : false,
      message: '',
      dialog: false,
      status: false
    }
  },
  methods: {
    signIn: function () {
    this.status = true
    firebase.auth().signInWithEmailAndPassword(this.email, this.password)
      .then((response) => { // eslint-disable-line
        this.message = 'ログインしました'
        this.showDialog(this.message)
        this.$store.commit('updateIdToken', response.user);

        firebase.analytics().logEvent('login_complete', {
          method: 'Google'
        })
      })
      .catch((error) => { // eslint-disable-line
        var errorCode = error.code;
        switch(errorCode){
          case 'auth/user-disabled':
          case 'auth/wrong-password':
          case 'auth/user-not-found':
            this.message = 'メールアドレスまたはパスワードが違います'
          break
          case 'auth/invalid-email':
            this.message = '有効なメールアドレスを入力してください'
          break
          default:
            this.message = '何度もこのメッセージが表示される場合は、お手数ですが運営までお問い合わせください'
          break
        } 
        this.showDialog()
      });
    },
    showDialog: function () {
      this.dialog = true
      this.status = false
    },
    closeDialog: function () {
      this.dialog = false
      if(this.$store.getters.idToken != null){
        this.$router.push('/')
      }
    }
  },
  mounted() {
    document.title = this.$route.meta.title
  },
  created() {
    firebase.analytics().logEvent('login', {});
  },
  beforeCreate() {
      firebase.auth().onAuthStateChanged((user) => {
        
      if (user) {
        this.$store.commit('updateIdToken', user)
        this.$store.commit('updateUser', user)

        var self = this
        var db = firebase.firestore()
        var docs = []

        db.collection("users").where('userId','==', this.$store.getters.user.uid)
        .get()
        .then(querySnapshot => {
          if(querySnapshot.size >=1){
            querySnapshot.forEach(doc => {
              docs = doc.data()
            })
            if(docs.userIconSrc != "" || docs.userIconSrc != null){
              self.$store.commit('updateUserIconSrc', docs.userIconSrc)
            } 
          } 
        })
        .catch(function(error) {
          console.log("ERROR: ", error);
        })
      } 
    })
  }
}
</script>


<style lang="scss">
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 800px;
  background-image: url('/img/top_img.jpg');
  background-size: cover;
}

.zzzz {
  z-index: 10;
}
</style>