<template>
  <div>
    <div class="terms-container text-justify">
      <h1 class="text-center mt-16 mb-10 text-h5 font-weight-bold text-sm-h4">利用規約</h1>
      <p class="mb-16">この規約は、お客様が、株式会社Flyers（以下「弊社」）が提供する「Flyers」サービス（以下「本サービス」）をご利用頂く際の取扱いについて定めるもので、ご利用いただくにあたり本規約に同意いただく必要があります。</p>
      
      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第１条 目的</h2>
        <ul class="mb-4 decimal">
          <li>本規約は、弊社とお客様との間における権利義務を規定することを目的とします。</li>
          <li>本規約に同意いただけない場合、本サービスを利用する事は出来ません。</li>
          <li>本サービスのアカウント登録ページにおいて「Flyers利用規約に同意する」にチェックを入れアカウントを登録された場合、本規約に同意したものとみなされます。</li>
        </ul>
      </div>

      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第2条 定義</h2>
        <p>本規約内で使用する用語を以下のとおり定義します。</p>
        <ul class="mb-4 subtitle-2">
          <li>（１）「ドローン」とは、航空法に規定された無人航空機及び模型航空機のことをいいます。</li>
          <li>（２）「本コンテンツ」とは、弊社又はお客様が本サービスに掲載・発信した情報をいいます。</li>
          <li>（３）「投稿コンテンツ」とは、本サービスにある弊社コンテンツ以外の情報をいいます。</li>
        </ul>
      </div>

      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第3条 サービスの概要</h2>
        <p>本サービスはよりドローンを気軽に楽しめる世の中を作るべく、ドローンの飛行に関する情報の提供およびドローンの飛行申請を代行します。</p>
      </div>
        
      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第4条 規約への同意</h2>
        <ul class="mb-4 subtitle-2">
          <li>１.お客様は、本規約の定めに従って本サービスを利用しなければなりません。</li>
          <li>２.お客様が未成年者である場合は、親権者等の法定代理人の同意を得たうえで本サービスを利用してください。また、お客様が本サービスを事業者のために利用する場合は、当該事業者も本規約に同意したうえで本サービスを利用してください。</li>
          <li>３.お客様は、本サービスを実際に利⽤し、⼜は、本サービスに会員登録をすることによって、本規約に有効かつ取消不能な同意をしたものとみなされます。</li>
          <li>４.本サービスに個別利⽤規約がある場合、お客様は、本規約のほか、個別利⽤規約の定めにも従って本サービスを利⽤しなければなりません。</li>
        </ul>
      </div>

      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第5条 規約の変更</h2>
        <p>当社は、本規約を変更する場合、お客様に対し事前に当社の定める方法にて通知します。お客様は、本規約の変更後に本サービスを利用した場合、変更後の本規約の全ての記載内容に同意したものとみなします。 OS やブラウザの情報、端末を識別可能な ID情報等）を収集することがあります。また、当社は、本サービスの利用時に自動で生成、保存される IP アドレス及びお客様からのリクエスト日時、本サービス内での操作履歴の情報や、お客様のサービス利用状況に関する情報（以下「ログ情報」といます。）を収集することがあります。</p>
      </div>
        
      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第6条 アカウント</h2>
        <ul class="mb-4 subtitle-2">
          <li>１.本サービスの利用に際し、アカウントの作成が必要となる場合があります。この場合、お客様は、真実、正確かつ完全な情報を登録しなければならず、常に最新の情報となるよう修正しなければなりません。</li>
          <li>２.お客様は、アカウント登録をされる際に次の情報を当社に提供してください。なお、弊社から以下の情報を示す資料の提出を求められた場合、速やかに当該資料を提出してください。以下の情報及び弊社が必要と判断した資料のご提出を頂けない場合、アカウント登録をすることが出来ず、また本サービスを利用する事が出来ません。</li>
          <li>
            <ul>
              <li>（1）メールアドレス</li>
              <li>（2）その他弊社が定める事項</li>
            </ul>
          </li>
          <li>３.お客様は、本サービスの利⽤に際してパスワードを登録する場合、これを不正に利⽤されないようご⾃⾝の責任で厳重に管理しなければなりません。弊社は登録されたパスワードを利⽤して⾏われた⼀切の⾏為をお客様ご本⼈の⾏為とみなすことができます。</li>
          <li>４.本サービスにアカウント登録したお客様は、いつでもアカウントを削除して退会することができます。</li>
          <li>５.弊社は、お客様が本規約に違反し⼜は違反するおそれがあると認めた場合、あらかじめお客様に通知することなく、アカウントを停⽌⼜は削除することができます。なお、弊社は、アカウントを停⽌⼜は削除されたお客様に対して、返⾦等はいたしません。</li>
        </ul>
      </div>

      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第7条 ドローンユーザーの権利義務</h2>
        <p>お客様は、本サービスを通じてドローンの飛行申請をする場合、以下の情報を当社に提供していただく場合があります。なお、当社から以下の情報の根拠資料の提出を求められた場合、速やかに当該資料を提出してください。また、提供した情報に変更点があった場合は、ただちに正確な情報に更新してください。当社の指⽰に従わない場合には、本サービスを利⽤することができません。</p>
        <ul class="mb-4 subtitle-2">
          <li>（１）電話番号</li>
          <li>（２）ドローン飛行経験時間</li>
          <li>（３）ドローン民間ライセンスの種類</li>
          <li>（４）無人航空機飛行許可の内容</li>
          <li>（５）ドローンの機体情報</li>
          <li>（６）損害賠償保険の内容</li>
          <li>（７）支払いに関する情報</li>
          <li>（８）(複数人で操縦を行う場合の)他の操縦者の氏名、電話番号</li>
          <li>（９）その他弊社が定めた情報</li>
        </ul>
      </div>

      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第8条 プライバシー保護</h2>
        <p>弊社は、本規約のほか、プライバシーポリシーに従って個人情報等を取り扱います。ユーザーは、本サービスの利用又は本サービスの閲覧の前に、本サービス上で、プライバシーポリシーを必ず確認し、その内容に同意した上で、本サービスを利用するものとします。</p>
      </div>

      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第9条 サービス提供条件及び表現</h2>
        <ul class="mb-4 subtitle-2">
          <li>１.お客様は本サービスを利用するにあたり、必要な情報通信端末、ドローンオペレーションシステム、通信手段及び電力などをお客様の費用と責任で用意しなければなりません。なお、お客様が未成年者である場合には、親権者など法的代理人がお客様に使用を認めたものをご使用下さい。</li>
          <li>２.当社は、本サービスの全部または一部を、年齢、ご本人確認の有無、登録情報の有無、当社が必要と判断する条件を満たしたお客様に限定して提供することができるものとします。</li>
          <li>３.当社は、当社が必要と判断する場合、予めお客様に通知することなく、いつでも、本サービスの全部または一部の内容を変更し、またその提供を中止することが出来るものとします。</li>
        </ul>
      </div>

      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第10条 コンテンツ利用条件</h2>
        <ul class="mb-4 subtitle-2">
          <li>１.当社は、当社が提供する本コンテンツにおいて、お客様に対し、譲渡及び再許諾できない、非独占的な、本サービスの利用権を付与します。</li>
          <li>２.お客様は、利用料、利用期間等の利用条件が別途定められた本コンテンツを利用する場合、かかる利用条件に従うものとします。本サービスの画面上で「購入」、「販売」などの表示がされている場合であっても、当社がお客様に対し提供する本コンテンツに関する知的財産権その他の権利はお客様に移転せず、お客様には上記の利用権のみが付与されます。</li>
          <li>３.お客様は、本コンテンツを、本サービスが予定している利用態様を超えて利用(複製、送信、転載、改変等の行為を含む)してはなりません。</li>
          <li>４.投稿コンテンツのバックアップはお客様自身で行って下さい。当社は投稿コンテンツのバックアップを行う義務を負いません。</li>
          <li>５.お客様は投稿コンテンツに対して有する権利を従前通り保持し、当社がかかる権利を取得する事はありません。ただし、投稿コンテンツのうち、一般に見える形で公開されたものに限り、お客様は、当社に対し、これをサービスやプロモーションに利用する権利(改変する権利も含む)を無償で、無制限に、地域の限定なく許諾したことになります。</li>
          <li>６.当社はお客様の投稿コンテンツに関し、法令、本規約、または個別利用規約に違反、もしくは違反する恐れがあると判断した場合、予めお客様に通知することなく投稿コンテンツを削除するなどの方法により、本サービス上での投稿コンテンツの利用を制限できます。</li>
        </ul>
      </div>

      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第11条 禁止事項</h2>
        <p>お客様は、本サービスの利用に際して以下に記載する行為を行ってはなりません。</p>
        <ul class="mb-4 subtitle-2">
          <li>（１）法令、裁判所の判決、決定もしくは命令、または法的拘束力のある行政措置に違反する行為。</li>
          <li>（２）公の秩序または善良の風俗を害する恐れのある行為。</li>
          <li>（３）反社会的勢力に対する利益供与、その他協力行為。</li>
          <li>（４）当社または第三者の著作権、商標権、特許権等の知的財産、名誉権、プライバシー権、その他法令上または契約上の権利を侵害する行為。</li>
          <li>（５）当社が定める方法以外の方法で本コンテンツの利用権利またはデータを貸与、交換、譲渡、名義変更、売買、質入れ、担保供与その他の方法により第三者に利用させる、または処分する行為。</li>
          <li>（６）以下の行為を目的とした、本サービスが予定している利用目的とは異なる目的で本サービスを利用すること。</li>
            <ul>
              <li>① 弊社が認めたもの以外の営利活動（営業、宣伝、広告、勧誘、その他）</li>
              <li>② 宗教活動または宗教団体への勧誘を目的とした行為</li>
              <li>③ 性行為やわいせつなを目的とした行為</li>
              <li>④ 児童ポルノや児童虐待に相当する情報を表示し、表示させる行為</li>
              <li>⑤ 第三者に対する嫌がらせや誹謗中傷を目的とする行為</li>
              <li>⑥ その他弊社が適切でないと判断した行為</li>
            </ul>
          <li>（７）以下のコンテンツを投稿、⼜は送信する⾏為</li>
            <ul>
              <li>① お客様ならびに第三者に損害を与えるコンテンツ</li>
              <li>② お客様ならびに第三者の名誉を毀損するコンテンツ</li>
              <li>③ お客様ならびに第三者を侮辱するコンテンツ</li>
              <li>④ お客様ならびに第三者のプライバシーを含む権利を侵害するコンテンツ</li>
              <li>⑤ ⼈種、国籍、信条、性別、社会的⾝分、⾨地等による差別的なコンテンツ</li>
              <li>⑥ 誹謗中傷や罵詈雑言など攻撃的なコンテンツ</li>
              <li>⑦ 暴⼒的な表現が含まれるコンテンツ</li>
              <li>⑧ 倫理的に問題のあるコンテンツ</li>
              <li>⑩ 性的表現を含んだコンテンツ</li>
              <li>⑪ 反社会的な内容を含むコンテン</li>
              <li>⑫ その他弊社が適切でないと判断するコンテンツ</li>
            </ul>
          <li>（８）当社⼜は第三者になりすます⾏為⼜は意図的に虚偽の情報を流布させる⾏為</li>
          <li>（９）第三者の個⼈情報、登録情報、利⽤履歴情報などを、不正に収集、開⽰⼜は提供する⾏為</li>
          <li>（１０）不正アクセス⾏為、第三者のアカウントの利⽤⾏為、その他これらに類する⾏為</li>
          <li>（１１）本サービスのサーバやネットワークシステムに⽀障を与える⾏為、本サービスを不正に操作する⾏為、本サービスの不具合を意図的に利⽤する⾏為、類似⼜は同様の問い合わせを必要以上に繰り返す等、当社に対し不当な要求をする⾏為、その他当社による本サービスの運営⼜は第三者による本サービスの利⽤を妨害し、これらに⽀障を与える⾏為</li>
          <li>（１２）本利⽤規約に違反する⾏為</li>
          <li>（１３）前各号のいずれかに該当する⾏為を援助⼜は助⻑する⾏為</li>
          <li>（１４）その他、当社が不適当と判断した⾏為</li>
        </ul>
      </div>


      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第12条 お客様の責任</h2>
        <ul class="mb-4 subtitle-2">
          <li>１.お客様は、お客様ご⾃⾝の責任において本サービスを利⽤するものとし、本サービスにおいて⾏ったあらゆる⾏為及びその結果について⼀切の責任を負うものとします。</li>
          <li>２.当社は、お客様が本規約に違反して本サービスを利⽤していると認めた場合、当社が必要と認める措置を講じます。ただし、当社はかかる違反を防⽌⼜は是正する義務を負いません。</li>
        </ul>
      </div>

      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第13条 免責</h2>
        <ul class="mb-4 subtitle-2">
          <li>１.当社は、本サービス（弊社コンテンツおよび投稿コンテンツを含みます。）に事実上⼜は法律上の契約不適合（安全性、信頼性、正確性、完全性、有効性、特定の⽬的への適合性、セキュティなどに関する⽋陥、エラーやバグ、及び権利侵害を含みます。）がないことを明⽰的にも黙⽰的にも保証しておりません。当社は、お客様に対して、かかる契約不適合を除去して本サービスを提供する義務を負いません。</li>
          <li>２.お客様が本サービスの利用により第三者に対して損害を与えたり、第三者との間で紛争等が生じた場合、お客様自らの責任と費用で解決し、弊社は一切の責任を負わないものとします。</li>
          <li>３.当社は、本サービスに起因してお客様に⽣じたあらゆる損害（ドローンの故障、ドローンの墜落等による建物その他⼯作物、⼟地、動産その他⼀切の物の損壊、滅失、及び空撮によるプライバシー、名誉の侵害等を含み、これに限りません。）について⼀切の責任を負いません。ただし、本サービスに関する当社とお客様との間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適⽤されません。</li>
          <li>４.前項の但書に定める場合であっても、弊社は、弊社の過失（重過失を除きます。）による債務不履⾏⼜は不法⾏為によりお客様に⽣じた損害のうち特別な事情から⽣じた損害（弊社⼜はお客様が損害発⽣につき予⾒し、⼜は予⾒しえた場合を含みます。）について⼀切の責任を負いません。</li>
          <li>５.弊社の過失（重過失を除きます。）による債務不履⾏⼜は不法⾏為によりお客様に⽣じた損害の賠償を⾏う場合、お客様から当該損害が発⽣した⽉に受領した利⽤料の額を上限とします。</li>
        </ul>
      </div>

      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第14条 反社会勢力の排除</h2>
        <ul class="mb-4 subtitle-2">
          <li>１.お客様は、次の事項を確約するものとします。</li>
          <li>
            <ul>
              <li>（１） 暴⼒団、暴⼒団員、暴⼒団員でなくなったときから5年を経過しない者、暴⼒団準構成員、暴⼒団関係企業、総会屋、社会運動等標ぼうゴロ、特殊知能暴⼒集団その他これらに準ずるもの(以下、総称して｢反社会的勢⼒｣という。)でないこと</li>
              <li>（２） 反社会的勢⼒と次の関係を有していないこと</li>
              <li>
                <ul>
                  <li>①⾃ら若しくは第三者の不正の利益を図る⽬的、⼜は第三者に損害を与える⽬的をもって反社会的勢⼒を利⽤していると認められる関係(以下、総称して｢反社会的勢⼒｣という。)でないこと</li>
                  <li>② 反社会的勢⼒に対して資⾦等を提供し、⼜は便宜を供与するなど反社会的勢⼒の維持、運営に協⼒し、⼜は関与している関係</li>
                </ul>
              </li>
              <li>（３） 反社会的勢⼒に⾃⼰の名義を利⽤させ、本サービスを利⽤するものではないこと</li>
            </ul>
          </li>
          <li>２.お客様は、⾃ら⼜は第三者を利⽤して次の事項の⼀つにでも該当する⾏為を⾏わないことを表明し、保証するものとします。</li>
          <li>
            <ul>
              <li>（１）暴⼒的な要求⾏為(以下、総称して｢反社会的勢⼒｣という。)でないこと</li>
              <li>（２）法的な責任を超えた不当な要求⾏為</li>
              <li>（３）取引に関して、脅迫的な⾔動をし、⼜は暴⼒を⽤いる⾏為</li>
              <li>（４）⾵説を流布し、偽計⼜は威⼒を⽤いて相⼿⽅の業務を妨害し、⼜は信⽤を毀損する⾏為</li>
              <li>（５）その他前各号に準ずる⾏為</li>
            </ul>
          </li>
          <li>３.当社は、お客様が前⼆項の規定に反することが判明した場合、本サービスの利⽤の全部⼜は⼀部を停⽌し、⼜はお客様のアカウントを削除し、若しくはお客様の本サービスの利⽤を禁⽌することができるものとします。</li>
          <li>４.お客様が第1項⼜は第2項に違反した場合、お客様は、当社の被った全ての損害を賠償しなければなりません。</li>
          <li>５.お客場は第3項による措置について⼀切異議を述べることはできず、また、当該措置により⽣じる損害について、当社に対し⼀切の請求を⾏うことはできません。</li>
        </ul>
      </div>

      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第15条 連絡方法</h2>
        <ul class="mb-4 subtitle-2">
          <li>１.本サービスに関する弊社からお客様へのご連絡は、本サービスまたは弊社が運営するWEBサイト内への掲示、その他弊社が取り決めた方法により行います。</li>
          <li>２.本サービスに関するお客様から弊社への連絡は、以下のメールアドレスにお問い合わせ内容を記載の上送信してください。</li>
          <li>お問い合わせ<br>info@flyers.plus</li>
        </ul>
      </div>

      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第16条 言語、準拠法</h2>
        <p>本規約及び個別利⽤規約の準拠法は⽇本法とします。また、本規約及び個別利⽤規約は⽇本語によって作成されたものを正本とし、英語で作成された本規約⼜は個別利⽤規約との間に⽭盾、⼜は抵触が⽣じた場合には、⽇本語で作成されたものが優先されます。</p>
      </div>

      <div class="mb-6">
        <h2 class="text-h6 font-weight-bold mb-2">第17条 裁判管轄</h2>
        <p>本サービスに起因⼜は関連してお客様と当社との間に⽣じた紛争については、札幌地⽅裁判所を第⼀審の専属的合意管轄裁判所とします。</p>
      </div>


      <p>以上</p>
      <p class="subtitle-2 mb-0">2021年01月07日 発効</p>
      <p class="subtitle-2">2021年04月13日 改定</p>
      
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.title = this.$route.meta.title
  },
}
</script>

<style lang="scss" scoped>

.terms-container {
  width: 70%;
  margin: 0 auto;
}

.decimal {
  list-style-type: decimal;
}


ul {
  list-style: none;
  li {
    margin-bottom: 2px;
  }
}

@media (max-width: 768px) {

  .terms-container {
    width: 80%;
  }

}
</style>