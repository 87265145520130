<template>
  <div>
    <div class="pc">
      <div class="user-detail-container">
        <div class="user-description">
          <div>
            <v-avatar
            size="160">
              <v-img
                :src="user.userIconSrc"
              />
            </v-avatar>
              <v-btn
              small
              rounded
              text
              color="primary"
              outlined
              class="mt-5 "
              v-if="user.userId == uid"
              block
              :to="{name:'UsersProfile'}"
              >
                プロフィールを編集する
              </v-btn>
          </div>
          
          <div>
            <div class="mt-4 name">
              <div class="cococo"> 
                <h2 class="text-h4 font-weight-bold mb-4">{{user.displayName}}</h2>
              </div>
              <p class="caption mb-1 blue-grey--text text--lighten-2">FlyersID / {{user.userId}}</p>
            </div>
            <p v-html="autoLink(user.selfIntroduction)" class="intro text-pre-wrap"></p>
            <div>
              <div class="d-flex" v-if="user.activityBase != ''">
                <div>
                  <v-img
                    :src="require('@/assets/img/icon_activity_base.svg')"
                  />
                </div>
                <p class="subtitle-2 ml-2">おもな活動場所：{{ user.activityBase }}</p>
              </div>
              <div class="d-flex">
                <v-btn
                  color="blue-gray"
                  class="mr-1"
                  v-if="user.twitter != ''"
                  :href="'https://twitter.com/'+user.twitter"
                  target="_blank"
                  fab 
                  small
                  depressed
                >
                  <v-icon small>fab fa-twitter</v-icon>
                </v-btn>
                <v-btn
                  color="blue-gray"
                  class="mr-1"
                  v-if="user.youtube != ''"
                  :href="user.youtube"
                  target="_blank"
                  fab 
                  small
                  depressed
                >
                  <v-icon small>fab fa-youtube</v-icon>
                </v-btn>
                <v-btn
                  color="blue-gray"
                  class="mr-1"
                  v-if="user.instagram != null && user.instagram != ''"
                  :href="'https://instagram.com/'+user.instagram"
                  target="_blank"
                  fab 
                  small
                  depressed
                >
                  <v-icon >fab fa-instagram</v-icon>
                </v-btn>
                <v-btn
                  color="blue-gray"
                  v-if="user.web != ''"
                  :href="user.web"
                  target="_blank"
                  fab 
                  small
                  depressed
                >
                  <v-icon>mdi-earth</v-icon>
                </v-btn>
              </div>
            </div>

          </div>
        </div>
        <div class="text-left mt-8">
          <v-tabs
          v-model="tabs"
          height="40px"
          class="ml-3 mr-3"
          >
            <v-tab class="font-weight-bold">投稿写真</v-tab>
          </v-tabs>     
        </div>
      </div>
    </div>

    <!-- SP版 -->
    <div class="sp text-left user-detail-sp-container">  
      <div class="user-sp-description">
        <div class="mb-4">
          <v-avatar
          size="160">
            <v-img
              :src="user.userIconSrc"
            />
          </v-avatar>
          <div>
            <v-btn
              small
              rounded
              text
              color="primary"
              outlined
              class="mt-3"
              v-if="user.userId == uid"
              :to="{name:'UsersProfile'}"
              >
                プロフィールを編集する
              </v-btn>
          </div>
        </div>
        <h2 class="text-h6 font-weight-bold mb-2">{{user.displayName}}</h2>
        <p v-html="autoLink(user.selfIntroduction)" class="intro text-pre-wrap"></p>
        <div class="d-flex" v-if="user.activityBase != ''">
          <div>
            <v-img
              :src="require('@/assets/img/icon_activity_base.svg')"
            />
          </div>
          <p class="subtitle-2 ml-2">主な活動場所：{{ user.activityBase }}</p>
        </div>

        <div class="d-flex mb-6">
          <v-btn
            color="blue-gray"
            class="mr-1"
            v-if="user.twitter != ''"
            :href="'https://twitter.com/'+user.twitter"
            target="_blank"
            fab 
            small
            depressed
          >
            <v-icon small>fab fa-twitter</v-icon>
          </v-btn>
          <v-btn
            color="blue-gray"
            class="mr-1"
            v-if="user.youtube != ''"
            :href="user.youtube"
            target="_blank"
            fab 
            small
            depressed
          >
            <v-icon small>fab fa-youtube</v-icon>
          </v-btn>
          <v-btn
            color="blue-gray"
            class="mr-1"
            v-if="user.instagram != null && user.instagram != ''"
            :href="'https://instagram.com/'+user.instagram"
            target="_blank"
            fab 
            small
            depressed
          >
            <v-icon >fab fa-instagram</v-icon>
          </v-btn>
          <v-btn
            color="blue-gray"
            v-if="user.web != ''"
            :to="user.web"
            fab 
            small
            depressed
          >
            <v-icon>mdi-earth</v-icon>
          </v-btn>
        </div>

        <div class="text-left mt-8">
          <v-tabs
          v-model="tabs"
          height="40px"
          class="ml-0 mr-3"
          >
            <v-tab class="font-weight-bold">投稿写真</v-tab>
          </v-tabs>     
        </div>
      </div>
    </div>

    <v-tabs-items v-model="tabs" class="text-left" id="user-tabs">
        <v-tab-item>
          <div v-if="userPhotos.length != 0">
            <p class="subtitle-2 mt-5 ml-5 mb-0">写真総数:{{userPhotos.length}}枚</p>
            <div class="photo">
              <div v-for="(element,index) in userPhotos" :key="index">
                    
                <router-link to="#" @click.native="showPhoto(element)">
                  <div class="card">
                    <v-img
                      :aspect-ratio="16/9"
                      :src="element.src"
                      :lazy-src="require('../../assets/img/lazy_img.svg')"
                      class="rounded-sm img-hover"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="blue-grey lighten-2"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div v-else class="text-center">
            <div v-if="user.userId != uid">
              <p class="mt-16 mb-1">まだ投稿された写真がありません。</p>
              <p class="caption mb-16">{{ user.displayName }}さんが写真を投稿すると、ここに表示されます。</p>
            </div>
            <div v-else>
              <p class="mt-16 mb-4">まだ投稿された写真がありません。</p>
              <p class="caption mb-2 font-weight-bold">最近追加されたスポットの写真があれば、ぜひ投稿してみてください</p>
              <v-btn
                text
                color="primary"
                outlined
                minWidth="300px"
                class="mb-16"
                @click="newSpot()"
              >
                <span class="font-weight-bold">新着のスポットをみる</span>
              </v-btn>
            </div>
          </div>

        </v-tab-item>
        <v-tab-item v-if="false">
          <v-card flat>
            <v-card-title class="headline">
              An awesome title
            </v-card-title>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <div class="zzzz">
        <v-dialog
            width="80%"
            max-width="1100px"
            v-model="photoDialog.status"
            overlay-opacity="0.94"
            class=""
          >
          <v-card>
            <div class="photo-d-back sp">
              <v-img
                :src="photoDialog.src"
              >
              </v-img>
            </div>
            <div class="photo-d-back pa-4 pc">
              <v-img
                contain
                :minHeight="photoDialogMinHeight"
                :height="photoDialogHeight"
                :src="photoDialog.src"
              >
              </v-img>
            </div>
            <div class="pt-sm-4 pr-sm-8 pl-sm-8 pb-sm-8 pt-1 pr-4 pl-4 pb-4">
              <div class="photo-dialog">
                <div class="footer-information">
                  <div class="d-flex align-content-center mt-3">
                    <v-avatar
                    size="30">
                      <v-img
                        :src="user.userIconSrc"
                      ></v-img>
                    </v-avatar>
                    <p class="caption mb-0 pt-1 ml-2 omit">{{ user.displayName }}</p>
                  </div>
                  <div class="d-flex">
                    <v-icon x-small class="mr-4 pt-2 blue-grey--text text--lighten-3" v-if="user.userId == uid">fas fa-trash</v-icon>
                    <v-icon x-small class="mr-0 pt-2 blue-grey--text text--lighten-3" @click="openRepotDialog(photoDialog.array,'みんなの空撮写真')">fas fa-flag</v-icon>
                  </div>
                </div>
                <div class="text-left">
                  <p class="subtitle-1 font-weight-bold pt-4 mb-2 text-justify photo-title" v-if="photoDialog.title != ''">{{ photoDialog.title }}</p>
                  <p class="subtitle-2 pt-0 text-justify" v-if="photoDialog.description != ''">{{ photoDialog.description }}</p>
                  <v-divider class="mt-4 mb-4"></v-divider>
                  <div class="">
                    <div>
                      <p class="subtitle-2 mb-0 pt-0 font-weight-bold text-justify"><router-link :to="'/userspot/'+ spot.id +'/detail'" class="no-under-line default-color">{{ spot.name }}</router-link></p>
                      <p class="subtitle-2 mb-0 text-justify">{{ spot.prefecture }} / {{ spot.municipality}}</p>
                    </div>
                    <div>
                      <v-btn
                        text
                        color="primary"
                        :to="'/userspot/'+ spot.id +'/detail'"
                        outlined
                        small
                        class="mt-2"
                        block
                      >
                        <span class="">スポットをみる</span>
                      </v-btn>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </div>

      <div class="reportDialog">
      <v-dialog
          width="90%"
          max-width="500px"
          v-model="reportDialog.status"
        >
        <v-card>
          <v-card-title class="text-h6">
            問題の報告
          </v-card-title>
          <v-card-text class="subtitle-2 text-left" v-html="'該当する内容を選択してください'"></v-card-text>

          <v-radio-group
            class="mt-0"
            v-model="reportDialog.reportValue"
          >
            <v-radio v-for="(element,index) in report" :key="index"
              :label="element"
              :value="element"
              class="ml-10 mb-4"
            ></v-radio>
          </v-radio-group>

          <v-card-text class="caption text-left" v-html="'不適切として報告された情報や写真およびユーザーは、Flyersが確認しコミュニティガイドラインに違反していないかどうかを判断します。深刻な違反や、違反が繰り返された場合は、対象のアカウントが停止される場合があります。'"></v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="gray darken-1"
              text
              @click="reportDialog.status = false"
            >
              キャンセル
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              :disabled="reportDialog.reportValue == ''"
              @click="sendReport(reportDialog.type)"
            >
              <span class="font-weight-bold">通報する</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="reportDialog">
      <v-dialog
          width="80%"
          max-width="750px"
          v-model="reportSuccessDialog.status"
        >
        <v-card>
          <v-card-title class="text-h6">
            問題報告の完了
          </v-card-title>
          <v-card-text class="subtitle-1 text-left" v-html="'問題報告いただき、ありがとうございます。<br> Flyersにて対象コンテンツの検証を行います。'"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="gray darken-1"
              text
              @click="reportSuccessDialog.status = false"
            >
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import axios from 'axios'

export default {
  data() {
    return {
      tabs: null,
      uid: this.getUid(),
      spot: [],
      user: {
        activityBase:'',
        displayName:'',
        selfIntroduction:'',
        twitter:'',
        userIconSrc:'',
        userId:'',
        web:'',
        youtube:'',
      },
      userPhotos: [],
      photoDialog: {
        status: false,
        src: '',
        id: 0,
        title: '',
        description: '',
        array: []
      },
      reportDialog: {
        type: '',
        status: false,
        array: [],
        reportValue: ''
      },      
      report: ['スパム・迷惑行為','権利の侵害','性的な内容が含れる','危険な行為','法律に反する行為'],
      reportSuccessDialog: {  
        status: false,
      },
    }
  },
  created() {
    var self = this
    firebase.firestore().collection('spotPhotos')
    .where('uid','==',this.$route.params.id)
    .orderBy('createdAt', 'desc')
    .get().then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        self.userPhotos.push(doc.data())
      })
    })

    firebase.firestore().collection('users')
    .where('userId','==',this.$route.params.id)
    .limit(1)
    .get().then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        self.user = doc.data()
      })
    })
  },
  methods: {
    showPhoto(element){
      this.photoDialog.status = true
      this.photoDialog.src = element.src
      this.photoDialog.title = element.title
      this.photoDialog.description = element.description
      this.photoDialog.array = element

      var self = this
      firebase.firestore().collection('userFlightSpots')
      .where('id','==',this.photoDialog.array.spotid)
      .limit(1)
      .get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          self.spot = doc.data()
        })
      })
    },
    autoLink(text){
      return text.replace(/(https?:\/\/[^\s]*)/g, "<a href='$1' target='_blank'>$1</a>")
    },
    getUid(){
      return this.$store.getters.user != null ? this.$store.getters.user.uid : ''
    },
    openRepotDialog(array,type) {
      this.reportDialog.type = type
      this.reportDialog.status = true
      this.reportDialog.array = array
    },
    sendReport(type) {

      var payload = []
      var self = this

      switch (type) {
        case 'みんなの空撮写真':
          payload = {
              "blocks": [
              {
                "type":"section",
                "text": {
                  "type":"mrkdwn",
                  "text": this.uid + ' さんから通報がありました\n投稿タイプ：'+ type +'\n通報の内容：' + this.reportDialog.reportValue + '\n投稿者のID：'+  this.reportDialog.array.uid
                  }
              },
              {
                "type":"section",
                "text": {
                  "type":"mrkdwn",
                  "text": 'ユーザー詳細の投稿写真：\nパス：' + this.$route.fullPath
                  }
              },
              {
                "type": "image",
                "image_url": this.reportDialog.array.src,
                "alt_text": 'image1'
              }
              ]
            }
          break;

        default:
          break;
      }

      var url = 'https://hooks.slack.com/services/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'

      var params = new URLSearchParams();
      params.append('payload', JSON.stringify(payload));

      axios.post(url, params)
        .then(function (response) { // eslint-disable-line
          self.reportDialog.status = false
          self.reportDialog.reportValue = ''
          self.reportSuccessDialog.status = true
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    newSpot(){
      this.$store.commit('searchValue',{data:{areaName:'',id:12 ,childs:[{prefName:'',prefCode:0}]},isArea:true,isNew:true,isNewCertify:false})
      this.$router.push({name: 'List'})
    },
  },
    computed: {
    photoDialogHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        return '10vh'
        default:
        return '60vh'
      }
    },
    photoDialogMinHeight () {
        switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        return '300'
        default:
        return '500'
      }
    }
  },
}
</script>

<style lang="scss" scoped>

.user-detail-container {
  background: white;
  .user-description {
    display: grid;
    max-width: 1000px;
    grid-template-columns: 170px 1fr;
    gap: 40px;
    margin: 0 auto;
    padding: 44px 40px 0 40px;
    text-align: left;
    text-align: justify;
    .name {
      display: grid;
      grid-template-columns: 1fr;
      .cococo {
        // max-width: 460px;
        word-break: break-all;
      }
    }
    .intro {
      font-size: 15px;
      line-height: 1.6;
      letter-spacing: 0.06em;
    }
  }
}

#user-tabs {
  border-top: 1px solid rgba(0,0,0,0.1);
  .photo {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px,1fr));
    gap: 8px;
    padding: 14px 20px 20px 20px;
  }
}

.user-detail-sp-container {
  padding: 70px 24px 0 24px;
  .user-sp-description {
    .intro {
      text-align: justify;
      font-size: 15px;
      line-height: 1.6;
      letter-spacing: 0.06em;
    }
  }
}

.photo-dialog {
  .footer-information {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .photo-title {
    line-height: 1.5;
  }
}

.photo-d-back {
  background: #f1f3f7;
}

.omit {
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.img-hover {
  transition-duration: 1.3s;
  opacity: 1;
  &:hover {
    transition-duration: 0.3s;
    opacity: .7;
  }
}

@media (max-width: 768px) {

  #user-tabs {
    .photo {
      grid-template-columns: repeat(auto-fit, minmax(160px,1fr));
      gap: 6px;
      padding: 8px;
    }
  }
}


</style>