<template>
  <div>
   <h1 class="mb-16">
    <div class="pc">
      <v-img
        :src="require('@/assets/img/guide_back.jpg')" 
        class="back"
      >
      <v-img
        width="49%"
        :src="require('@/assets/img/guide_copy.svg')" 
        class="copy"
      />
      <p class="caption text-right mr-1 mt-1">
      Photo by <a href="https://unsplash.com/@gunjan195?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Gunjan Patel</a> on <a href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
      </p>
      </v-img>
    </div>

    <div class="sp mt-13">
      <v-img
        :src="require('@/assets/img/guide_back_sp.jpg')" 
        class="back"
      >
      <v-img
        width="92%"
        :src="require('@/assets/img/guide_copy.svg')" 
        class="copy"
      />
      <p class="caption text-right mr-2 mt-2">
      Photo by <a href="https://unsplash.com/@gunjan195?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Gunjan Patel</a> on <a href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
      </p>
      </v-img>
    </div>

   </h1> 
    <section id="feature">
      <h2 class="mb-16"> 
        <div class="d-flex align-end justify-center mb-4">
          <div>
            <v-img
             class="mr-2"
             width="150px"
              :src="require('@/assets/img/guide_logo.svg')" 
            />
          </div>
          の特徴
        </div>
      </h2>

      <v-row class="mb-6 mb-sm-16">
        <v-col 
        class="text-left"
        cols="12"
        sm="6"
        order="first"
        order-sm="first"
        align-self="center"
        >
          <div>
            <v-img
              class="ml-2 mr-2 mb-3 mr-sm-10 mb-sm-0"
              :src="require('@/assets/img/guide_feature01.svg')" 
            />
          </div>
        </v-col>
        <v-col 
        cols="12"
        sm="6"
        order="last"
        order-sm="last"
        align-self="center"
        >
        <div class="text-left">
          <h3 class="text-h6 text-sm-h4 font-weight-bold mb-2">スポット情報データベース</h3>
          <p>Flyersには、<span class="font-weight-bold primary--text">『ドローンを飛ばせる場所』</span>や<span class="font-weight-bold error--text">『飛行が禁止されている場所』</span>の情報が集まっています。
          <p>スポット情報には、写真や動画やクチコミなど、過去にそこでドローンを飛ばした事があるユーザーからの投稿も集まっているので、事前に情報を知ることができます。</p>
        </div>
        </v-col>
      </v-row>

      <v-row class="mb-6 mb-sm-16">
        <v-col 
        class="text-left"
        cols="12"
        sm="6"
        order="last"
        order-sm="first"
        align-self="center"
        >
          <div class="text-left">
            <h3 class="text-h6 text-sm-h4 font-weight-bold mb-2">ユーザーのクチコミ</h3>
            <p>実際に現地でドローンを飛ばしたユーザーのリアルな体験がクチコミ情報として掲載されています。</p>
            <p>ドローンを飛ばす前に様々な情報を知ることで、より安全にドローンを飛ばすことができると考えています。</p>
          </div>
        </v-col>
        <v-col 
        cols="12"
        sm="6"
        order="first"
        order-sm="last"
        align-self="center"
        >
        <div>
          <v-img
              class="ml-4 mr-4 mb-1 ml-sm-16 mb-sm-0"
            :src="require('@/assets/img/guide_feature02.svg')" 
          />
        </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col 
        class="text-left"
        cols="12"
        sm="5"
        order="first"
        order-sm="first"
        align-self="center"
        >
         <div>
            <v-img
              maxWidth="250px"
              class="ml-16 mr-16 mb-1 mb-sm-0"
              :src="require('@/assets/img/guide_feature03.svg')" 
            />
          </div>
        </v-col>
        <v-col 
        cols="12"
        sm="7"
        order="last"
        order-sm="last"
        align-self="center"
        >
        <div class="text-left">
          <h3 class="text-h6 text-sm-h4 font-weight-bold mb-2">管理者への確認と手続きの代行</h3>
          <p>土地管理者への確認と飛行手続きをユーザーの代わりに行う「飛行手続申請代行サービス」をご用意。</p>
          <p>これからは、ドローンを飛ばしたい場所は見つけたけど「どこに確認すればいいの…？」「必要な手続きがわからない…」なんて、もう悩む必要はありません。</p>
        </div>
        </v-col>
      </v-row>
    </section>

    <v-img
      :src="require('@/assets/img/guide_cando_top.svg')" 
    />
    <section id="cando">
      <h2> 
        <div class="d-flex align-end justify-center mb-4">
          <div>
            <v-img
             class="mr-2"
             width="150px"
             :src="require('@/assets/img/guide_logo.svg')" 
            />
          </div>
          でできること
        </div>
      </h2>
      <p class="subtitle-1 text-sm-h6 mb-0 text-center">「飛ばす前」から「飛ばした後」まで</p>
      <p class="subtitle-1 text-sm-h6 mb-12 text-center">ドローンを安心して楽しく飛ばすための <span class="accent-color font-weight-bold"> 情報と機能 </span>を提供します</p>
      <div class="grid text-left text-sm-center">
        <div>
          <div>
            <v-img
              class="ml-10 mr-10 mb-1 ml-sm-0 mr-sm-0 mb-sm-0"
              :src="require('@/assets/img/guide_cando01.svg')" 
            />
          </div>
          <div>
            <h3 class="text-h6 font-weight-bold mb-2 mt-4">スポット情報を調べる</h3>
            <p class="subtitle-1">日本全国のドローンを飛ばせる場所と、飛行禁止の場所をマップから探せます。その場所の詳細情報も知ることができます。</p>
          </div>
          <v-btn
          v-scroll-to="{ element: '#description01', duration: 1000, offset: -100 }"
          rounded
          color="white"
          large
          block>
            <span class="font-weight-bold">詳細をみる</span>
          </v-btn>
        </div>
        <div>
          <div>
            <v-img
              class="ml-10 mr-10 mb-1 ml-sm-0 mr-sm-0 mb-sm-0"
              :src="require('@/assets/img/guide_cando02.svg')" 
            />
          </div>
          <div>
            <h3 class="text-h6 font-weight-bold mb-2 mt-4">ドローンを飛ばしに行く</h3>
            <p class="subtitle-1">管理者へ<span class="error--text font-weight-bold">事前確認と飛行手続き</span>を行いましょう。「自分で手続き」か「Flyersを利用して手続き」の2つの方法があります。</p>
          </div>
          <v-btn
          v-scroll-to="{ element: '#description02', duration: 1000, offset: -100 }"
          rounded
          color="white"
          large
          block>
            <span class="font-weight-bold">詳細をみる</span>
          </v-btn>
        </div>

        <div>
          <div>
            <v-img
              class="ml-10 mr-10 mb-1 ml-sm-0 mr-sm-0 mb-sm-0"
              :src="require('@/assets/img/guide_cando03.svg')" 
            />
          </div>
          <div>
            <h3 class="text-h6 font-weight-bold mb-2 mt-4">情報を共有する</h3>
            <p class="subtitle-1">スポット情報や空撮写真、動画、クチコミなどいろいろな情報を、全国にいるユーザーにカンタンに共有できます。</p>
          </div>
          <v-btn
          v-scroll-to="{ element: '#description03', duration: 1000, offset: -100 }"
          rounded
          color="white"
          large
          block>
            <span class="font-weight-bold">詳細をみる</span>
          </v-btn>
        </div>
      </div>
    </section>
    <v-img
      :src="require('@/assets/img/guide_cando_bottom.svg')" 
    />

    <section id="description">
      <v-row>
        <v-col 
        class="text-left"
        cols="12"
        sm="6"
        order="last"
        order-sm="first"
        align-self="center"
        >
          <h3 class="text-h5 text-sm-h4 font-weight-bold mb-2" id="description01">スポット情報を調べる</h3>
          <div class="ml-2">
            <p>日本全国の11エリアもしくは、都道府県ごとにフライトスポット（飛ばしていい場所）と、飛行が禁止されているスポットを調べることができます。</p> 
            <p>スポット詳細画面には、その場所の情報や、Flyersユーザーが共有してくれた空撮写真、動画、クチコミなどが掲載されていますので、ぜひドローンを飛ばす際の参考にしてみてください</p>
            <h4 class="subtitle-1 font-weight-bold">調べられる情報</h4>
            <p class="subtitle-2">住所 / 駐車場の有無 / トイレの有無 / 土地管理者と確認先 / 管轄の警察署 / みんなの空撮写真 / 関連する空撮動画 /現地の立て札や張り紙などの写真 / スポットについての備考 / 禁止されている事がわかる情報</p>
          </div>
        </v-col>
        <v-col 
        cols="12"
        sm="6"
        order="first"
        order-sm="last"
        align-self="center"
        >
        <div>
          <v-img
            class="ml-10 mr-10 mb-1 ml-sm-16 mr-sm-0 mb-sm-0"
            :src="require('@/assets/img/guide_description01.svg')" 
          />
        </div>
        </v-col>
      </v-row>
      
      <v-divider class="mt-16 mb-16"></v-divider>

      <v-row>
        <v-col 
        class="text-left"
        cols="12"
        sm="6"
        order="first"
        order-sm="first"
        align-self="center"
        >
          <div>
            <v-img
              class="ml-8 mr-8 ml-sm-0 mr-sm-10"
              :src="require('@/assets/img/guide_description02.svg')" 
            />
          </div>
        </v-col>
        <v-col 
        cols="12"
        sm="6"
        order="last"
        order-sm="last"
        align-self="center"
        >
        <div class="text-left">
          <h3 class="text-h5 text-sm-h4 font-weight-bold mb-2" id="description02">ドローンを飛ばしに行く</h3>
          <p class="ml-2 mb-0">ドローンの飛行には、土地管理者から飛行の許可または承認を得る必要があります。</p>
          <p class="ml-2 error--text">以下のどちらかの方法で必ず「確認および手続き」を行ってください。</p>
          <h4 class="subtitle-1 text-sm-h6 font-weight-bold">自分で確認と手続き</h4>
          <div class="ml-2">
            <p class="mb-0">ユーザーご自身ですべての管理者と管轄の警察署を調べ、許可まはた承認を得た上でドローンの飛行を行ってください。</p>
            <p>スポットによっては、届け出や申請書作成が必要になる場合があります。<span class="caption">※1</span></p>
          </div>
          <h4 class="subtitle-1 text-sm-h6 font-weight-bold accent-color">Flyersで確認と手続き</h4>
          <div class="ml-2">
            <p class="mb-0">Flyersの飛行申請手続代行サービスを利用できます。</p>
            <p>利用申請は最短1分で完了します。 <span class="caption">※1</span></p>
            <p>詳しくは以下をご確認ください。</p>
            <v-btn
            rounded
            block
            color="#00A99D"
            outlined
            x-large
            class="mb-10"
            :to="'/agentservice'"
            >
              <span class="font-weight-bold">飛行申請手続代行について</span>
            </v-btn>
            <p class="caption">※1：国土交通大臣への承認手続きは必要に応じてご自身で行なってください。</p>
          </div>
        </div>
        </v-col>
      </v-row>

      <v-divider class="mt-16 mb-16"></v-divider>
      <v-row>
        <v-col 
        class="text-left"
        cols="12"
        sm="6"
        order="last"
        order-sm="first"
        align-self="center"
        >
          <h3 class="text-h5 text-sm-h4 font-weight-bold mb-2" id="description03">情報を共有する</h3>
          <div class="ml-2">
            <p class="mb-0">情報の共有は、専用フォームから必要項目を入力するだけでカンタンに投稿を行うことが可能です。</p>
            <p>もちろんプロ・アマ・初心者問わず、誰でもスポット情報の共有を行うことができます。</p>
            <p>飛ばす人も、飛ばさない人も、みんなが安全にそしてトラブルのない社会づくりのため、ぜひご協力をお願いいたします。</p>
            <p>土地管理者の方からの情報共有もお待ちしています!</p>
          </div>
        </v-col>
        <v-col 
        cols="12"
        sm="6"
        order="first"
        order-sm="last"
        align-self="center"
        >
        <div>
          <v-img
            class="ml-10 mr-10 mb-1 ml-sm-16 mr-sm-0 mb-sm-0"
            :src="require('@/assets/img/guide_description03.svg')" 
          />
        </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>
<script>
export default {
  mounted() {
    document.title = this.$route.meta.title
  },
}
</script>
<style lang="scss" scoped>
$maxWidth: 1000px;

p {
  text-align: justify;
}

.accent-color {
  color: #00A99D;
}

.back {
  position: relative;
  .copy {
    position: absolute;
    top:40px;
    left:40px;
    mix-blend-mode: multiply;
  }
}

#feature {
  max-width: $maxWidth;
  margin: 0 auto 100px auto;
  padding: 0 40px;
}

#cando {
  margin: 0 auto;
  background-color: #F8F8F8;
  padding: 100px 40px;
  .grid {
    max-width: $maxWidth;
    margin: 0 auto;
    padding: 0 40px;
    display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-content: center;
    gap: 40px;
  }
}

#description {
  max-width: $maxWidth;
  margin: 100px auto 100px auto;
  padding: 0 40px;
}

@media (max-width: 768px) {

  #cando {
    padding: 100px 10px;
    .grid {
      padding: 0 20px;
    }
  }

  #description {
    padding: 0 20px;
  } 

  .back {
    .copy {
      top:60px;
      left:20px;
    }
  }

}

</style>