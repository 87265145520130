<template>
<div>
  <div class="signup pa-6">
    <v-card 
      class="mx-auto pr-8 pl-8 pt-8 pb-14"
      width="650px"
      > 
      <v-row
        justify="center"
        >
        <v-img 
        class="ma-8"
        src="@/assets/img/betalogo.svg"
        max-width="250"
        ></v-img>
      </v-row>
      <h1 class="text-h4 font-weight-bold mt-1 mb-1">利用申請完了</h1>
      <p class="text-h6">利用申請ありがとうございます</p>

      <p class="mb-4">申請内容の確認のため、利用申請確認メールをお送り致します。</p>
      <p class="mb-4">いただいた情報をもとに<span class="font-weight-bold">土地管理者などへ申請</span>を行います。</p>
      <p class="mb-8">完了次第メールにてご連絡差し上げます。<br class="pc">完了までお待ちください。</p>
     
      <router-link :to="{ path: '/home'}" >
        <v-btn
          width="100%"
          max-width="400px"
          height="55px"
          color="primary"
          x-large
          rounded
          depressed
        >
        <span class="text-h6 font-weight-bold">TOPへもどる</span>
        </v-btn>
        </router-link>
      </v-card>
    </div>
  </div>
</template>


<script>
import firebase from 'firebase'

export default {
  created() {
    firebase.analytics().logEvent('form_complete', {});
  },
  mounted() {
    document.title = this.$route.meta.title
  },
}
</script>