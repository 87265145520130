<template>
  <div class="user-home mt-10">
    <UserMenu 
     userName="ああああああああああああああああああああああああああああああ"
    />
    <section >
      資格・所有機体
    </section>
  </div>
</template>

<script>
import UserMenu from '@/components/UserMenu.vue'

export default {
  components: {
    UserMenu
  },
  mounted() {
    document.title = this.$route.meta.title
  },
}
</script>

<style lang="scss">
.user-home {
  margin: 0 40px 0 40px;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 230px 1fr;
}

.user-home-main{
  text-align: left;
  margin-left: 90px;
  .tops {
    display: flex;
    align-items: center;
  }
}
</style>