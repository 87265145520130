<template>
  <div class="user-home mt-10">
    <UserMenu 
     :userName="this.$store.getters.displayName" 
    />
    <section class="user-home-main">
      <router-link to="account" class="no-under-line">
        <div class="tops mb-8">
          <div>
            <v-icon large class="mr-4">mdi-account-circle</v-icon>
          </div>
          <div>
            <h3>アカウント</h3>
            <p class="mb-0">メールアドレス確認・パスワード変更ができます</p>
          </div>
        </div>
      </router-link>
      <router-link to="profile" class="no-under-line">
        <div class="tops mb-8">
          <div>
            <v-icon large class="mr-4">mdi-emoticon-happy</v-icon>
          </div>
          <div>
            <h3>プロフィール編集</h3>
            <p class="mb-0" >ユーザー名などを設定できます</p>
          </div>
        </div>
      </router-link>
      <div class="disabled">
        <router-link to="qualification" class="no-under-line">
          <div class="tops mb-8">
            <div>
              <v-icon large class="mr-4">mdi-star-circle</v-icon>
            </div>
            <div>
              <h3>資格・所有機体情報を編集</h3>
              <p class="mb-0">お持ちの資格や所有機体情報を登録・編集できます</p>
            </div>
          </div>
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
import firebase from 'firebase'
import UserMenu from '@/components/UserMenu.vue'

export default {
  components: {
    UserMenu
  },
  data() {
    return {
      user:[]
    }
  },
  mounted() {
    document.title = this.$route.meta.title
  },
  created() {
    firebase.auth().onAuthStateChanged(user => {
      this.user = user ? user : {}
      this.$store.commit('updateUser', user)
    })

    var db = firebase.firestore()
    var docs = []

    db.collection("users").where('userId','==', this.$store.getters.user.uid)
    .get()
    .then(querySnapshot => {
      if(querySnapshot.size >=1){
        querySnapshot.forEach(doc => {
          docs = doc.data()
          this.docId = doc.id
        })
        this.src = docs.userIconSrc
        this.displayName = docs.displayName != null ? docs.displayName : this.$store.getters.user.email
        this.$store.commit('updateDisplayName', this.displayName)
      }
    })
    .catch(function(error) {
      console.log("ERROR: ", error);
    })
  },
}
</script>

<style lang="scss">
.user-home {
  margin: 0 40px 0 40px;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 230px 1fr;
}

.user-home-main {
  min-width: 400px;
  text-align: left;
  margin-left: 90px;
  .tops {
    display: flex;
    align-items: center;
  }
  a {
    color: #2C3E50 !important;
    &:hover {
      color: #1976d2 !important;
    }
  }
  .disabled {
    opacity: .25 !important;
    pointer-events: none;
  }
}

@media (max-width: 768px) {

  .user-home {
    grid-template-columns: 1fr !important;
  }

  .user-home-main {
    margin-top: 50px;
    min-width: initial;
    margin-left: 0px !important;
    .tops {
      display: flex;
      align-items: center;
    }
    a {
      color: #2C3E50 !important;
      &:hover {
        color: #1976d2 !important;
      }
    }
    .disabled {
      opacity: .25 !important;
      pointer-events: none;
    }
  }
}
</style>