<template>
  <div>
    <div class="us" v-if="!userFlightSpot.certify">
      <p class="caption font-weight-bold mb-0">このページはユーザーから投稿された情報を表示しています</p>
    </div>
    <div class="fs" v-else>
      <p class="caption font-weight-bold mb-0 pt-1 pb-1 pt-sm-0 pb-sm-0 pl-3 pr-3">このスポットはFlyersで利用申請をすると、カンタンにドローンを飛ばすことができます</p>
    </div>

    <div class="mt-14 mt-sm-0">
      <gmap-map 
        :center="center" 
        :zoom="userFlightSpot.zoom != null ? parseInt(userFlightSpot.zoom) : 16"
        style="width:100%; height:400px;"
        :options="{'mapTypeId':'hybrid','zoomControl':'true','fullscreenControl':'true'}"
        ref="gmap"
        >
        <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :ref="`marker${index}`"
          :icon="getMakerOptions(m.type)"
        />

        <div v-if="userFlightSpot.certify">
          <span v-if="isLatlng">
            <div v-if="latlngs.length >= 1">
              <span v-for="(path,index) in latlngs" :key="'latlngs'+index">
                <gmap-polygon
                  :options="{'strokeColor': '#00b3ff','fillColor': '#0089FF','strokeWeight': '2', 'fillOpacity': '0.2' }" 
                  :path="path"
                >
                </gmap-polygon>
              </span>
            </div>
          </span>

          <span v-if="islaunchSite">
            <div v-if="launchSites.length >= 1">
              <span v-for="(path,index) in launchSites" :key="'launchSites'+index">
                <gmap-polygon
                  :options="{'strokeColor': '#ff8a00','fillColor': '#ff8a00','strokeWeight': '2', 'fillOpacity': '0.2'}" 
                  :path="path"
                >
                </gmap-polygon>
              </span>
            </div>
          </span>

          <span v-if="isParking">
            <div v-if="parkings.length >= 1">
              <span v-for="(path,index) in parkings" :key="'parkings'+index">
                <gmap-polygon
                  :options="{'strokeColor': '#eb5ddc','fillColor': '#eb5ddc','strokeWeight': '2', 'fillOpacity': '0.2', 'zIndex': '4'}" 
                  :path="path" 
                >
                </gmap-polygon>
              </span>
            </div>
          </span>
        </div>
      </gmap-map>
    </div>
    <div class="text-left ml-6 mt-4">
      <p class="subtitle-2 mb-0">表示切替ボタン</p>
    </div>
    <div class="text-left d-flex flex-wrap path-controller">
      <v-btn 
        outlined
        rounded
        color="#FF0000"
        @click="showDid()"
        class="mr-3 mt-2"
        small
      >
        <v-icon class="mr-2" v-if="isDid">mdi-eye-off</v-icon>
        <v-icon class="mr-2" v-else>mdi-eye</v-icon>
        人口集中地区（DID）
      </v-btn>
      <v-btn 
        outlined
        rounded
        color="#0093ff"
        @click="isLatlng = !isLatlng"
        v-if="userFlightSpot.certify"
        class="mr-3 mt-2"
        small
      >
        <v-icon class="mr-2" v-if="!isLatlng">mdi-eye-off</v-icon>
        <v-icon class="mr-2" v-else>mdi-eye</v-icon>
        利用申請範囲
      </v-btn>
      <v-btn 
        outlined
        rounded
        color="#ff8a00"
        @click="islaunchSite = !islaunchSite"
        v-if="userFlightSpot.certify"
        class="mr-3 mt-2"
        small
      >
        <v-icon class="mr-2" v-if="!islaunchSite">mdi-eye-off</v-icon>
        <v-icon class="mr-2" v-else>mdi-eye</v-icon>
        離発着場所
      </v-btn>
      <v-btn 
        outlined
        rounded
        color="#eb5ddc"
        @click="isParking = !isParking"
        v-if="userFlightSpot.certify"
        class="mr-3 mt-2"
        small
      >
        <v-icon class="mr-2" v-if="!isParking">mdi-eye-off</v-icon>
        <v-icon class="mr-2" v-else>mdi-eye</v-icon>
        駐車場
      </v-btn>
    </div>

    <div class="container">


      <section class="mb-0 mt-6">
        <div class="d-flex align-center justify-space-between">
          <div>
            <v-img 
            width="110px"
            height="20px"
            :src="getDidIcon(userFlightSpot.did)" />
          </div>

          <div class="text-center d-flex align-end ml-1" v-if="userFlightSpot.type != VUE_APP_NOT_FLIGHT_SPOT_VALUE">
            <v-menu
              transition="slide-y-transition"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <div>
                  <v-btn
                    depressed
                    small
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                  <div>
                    <v-img
                      width="12"
                      height="12"
                      :src="require('@/assets/img/icon_plus.svg')" 
                    />
                  </div>
                  </v-btn>
                  <span class="caption ml-1">追加</span>
                </div>
              </template>
              <v-list class="text-left pa-0">
                <p class="mb-0 pt-2 ml-3 mr-5 subtitle-2 font-weight-bold">スポットをリストに追加</p>
                <v-list-item
                dense
                link>
                  <v-list-item-title @click="addBookmark(userFlightSpot.id,userFlightSpot.name)"><v-icon small color="green lighten-1" class="mb-0">mdi-flag</v-icon> <span class="subtitle-2 ml-1">行ってみたい</span></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>

        <h1 class="text-sm-h2 text-h4 font-weight-bold mb-0 mt-3 mt-md-5">{{ userFlightSpot.name }}</h1>

        <div class="information mt-1 mb-0 mt-md-4">
          <div class="d-flex align-center">
            <v-img 
              max-height="26px"
              max-width="23px"
              class="mr-1"
              v-bind:class="{'opa': !userFlightSpot.certify }"
              :src="require('@/assets/img/icon_cirtify.svg')"/>
            <v-img 
              max-height="26px"
              max-width="20px"
              class="mr-2"
              :src="getTypeIconSrc(userFlightSpot.type)"/>
            <p class="caption text-sm-subtitle-2 mb-0 pt-0 mr-2">{{ userFlightSpot.prefecture }} / {{ userFlightSpot.municipality }}</p>
          </div>
        </div>
            



        <div class="point title font-weight-bold mb-6" v-if="userFlightSpot.highlights != ''">
            <p class="mb-1" v-for="highlight in userFlightSpot.highlights" :key="highlight">・{{ highlight }}</p>
        </div>
        <p class="mb-2">{{ userFlightSpot.description }}</p>
        <div class="mb-2">
          <!-- <v-btn
            small
            rounded
            depressed
            class="mr-2 mt-2"
          >
          <span class="font-weight-bold">タグ追加</span>
          </v-btn> -->
          <v-chip
            label
            outlined
            small
            v-for="tag in userFlightSpot.tags" :key="tag"
            class="mr-2 mt-2"
          >{{ tag }}</v-chip>
        </div>
      </section>


  
      <div class="mt-10 mb-12">
        <h2 class="text-sm-h5 text-h6 font-weight-bold mb-4">スポット情報</h2>
        <div class="ml-2" v-if="userFlightSpot.type != parseInt(VUE_APP_NOT_FLIGHT_SPOT_VALUE)">
          <p class="font-weight-bold mb-1">住所</p>
          <p class="subtitle-2">{{ userFlightSpot.address != '' ? userFlightSpot.address : '確認中' }}</p>
          <p class="font-weight-bold mb-1">設備</p>
          <p class="subtitle-2">駐車場：{{ getFacilityText(userFlightSpot.parking) }}<br>トイレ：{{ getFacilityText(userFlightSpot.restroom) }}</p>
          <div v-if="userFlightSpot.police != ''">
            <p class="font-weight-bold mb-1">管轄の警察署</p>
            <p class="subtitle-2">{{ userFlightSpot.police }}</p>
          </div>
          <div class="mb-4">
            <p class="font-weight-bold mb-1">土地管理者または確認先</p>
            <div class="mb-4" v-if="uid == ''">
              <router-link link to="/auth/signup">
                <v-img
                  maxWidth="715"
                  :src="require('@/assets/img/admin_bokashi.png')"
                />
              </router-link>
              <SignupBtn/>
            </div>
            <div v-else>
              <div class="align-end" v-for="(placeAdmin,index) in userFlightSpot.placeAdmins" :key="index">
                <p class="subtitle-2 mb-0">{{ placeAdmin.name != '' ? placeAdmin.name : '確認中'}}</p>
                <p class="caption mb-2 ml-0">{{ placeAdmin.contact != null && placeAdmin.contact != '' ? '（連絡先：'+ placeAdmin.contact + '）' : '' }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="ml-2" v-else>
          <p class="font-weight-bold mb-1">住所</p>
          <p class="subtitle-2">{{ userFlightSpot.address != '' ? userFlightSpot.address : '確認中' }}</p>
          <p class="font-weight-bold mb-1">土地管理者または確認先</p>
          <div class="d-flex align-end" v-for="(placeAdmin,index) in userFlightSpot.placeAdmins" :key="index">
            <p class="subtitle-2 mb-1">{{ placeAdmin.name != '' ? placeAdmin.name : '確認中'}}</p>
            <p class="caption mb-1 ml-1">{{ placeAdmin.contact != null && placeAdmin.contact != '' ? '（連絡先：'+ placeAdmin.contact + '）' : '' }}</p>
          </div>
        </div>
      </div>

      <div class="submit pb-14" v-if="userFlightSpot.certify && userFlightSpot.type != parseInt(VUE_APP_NOT_FLIGHT_SPOT_VALUE)">
        <v-btn
          width="40%"
          minWidth="300px"
          large
          rounded
          depressed
          @click="submit()"
          class="use"
        >
          <span class="subtitle-1 font-weight-bold">利用申請をする（無料）</span>
        </v-btn>
        <p class="subtitle-2 font-weight-bold mt-4"><router-link to="/agentservice" class="blue-grey--text">Flyersの飛行手続申請代行について</router-link></p>
      </div>

      <v-divider></v-divider>

      <div class="mt-14 mb-14" v-if="userFlightSpot.type != parseInt(VUE_APP_NOT_FLIGHT_SPOT_VALUE)">
        <div class="mt-14" v-if="userFlightSpot.certify">
          <h2 class="text-sm-h5 text-h6 font-weight-bold mb-4">現地ルール・注意事項</h2>
          <div class="ml-2 mb-14">
            <div class="rule" v-if="userFlightSpot.rules != ''">
              <h3 class="subtitle-1 font-weight-bold mb-1">現地ルール</h3>
              <div class="mb-4" v-if="uid == ''">
                <router-link link to="/auth/signup">
                  <v-img
                    maxWidth="491"
                    :src="require('@/assets/img/rule_bokashi.png')"
                  />
                </router-link>
              </div>
              <ul class="subtitle-2 red--text text--darken-1 mb-6" v-else>
                <li v-for="(rule,index) in userFlightSpot.rules" :key="index">{{ rule }}</li>
              </ul>
            </div>
            <div class="caution" v-if="userFlightSpot.cautions != ''">
              <h3 class="subtitle-1 font-weight-bold mb-1">注意事項</h3>
              <div class="mb-4" v-if="uid == ''">
                <router-link link to="/auth/signup">
                  <v-img
                    maxWidth="531"
                    :src="require('@/assets/img/caution_bokashi.png')"
                  >
                  </v-img>
                </router-link>
                <SignupBtn/>
              </div>
              <ul class="subtitle-2 red--text text--darken-1" v-else>
                <li v-for="(caution,index) in userFlightSpot.cautions" :key="index">{{ caution }}</li>
              </ul>
            </div>
          </div>
          <v-divider></v-divider>
        </div>
      </div>
      
      <div class="mt-14 mb-14" v-if="userFlightSpot.type != parseInt(VUE_APP_NOT_FLIGHT_SPOT_VALUE)">
        <h2 class="text-sm-h5 text-h6 font-weight-bold mb-4">このスポットの写真
          <v-btn
            small
            rounded
            depressed
            :color="isAddPhoto ? 'gray':'primary'"
            class="ml-2 mb-1"
            @click="addPhoto()"
            v-if="uid != ''"
          >
          <span class="font-weight-bold">{{ isAddPhoto ? '投稿フォームを閉じる':'＋ 写真を投稿' }}</span>
          </v-btn>
        </h2>

        <div v-if="isAddPhoto" class="add_photo">
          <div class="mt-6">
            <h3 class="subtitle-1 font-weight-bold mb-3">このスポットに関する画像を投稿します</h3>
            <v-file-input 
              outlined
              accept=".jpg, .jpeg, .png" 
              placeholder="画像ファイルを選択してください"
              @change="onFileUpload"
              ref="preview"
              dense
              prepend-inner-icon="mdi-image-area"
              @click:clear="fileClear()"
            ></v-file-input>
          </div>

          <div v-if="src != ''" >
            <v-img
              :aspect-ratio="16/9"
              class="rounded mb-6"
              contain
              :src="src"
            >
            </v-img>
          </div>

          <div v-if="src != ''" class="mb-0">
            <h3 class="subtitle-1 font-weight-bold mb-1">タイトル（任意）</h3>
            <v-text-field
              placeholder="タイトル"
              outlined
              dense
              v-model="photo.title"
              counter="60"
              maxlength="60"
            >
            </v-text-field>
          </div>

          <div v-if="src != ''" class="mb-0">
            <h3 class="subtitle-1 font-weight-bold mb-1">写真についての詳細（任意）</h3>
            <v-textarea
              :placeholder="addPhotoPlaceholderText"
              outlined
              dense
              v-model="photo.description"
              counter="500"
              maxlength="500"
            >
            </v-textarea>
          </div>

          <div>
            <v-btn
              width="200px"
              color="primary"
              depressed
              rounded
              :disabled="src == ''"
              @click="photoSubmit"
              :loading="photoSubmitStatus"
              class="mb-11"
            >
            <span class="font-weight-bold">この写真を投稿する</span>
            </v-btn>
          </div>
        </div>

        <div class="kusatsu">
          <div v-for="(element,index) in userPhotos" :key="index">
            <div class="card">
              <router-link to="#" @click.native="showPhoto(index)">
                <v-img
                  :aspect-ratio="16/9"
                  :src="element.photo.src"
                  :lazy-src="require('../../assets/img/lazy_img.svg')"
                  class="rounded img-hover"
                >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="blue-grey lighten-2"
                    ></v-progress-circular>
                  </v-row>
                </template>
                </v-img>
              </router-link>
              <div class="d-flex align-content-center mt-2">
                <router-link :to="'/users/'+ element.user.userId +'/detail'" class="no-under-line default-color">
                <v-avatar
                size="24">
                  <v-img
                    :src="element.user.userIconSrc"
                  ></v-img>
                </v-avatar>
                </router-link>
                <router-link :to="'/users/'+ element.user.userId +'/detail'" class="no-under-line default-color">
                  <p class="caption mb-0 pt-0 ml-2 omit">{{ element.user.displayName }}</p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div v-if="userPhotos.length == 0">
          <div class="subtitle-2 ml-2">
            <p>このスポットに関する写真をお持ちですか？</p>
            <p class="mb-0">それなら <span class="primary--text font-weight-bold">『写真を投稿』</span> ボタンを押して、さっと投稿してみましょう！</p>
            <p>一枚目ならより多くの人に見てもらえるチャンス！</p>
          </div>
        </div>
        <v-divider class="mt-10"></v-divider>
      </div>

      <div class="not_flight_spot" v-if="userFlightSpot.type == parseInt(VUE_APP_NOT_FLIGHT_SPOT_VALUE)">
        <div class="mt-14 mb-14">
          <div>
            <h2 class="text-sm-h5 text-h6 font-weight-bold mb-4">禁止されている事がわかる情報など
              <v-btn
                small
                rounded
                depressed
                :color="isAddSource ? 'gray':'primary'"
                class="ml-0 ml-sm-2 mb-1"
                @click="isAddSource = !isAddSource"
                v-if="uid != ''"
              >
              <span class="font-weight-bold">{{ isAddSource ? '投稿フォームを閉じる':'＋ 情報を投稿する' }}</span>
              </v-btn>
            </h2>
          </div>

          <div v-if="isAddSource">
            <h3 class="subtitle-1 font-weight-bold mb-1">禁止されている事がわかる情報元など教えてください</h3>
            <div>
              <v-form ref="form2">
                <v-textarea
                  placeholder="禁止されていることがわかるWebページのURLや、立て札や張り紙の内容など"
                  outlined
                  dense
                  v-model="source.description"
                  :counter="VUE_APP_SOURCE_COUNT"
                  :maxlength="VUE_APP_SOURCE_COUNT"
                >
                </v-textarea>
              </v-form>
              <div>
                <v-btn
                  width="200px"
                  color="primary"
                  depressed
                  rounded
                  :disabled="source.description == ''"
                  @click="sourceSubmit"
                  :loading="sourceSubmitStatus"
                  class="mb-11"
                >
                <span class="font-weight-bold">この情報を投稿する</span>
                </v-btn>
              </div>
            </div>
          </div>
            <div v-if="sources.length > 0">
              <div v-for="(element,index) in sources" :key="index" class="source mb-4">
                <p v-html="autoLink(element.source.description)" class="subtitle-2 text-pre-wrap ml-0 sm-ml-2 text-justify"></p>
                <div class="d-flex align-content-center mt-4">
                  <router-link :to="'/users/'+ element.user.userId +'/detail'" class="no-under-line default-color">
                  <v-avatar
                  size="24">
                    <v-img
                      :src="element.user.userIconSrc"
                    ></v-img>
                  </v-avatar>
                  </router-link>
                  <router-link :to="'/users/'+ element.user.userId +'/detail'" class="no-under-line default-color">
                    <p class="caption mb-0 pt-0 ml-2">{{ element.user.displayName }}</p>
                  </router-link>
                </div>
                <div class="d-flex justify-space-between">
                <p class="caption mb-0 pt-1">{{ formatDate(element.source.createdAt.toDate()) }}</p>
                  <v-row>
                    <v-col
                      cols="12"
                      class="d-flex justify-end"
                    >
                      <v-btn
                        icon
                        small
                      >
                      <v-icon x-small class="blue-grey--text text--lighten-4" @click="opneSourceDeleteDialog(element.source.id)" v-if="element.user.userId == uid">fas fa-trash</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        small
                      >
                      <v-icon x-small class="blue-grey--text text--lighten-4" @click="openRepotDialog(element,'禁止されている事がわかる情報')">fas fa-flag</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  </div>
              </div>
            </div>
            <div class="subtitle-2 ml-2" v-else>
              <p class="mb-0">確認中です</p>
              <p>土地管理者の方が直接投稿いただくことも可能です</p>
            </div>
        </div>
        <v-divider></v-divider>
      </div>
      
      <div class="mt-14 mb-14" v-if="userFlightSpot.type == parseInt(VUE_APP_NOT_FLIGHT_SPOT_VALUE)">
        <h2 class="text-sm-h5 text-h6 font-weight-bold mb-4">現地の立て札や張り紙などの写真
          <v-btn
            small
            rounded
            depressed
            :color="isAddPhoto ? 'gray':'primary'"
            class="ml-0 ml-sm-2 mb-1"
            @click="isAddPhoto = !isAddPhoto"
            v-if="uid != ''"
          >
          <span class="font-weight-bold">{{ isAddPhoto ? '投稿フォームを閉じる':'＋ 写真を投稿' }}</span>
          </v-btn>
        </h2>
        <div v-if="userPhotos.length == 0">
          <div class="subtitle-2 ml-2">
            <p class="mb-0">もしこのスポットの立て札や注意書きなどの写真をお持ちの場合は投稿をお願いいたします。</p>
            <p>土地管理者の方が直接投稿いただくことも可能です</p>
          </div>
        </div>
          <div v-if="isAddPhoto" class="add_photo">
            <div class="mt-6">
              <h3 class="subtitle-1 font-weight-bold mb-3">このスポットに関する禁止されていことがわかる写真を投稿します</h3>
              <v-file-input 
                outlined
                accept=".jpg, .jpeg, .png" 
                placeholder="画像ファイルを選択してください"
                @change="onFileUpload"
                ref="preview"
                dense
                prepend-inner-icon="mdi-image-area"
                @click:clear="fileClear()"
              ></v-file-input>
            </div>

            <div v-if="src != ''" >
              <v-img
                :aspect-ratio="16/9"
                class="rounded mb-6"
                contain
                :src="src"
              >
              </v-img>
            </div>

            <div v-if="src != ''" class="mb-0">
              <h3 class="subtitle-1 font-weight-bold mb-1">写真についての詳細（任意）</h3>
              <v-textarea
                placeholder="詳細"
                outlined
                dense
                v-model="photo.description"
                counter="500"
                maxlength="500"
              >
              </v-textarea>
            </div>

            <div>
              <v-btn
                width="200px"
                color="primary"
                depressed
                rounded
                :disabled="src == ''"
                @click="photoSubmit"
                :loading="photoSubmitStatus"
                class="mb-11"
              >
              <span class="font-weight-bold">この写真を投稿する</span>
              </v-btn>
            </div>
          </div>
          <div class="kusatsu">
            <div v-for="(element,index) in userPhotos" :key="index">
              <div class="card">
                <router-link to="#" @click.native="showPhoto(index)">
                  <v-img
                    :aspect-ratio="16/9"
                    :src="element.photo.src"
                    :lazy-src="require('../../assets/img/lazy_img.svg')"
                    class="rounded img-hover"
                  >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="blue-grey lighten-2"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                  </v-img>
                </router-link>
                <div class="d-flex align-content-center mt-2">
                  <router-link :to="'/users/'+ element.user.userId +'/detail'" class="no-under-line default-color">
                  <v-avatar
                  size="24">
                    <v-img
                      :src="element.user.userIconSrc"
                    ></v-img>
                  </v-avatar>
                  </router-link>
                  <router-link :to="'/users/'+ element.user.userId +'/detail'" class="no-under-line default-color">
                    <p class="caption mb-0 pt-0 ml-2">{{ element.user.displayName }}</p>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
      </div>

      <div v-if="userFlightSpot.type == parseInt(VUE_APP_FLIGHT_SPOT_VALUE)">
        <section id="user_youtube" class="mt-16 pb-13">
          <h2 class="text-sm-h5 text-h6 font-weight-bold">このスポットに関する動画
            <v-btn
              small
              rounded
              depressed
              :color="isAddYoutube ? 'gray':'primary'"
              class="ml-2 mb-1"
              @click="isAddYoutube = !isAddYoutube"
              v-if="uid != ''"
            >
            <span class="font-weight-bold">{{ isAddYoutube ? '追加フォームを閉じる':'＋ 動画を追加' }}</span>
            </v-btn>
          </h2>
          <p class="caption">動画再生時の音量に注意ください</p>

          <div v-if="isAddYoutube" class="add_youtube">
            <div class="mt-7">
              <h3 class="subtitle-1 font-weight-bold mb-3">このスポットに関する動画を追加します</h3>
              <v-form ref="form">
                <v-text-field
                  placeholder="https://youtu.be/ または https://www.youtube.com/ から始まるURL"
                  outlined
                  dense
                  v-model="youtube.url"
                  :rules="[rules.regex]"
                >
                </v-text-field>
              </v-form>
            </div>

            <div>
              <v-btn
                width="200px"
                color="primary"
                depressed
                rounded
                @click="youtubeSubmit"
                :loading="youtubeSubmitStatus"
                class="mb-6"
              >
              <span class="font-weight-bold">この動画を追加する</span>
              </v-btn>
            </div>
          </div>

          <div class="contents">
            <div v-for="(element,index) in youtubes" :key="index">
              <div class="wrapper">
                <iframe width="560" height="315" :src="'https://www.youtube.com/embed/' + getYoutubeId(element.youtube.url) " frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="footer-information">
                  <p class="caption mt-2 blue-grey--text text--lighten-2 omit mb-0"><router-link :to="'/users/'+ element.user.userId +'/detail'" class="no-under-line blue-grey--text text--lighten-2">{{ element.user.displayName }}</router-link> により追加されました</p>
                <div class="d-flex">
                  <v-icon x-small class="mr-3 pt-2 blue-grey--text text--lighten-3" @click="openYoutubeDeleteDialog(element.youtube.id)" v-if="element.youtube.uid == uid">fas fa-trash</v-icon>
                  <v-icon x-small class="mr-1 pt-2 blue-grey--text text--lighten-3" @click="openRepotDialog(element,'このスポットの')">fas fa-flag</v-icon>
                </div>
              </div>
            </div>
          </div>
          <div v-if="youtubes.length == 0">
            <div class="subtitle-2 mt-5 ml-2">
              <p class="mb-0">このスポットの動画は現在登録されていません。</p>
              <p>あなたが一番最初に登録してみませんか😉</p>
              <p class="mb-0">登録は <span class="primary--text font-weight-bold">『動画を追加』</span> ボタンを押して、YoutubeのURLを入力するだけ！</p>
            </div>
          </div>
        </section>
        <v-divider></v-divider>
      </div>

      <div class="mt-16 pb-10 word_break_all" v-if="userFlightSpot.type != parseInt(VUE_APP_NOT_FLIGHT_SPOT_VALUE)">
        <h2 class="text-sm-h5 text-h6 font-weight-bold mb-0">みんなのクチコミ
          <v-btn
            small
            rounded
            depressed
            :color="isAddNote ? 'gray':'primary'"
            class="ml-2 mb-1"
            v-if="uid != ''"
            @click="isAddNote = !isAddNote"
          >
          <span class="font-weight-bold">{{ isAddNote ? '投稿フォームを閉じる':'＋ 情報を投稿' }}</span>
          </v-btn>
        </h2>

        <div v-if="isAddNote">
          <h3 class="subtitle-1 font-weight-bold mb-1">届け出に関すること・近隣の飲食店・観光情報などを入力してください</h3>
          <div>
            <v-form ref="form2">
              <v-textarea
                :placeholder="notePlaceholderText"
                outlined
                dense
                v-model="note.description"
                :counter="VUE_APP_REVIEW_COUNT"
                :maxlength="VUE_APP_REVIEW_COUNT"
              >
              </v-textarea>
            </v-form>
            <div>
              <v-btn
                width="200px"
                color="primary"
                depressed
                rounded
                :disabled="note.description == ''"
                @click="noteSubmit"
                :loading="noteSubmitStatus"
                class="mb-11"
              >
              <span class="font-weight-bold">この情報を投稿する</span>
              </v-btn>
            </div>
          </div>
        </div>

        <div v-if="notes.length > 0">
          <p class="caption">届け出に関すること・近隣の飲食店・観光情報 など<br>このスポットに関する情報があれば、ぜひ投稿してください</p>
          <div v-for="(element,index) in notes" :key="index" class="source mb-4">
            <p v-html="autoLink(element.note.description)" class="subtitle-2 text-pre-wrap ml-0 sm-ml-2 text-justify"></p>
            <div class="d-flex align-content-center mt-4">
              <router-link :to="'/users/'+ element.user.userId +'/detail'" class="no-under-line default-color">
              <v-avatar
              size="24">
                <v-img
                  :src="element.user.userIconSrc"
                ></v-img>
              </v-avatar>
              </router-link>
              <router-link :to="'/users/'+ element.user.userId +'/detail'" class="no-under-line default-color">
                <p class="caption mb-0 pt-0 ml-2">{{ element.user.displayName }}</p>
              </router-link>
            </div>
            <div class="d-flex justify-space-between">
            <p class="caption mb-0 pt-1">{{ formatDate(element.note.createdAt.toDate()) }}</p>
              <v-row>
                <v-col
                  cols="12"
                  class="d-flex justify-end"
                >
                  <v-btn
                    icon
                    small
                  >
                  <v-icon x-small class="blue-grey--text text--lighten-4" @click="opneNoteDeleteDialog(element.note.id)" v-if="element.user.userId == uid">fas fa-trash</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    small
                  >
                  <v-icon x-small class="blue-grey--text text--lighten-4" @click="openRepotDialog(element,'みんなのクチコミ')">fas fa-flag</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              </div>
          </div>
        </div>
        <div class="subtitle-2 ml-2 mt-4" v-else>
          <p class="mb-0">届け出に関すること・近隣の飲食店・観光情報 など<br>このスポットに関する情報があれば、ぜひ投稿してください</p>
        </div>
      </div>

      <v-btn 
        width="100%"
        outlined
        rounded
        large
        color="primary"
        @click="$router.go(-1)"
        class="mt-6"
      >
      <span class="font-weight-bold">もどる</span>
      </v-btn>
    </div>

    <div class="zzzz">
      <v-dialog
        v-model="dialog.status"
        persistent
        max-width="600px"
        z-index=20
        >
        <v-card>
          <v-card-title class="text-h6">
            投稿ありがとうございます
          </v-card-title>
          <v-card-text class="subtitle-1 text-left" v-html="dialog.message">
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="closeDialog"
            >
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="zzzz">
      <v-dialog
          width="80%"
          max-width="1100px"
          v-model="photoDialog.status"
          overlay-opacity="0.94"
          class=""
        >
        <v-card>
          <div class="photo-d-back sp">
            <v-img
              :src="photoDialog.src"
            >
            </v-img>
          </div>
          <div class="photo-d-back pa-4 pc">
            <v-img
              contain
              :minHeight="photoDialogMinHeight"
              :height="photoDialogHeight"
              :src="photoDialog.src"
            >
            </v-img>
          </div>
          <div class="pt-sm-4 pr-sm-8 pl-sm-8 pb-sm-8 pt-1 pr-4 pl-4 pb-4">
            <div class="photo-dialog">
              <div class="footer-information">
                <div class="d-flex align-content-center mt-3">
                  <router-link :to="'/users/'+ photoDialog.uid +'/detail'" class="no-under-line default-color">
                    <v-avatar
                    size="30">
                      <v-img
                        :src="photoDialog.userIconSrc"
                      ></v-img>
                    </v-avatar>
                  </router-link>
                    <p class="caption mb-0 pt-1 ml-2"><router-link :to="'/users/'+ photoDialog.uid +'/detail'" class="no-under-line default-color">{{ photoDialog.userName }}</router-link></p>
                </div>
                <div>
                  <v-icon x-small class="mr-4 pt-2 blue-grey--text text--lighten-3" @click="photoDeleteDialog.status = true" v-if="photoDialog.uid == uid">fas fa-trash</v-icon>
                  <v-icon x-small class="mr-4 pt-2 blue-grey--text text--lighten-3" @click="openRepotDialog(photoDialog,'みんなの空撮写真')">fas fa-flag</v-icon>
                </div>
              </div>
              <div class="text-left">
                <p class="subtitle-1 font-weight-bold pt-4 mb-2 text-justify photo-title" v-if="photoDialog.title != ''">{{ photoDialog.title }}</p>
                <p class="subtitle-2 mb-3 pt-0 text-justify" v-if="photoDialog.description != ''">{{ photoDialog.description }}</p>
              </div>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>

    <div class="zzzz">
      <v-dialog
        v-model="dialog.status"
        persistent
        max-width="600px"
        z-index=20
        >
        <v-card>
          <v-card-title class="text-h6">
            投稿ありがとうございます
          </v-card-title>
          <v-card-text class="subtitle-1 text-left" v-html="dialog.message">
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="closeDialog"
            >
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="photoDeleteDialog">
      <v-dialog
          width="80%"
          max-width="750px"
          v-model="photoDeleteDialog.status"
        >
        <v-card>
          <v-card-title class="text-h6">
            投稿の削除
          </v-card-title>
          <v-card-text class="subtitle-1 text-left" v-html="'この投稿を削除しようとしています。<br>削除してしまった場合、投稿内容と写真はもとに戻すことはできません。<br>本当に削除してもよろしいですか？'"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="gray darken-1"
              text
              @click="photoDeleteDialog.status = false"
            >
              キャンセル
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="deletePhoto()"
            >
              <span class="font-weight-bold">削除する</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="youtubeDeleteDialog">
      <v-dialog
          width="80%"
          max-width="750px"
          v-model="youtubeDeleteDialog.status"
        >
        <v-card>
          <v-card-title class="text-h6">
            投稿の削除
          </v-card-title>
          <v-card-text class="subtitle-1 text-left" v-html="'この投稿を削除しようとしています。<br>削除してしまった場合、投稿をもとに戻すことはできません。<br>本当に削除してもよろしいですか？'"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="gray darken-1"
              text
              @click="youtubeDeleteDialog.status = false"
            >
              キャンセル
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="deleteYoutube()"
            >
              <span class="font-weight-bold">削除する</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="reportDialog">
      <v-dialog
          width="80%"
          max-width="750px"
          v-model="youtubeDeleteSuccessDialog.status"
        >
        <v-card>
          <v-card-title class="text-h6">
            投稿の削除
          </v-card-title>
          <v-card-text class="subtitle-1 text-left" v-html="'投稿された情報を削除しました😉'"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="gray darken-1"
              text
              @click="youtubeDeleteSuccessDialog.status = false"
            >
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="reportDialog">
      <v-dialog
          width="90%"
          max-width="500px"
          v-model="reportDialog.status"
        >
        <v-card>
          <v-card-title class="text-h6">
            問題の報告
          </v-card-title>
          <v-card-text class="subtitle-2 text-left" v-html="'該当する内容を選択してください'"></v-card-text>

          <v-radio-group
            class="mt-0"
            v-model="reportDialog.reportValue"
          >
            <v-radio v-for="(element,index) in report" :key="index"
              :label="element"
              :value="element"
              class="ml-10 mb-4"
            ></v-radio>
          </v-radio-group>

          <v-card-text class="caption text-left" v-html="'不適切として報告された情報や写真およびユーザーは、Flyersが確認しコミュニティガイドラインに違反していないかどうかを判断します。深刻な違反や、違反が繰り返された場合は、対象のアカウントが停止される場合があります。'"></v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="gray darken-1"
              text
              @click="reportDialog.status = false"
            >
              キャンセル
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              :disabled="reportDialog.reportValue == ''"
              @click="sendReport(reportDialog.type)"
            >
              <span class="font-weight-bold">通報する</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="reportDialog">
      <v-dialog
          width="80%"
          max-width="750px"
          v-model="photoDeleteSuccessDialog.status"
        >
        <v-card>
          <v-card-title class="text-h6">
            投稿の削除
          </v-card-title>
          <v-card-text class="subtitle-1 text-left" v-html="'投稿された内容と写真を削除しました😉'"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="gray darken-1"
              text
              @click="photoDeleteSuccessDialog.status = false"
            >
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="reportDialog">
      <v-dialog
          width="80%"
          max-width="750px"
          v-model="reportSuccessDialog.status"
        >
        <v-card>
          <v-card-title class="text-h6">
            問題報告の完了
          </v-card-title>
          <v-card-text class="subtitle-1 text-left" v-html="'問題報告いただき、ありがとうございます。<br> Flyersにて対象コンテンツの検証を行います。'"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="gray darken-1"
              text
              @click="reportSuccessDialog.status = false"
            >
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="reportDialog">
      <v-dialog
          width="80%"
          max-width="750px"
          v-model="sourceDeleteSuccessDialog.status"
        >
        <v-card>
          <v-card-title class="text-h6">
            禁止情報の削除
          </v-card-title>
          <v-card-text class="subtitle-1 text-left" v-html="'投稿された情報を削除しました😉'"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="gray darken-1"
              text
              @click="sourceDeleteSuccessDialog.status = false"
            >
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="reportDialog">
      <v-dialog
          width="80%"
          max-width="750px"
          v-model="noteDeleteSuccessDialog.status"
        >
        <v-card>
          <v-card-title class="text-h6">
            情報の削除
          </v-card-title>
          <v-card-text class="subtitle-1 text-left" v-html="'投稿された情報を削除しました😉'"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="gray darken-1"
              text
              @click="noteDeleteSuccessDialog.status = false"
            >
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="sourceDeleteDialog">
      <v-dialog
          width="80%"
          max-width="750px"
          v-model="sourceDeleteDialog.status"
        >
        <v-card>
          <v-card-title class="text-h6">
            禁止情報の削除
          </v-card-title>
          <v-card-text class="subtitle-1 text-left" v-html="'この情報を削除しようとしています。<br>削除してしまった場合、もとに戻すことはできません。<br>本当に削除してもよろしいですか？'"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="gray darken-1"
              text
              @click="sourceDeleteDialog.status = false"
            >
              キャンセル
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="deleteSource()"
            >
              <span class="font-weight-bold">削除する</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="noteDeleteDialog">
      <v-dialog
          width="80%"
          max-width="750px"
          v-model="noteDeleteDialog.status"
        >
        <v-card>
          <v-card-title class="text-h6">
            情報の削除
          </v-card-title>
          <v-card-text class="subtitle-1 text-left" v-html="'この情報を削除しようとしています。<br>削除してしまった場合、もとに戻すことはできません。<br>本当に削除してもよろしいですか？'"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="gray darken-1"
              text
              @click="noteDeleteDialog.status = false"
            >
              キャンセル
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="deleteNote()"
            >
              <span class="font-weight-bold">削除する</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-snackbar
      v-model="snackBar.isShow"
      :color="snackBar.color"
      bottom
      left
      :timeout="6000"
      class="mb-0 ml-0 mb-md-6 ml-md-6"
      transition="slide-x-transition"
    >
      <p class="mb-0">リストに <span class="font-weight-bold">{{snackBar.message}}</span> を追加しました</p>

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="addBookmarkCancel()"
          color="pink"
          small
          class="mr-2"
        >
          <span class="caption">取り消し</span>
        </v-btn>

        <v-btn
          v-bind="attrs"
          @click="listTransition()"
          color="success"
          small
        >
          <span class="caption">リストをみる</span>
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'
import Compressor from 'compressorjs'
import firebase from 'firebase'
import axios from 'axios'
import SignupBtn from '@/components/SignupBtn.vue'


export default {
  components: {
    SignupBtn
  },
  data() {
    return {
      islaunchSite:true,
      isLatlng:true,
      isParking:true,
      markers: [],
      userFlightSpot: [],
      uid: this.getUid(),
      userPhotos: [],
      photoUsers: [],
      youtubes: [],
      sources: [],
      notes: [],
      isDid: false,
      isAddPhoto: false,
      isAddYoutube: false,
      isAddSource: false,
      isAddNote: false,
      file: '',
      src: '',
      did: '',
      photoSubmitStatus: false,
      youtubeSubmitStatus: false,
      sourceSubmitStatus: false,
      noteSubmitStatus: false,
      VUE_APP_REVIEW_COUNT:process.env.VUE_APP_REVIEW_COUNT,
      VUE_APP_SOURCE_COUNT:process.env.VUE_APP_SOURCE_COUNT,
      VUE_APP_NOT_FLIGHT_SPOT_VALUE: process.env.VUE_APP_NOT_FLIGHT_SPOT_VALUE,
      VUE_APP_FLIGHT_SPOT_VALUE: process.env.VUE_APP_FLIGHT_SPOT_VALUE,
      photo:{
        id: '',
        spotid: this.$route.params.id,
        uid: this.getUid(),
        src:'',
        title:'',
        filename: '',
        description:'',
        status: 0,
        ban: false,
        updateAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      },
      youtube:{
        id: '',
        spotid: this.$route.params.id,
        uid: this.getUid(),
        url:'',
        status: 0,
        ban: false,
        updateAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      },
      source:{
        id:'',
        spotid: this.$route.params.id,
        uid: this.getUid(),
        description:'',
        status: 0,
        ban: false,
        updateAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      },
      note:{
        id: '',
        spotid: this.$route.params.id,
        uid: this.getUid(),
        description:'',
        status: 0,
        ban: false,
        updateAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      },
      dialog: {
        status: false,
        message: ''
      },
      photoDialog: {
        status: false,
        uid: this.getUid(),
        userphoto: '',
        title: '',
        description: '',
        src:'',
        userName:'test',
        userIconSrc:require('../../assets/img/87_thumb.jpg')
      },
      photoDeleteDialog: {
        status: false,
        id: '',
        filename: ''        
      },
      photoDeleteSuccessDialog: {
        status: false,
      },
      sourceDeleteSuccessDialog: {
        status: false,
      },
      noteDeleteSuccessDialog: {
        status: false,
      },
      youtubeDeleteDialog: {
        status: false,
        id: ''
      },
      youtubeDeleteSuccessDialog: {
        status: false,
      },
      sourceDeleteDialog: {
        status: false,
        id: ''
      },
      noteDeleteDialog: {
        status: false,
        id: ''
      },
      reportDialog: {
        type: '',
        status: false,
        array: [],
        reportValue: ''
      },
      reportSuccessDialog: {
        status: false,
      },
      rules: {
        regex: v => this.checkText(v) || '未入力もしくはURLが正しくありません',
        maxTitleLength: v => v.length <= 80 || '最大80文字までです',
        maxDescriptionLength: v => v.length <= 256 || '最大256文字までです',
      },
      report: ['スパム・迷惑行為','権利の侵害','性的な内容が含れる','危険な行為','法律に反する行為'],
      addPhotoPlaceholderText:"写真についての詳細\n撮影時の体験や状況などが含まれていると、他のドローンユーザーが飛ばすときの参考、また安全に繋がります。",
      latlngs:[],
      launchSites:[],
      parkings:[],
      center:{lat:42.3,lng:140.3},
      notePlaceholderText:"届け出は電話で終わります。\n帰りに食べた近くの中華料理さんの回鍋肉が最高でした。",
      snackBar:{
        isShow:false,
        color:'rgba(0,0,0,0.9)',
        message:''
      },
      canselSpotId:''
    }
  },
  computed: {
    google: gmapApi,
    photoDialogHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        return '10vh'
        default:
        return '60vh'
      }
    },
    photoDialogMinHeight () {
        switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        return '300'
        default:
        return '500'
      }
    }
  },
  created() {
    var self = this

    firebase.firestore().collection("userFlightSpots")
    .where("id", "==", this.$route.params.id)
    .get()
    .then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
          self.userFlightSpot = doc.data()
          self.center = self.getLatLng(self.userFlightSpot.mapCenter != '' ? self.userFlightSpot.mapCenter : self.userFlightSpot.center)

          var latlngStr = self.userFlightSpot.center.split(",", 2)
          self.latlngs = self.changeToPath(self.userFlightSpot.latlngs)
          self.launchSites = self.changeToPath(self.userFlightSpot.launchSites)
          self.parkings = self.changeToPath(self.userFlightSpot.parkings)
          var array = { position:{lat: parseFloat(latlngStr[0]),lng: parseFloat(latlngStr[1])}, type:self.userFlightSpot.type }
          self.markers.push(array)

          //ページタイトル
          document.title = self.userFlightSpot.name + ' - Flyers（フライヤーズ）' 

          if(self.did == '' && self.userFlightSpot.prefCode != '0'){
            self.did = self.$refs.gmap.$mapPromise.then((map) => {
              map.data.loadGeoJson('https://fogfrog.jp/ddd/did_'+ self.userFlightSpot.prefCode + '.geojson')
              map.data.setStyle({
                fillColor: process.env.VUE_APP_DID_FILL_COLOR,
                strokeWeight: process.env.VUE_APP_DID_STROKE_WEIGHT,
                fillOpacity: process.env.VUE_APP_DID_FILL_OPACITY
              });
            })
          }
          self.loadPhoto()
          self.loadYoutube()
          self.loadSource()
          self.loadNote()
      })
    })
  },
  methods: {
    getDidIcon(did){
      var result = did ? require('../../assets/img/icon_did_on.svg') : require('../../assets/img/icon_did_off.svg')
      return result
    },
    submit() {
      if(this.uid != ''){
        this.$router.push('/userspot/'+this.$route.params.id+'/form')
      } else {
        this.$router.push('/auth/signup')
      }
    },
    getLatLng(center) {
      var latlngStr = center.split(",", 2)
      var array = {lat: parseFloat(latlngStr[0]),lng: parseFloat(latlngStr[1])}
      return array
    },
    getFacilityStatus(value) {
      switch (value) {
        case 'yes':
          return 'ある'
        case 'no':
          return 'ない'
        case 'unknown':
        case '':
          return 'Flyersにて確認中'
        default:
          break;
      }
    },    
    getMakerOptions(type) {
      switch (type) {
        case parseInt(process.env.VUE_APP_FLIGHT_SPOT_VALUE):
        default:
        return {
          url: require('../../assets/img/y.svg'),
          size: {width: 40, height: 40, f: 'px', b: 'px',},
          scaledSize: {width: 40, height: 40, f: 'px', b: 'px',},
        }
        case parseInt(process.env.VUE_APP_NOT_FLIGHT_SPOT_VALUE):
        return {
          url: require('../../assets/img/z.svg'),
          size: {width: 40, height: 40, f: 'px', b: 'px',},
          scaledSize: {width: 40, height: 40, f: 'px', b: 'px',},
        }
      }
    },
    showDid() {
      if(this.isDid){
        this.$refs.gmap.$mapPromise.then((map) => {
          map.data.setStyle({
            fillColor: process.env.VUE_APP_DID_FILL_COLOR,
            strokeWeight: process.env.VUE_APP_DID_STROKE_WEIGHT,
            fillOpacity: process.env.VUE_APP_DID_FILL_OPACITY
          });
        })
      } else {
        this.$refs.gmap.$mapPromise.then((map) => {
          map.data.setStyle({
            strokeWeight: 0,
            fillOpacity: 0
          });
        })
      }
      this.isDid = !this.isDid
    },
    getDidData(){
      this.$refs.gmap.$mapPromise.then((map) => {
        map.data.loadGeoJson('https://fogfrog.jp/ddd/did_'+ this.userFlightSpot.prefCode + '.geojson')
        map.data.setStyle({
          strokeWeight: 0,
          fillOpacity: 0
        });
      })
    },
    autoLink(text){
      return text.replace(/(https?:\/\/[^\s]*)/g, "<a href='$1' target='_blank'>$1</a>")
    },
    getTypeIconSrc(type) {
      switch (type) {
        case parseInt(process.env.VUE_APP_FLIGHT_SPOT_VALUE):
        default:
        return require('@/assets/img/y.svg')
        case parseInt(process.env.VUE_APP_NOT_FLIGHT_SPOT_VALUE):
        return require('@/assets/img/z.svg')
      }
    },
    getFacilityText(text) {
      switch (text) {
        case process.env.VUE_APP_YES:
          return 'あり'
        case process.env.VUE_APP_NO:
          return 'なし'
        case process.env.VUE_APP_UNKNOWN:
          return 'わからない'
        default:
          return '確認中'
      }
    },
    getYoutubeId(url){
      
      var parser = new URL(url)
      switch (parser.host) {
        case 'www.youtube.com':
          return url.split('v=')[1]
        case 'youtu.be':
          return url.split('.be/')[1]
        default:
          break;
      }
    },
    addPhoto() {
      this.isAddPhoto = !this.isAddPhoto
    },
    onFileUpload(file) {
      var self = this

      if(file != null){
        new Compressor(file, {
          quality: 0.6,
          maxWidth: 1600,
          maxHeight: 1600,
          success(result) {
            self.file = result
            self.src = URL.createObjectURL(result)
          },
          error(err) {
            console.log(err.message);
          }
        })
      }
    },
    photoSubmit() {
      var self = this
      this.photoSubmitStatus = true

      var storage = firebase.storage()
      var storageRef = storage.ref()
      var imagesRef = storageRef.child('users/'+ this.$store.getters.user.uid +'/photo')
      var filename = this.creatFileName(this.file.name)
      var spaceRef = imagesRef.child(filename)
      var contentType = this.file.type
      var uploadTask = spaceRef.put(this.file,contentType)

      uploadTask.on('state_changed', function(snapshot){ // eslint-disable-line
        }, function(error) { // eslint-disable-line
        }, function() {
        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          self.savePhoto(downloadURL,filename)
        })
      })
    },
    savePhoto(downloadURL,filename) {

      this.photo.src = downloadURL
      var self = this

      var spdb = firebase.firestore().collection("spotPhotos").doc()
      this.photo.id = spdb.id
      this.photo.filename = filename

      spdb.set(self.photo)
      .then(function() {
        self.dialog.status = true
        self.dialog.message = '写真の投稿が完了しました。'
        self.photoSubmitStatus = false

        self.updateUserFlightSpot()
      })
      .catch(function(error) {
        console.error("Error writing document: ", error);
      })
    },
    saveYoutube() {
      var self = this

      var ydb = firebase.firestore().collection("spotYoutubes").doc()
      self.youtube.id = ydb.id

      ydb.set(self.youtube)
      .then(function() {
        self.youtubeSubmitStatus = false
        self.dialog.status = true
        self.dialog.message = '動画の追加が完了しました。'

        self.updateUserFlightSpot()
      })
      .catch(function(error) {
        console.error("Error writing document: ", error);
      })
    },
    saveSource() {
      var self = this

      var sdb = firebase.firestore().collection("spotSources").doc()
      self.source.id = sdb.id
      
      sdb.set(self.source)
      .then(function() {
        self.dialog.status = true
        self.dialog.message = '禁止されている事がわかる情報の追加が完了しました。'
        self.sourceSubmitStatus = false

        self.updateUserFlightSpot()

        self.loadSource()
      })
      .catch(function(error) {
        console.error("Error writing document: ", error);
      })
    },
    saveNote() {
      var self = this

      var db = firebase.firestore().collection("spotNotes").doc()
      self.note.id = db.id
      
      db.set(self.note)
      .then(function() {
        self.dialog.status = true
        self.dialog.message = 'みんなのクチコミに追加しました。'
        self.noteSubmitStatus = false

        self.updateUserFlightSpot()

        self.loadNote()
      })
      .catch(function(error) {
        console.error("Error writing document: ", error);
      })
    },
    closeDialog() {
      this.dialog.status = false
      this.isAddPhoto = false 
      this.isAddSource = false 
      this.isAddYoutube = false 
      this.isAddNote = false 

      this.src = ''
      this.file = ''
      this.photo.title = ''
      this.photo.description = ''
      this.source.description = ''
      this.note.description = ''
      this.youtube.url = ''

      this.loadPhoto()
      this.loadYoutube()
      this.loadSource()
      this.loadNote()
    },
    creatFileName(name) {
      var lastIndex = name.lastIndexOf('.')
      var pureName = name.substring(0,lastIndex)
      var ex = name.substring(lastIndex,name.length)
      return pureName + '_' + new Date().getTime().toString() + ex
    },
    loadPhoto() {

      this.userPhotos = []

      var self = this
      firebase.firestore().collection("spotPhotos")
      .where('spotid','==',this.userFlightSpot.id)
      .orderBy("createdAt", "desc")
      .get().then(function(querySnapshot) {
        querySnapshot.forEach(function(photoDoc) {
          firebase.firestore().collection("users")
          .where('userId','==',photoDoc.data().uid)
          .get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              self.userPhotos.push({photo:photoDoc.data(),user:doc.data()})
            })
            self.userPhotos.sort(function(a,b){
              if(a.photo.createdAt.toDate() < b.photo.createdAt.toDate()) return 1;
              if(a.photo.createdAt.toDate() > b.photo.createdAt.toDate()) return -1;
            return 0;
            })
          })
        })
      })
    },
    loadYoutube() {

      this.youtubes = []

      var self = this

      firebase.firestore().collection("spotYoutubes")
      .where('spotid','==',this.userFlightSpot.id)
      .orderBy("createdAt", "desc")
      .get().then(function(querySnapshot) {
        querySnapshot.forEach(function(youtubeDoc) {
          firebase.firestore().collection("users")
          .where('userId','==',youtubeDoc.data().uid)
          .get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              self.youtubes.push({youtube:youtubeDoc.data(),user:doc.data()})
            })
            self.youtubes.sort(function(a,b){
              if(a.youtube.createdAt.toDate() < b.youtube.createdAt.toDate()) return 1;
              if(a.youtube.createdAt.toDate() > b.youtube.createdAt.toDate()) return -1;
              return 0;
            })
          })
        })
      })
    },
    loadSource() {

      this.sources = []

      var self = this
      firebase.firestore().collection("spotSources")
      .where('spotid','==',this.userFlightSpot.id)
      .orderBy("createdAt", "desc")
      .get().then(function(querySnapshot) {
        querySnapshot.forEach(function(sourceDoc) {
          firebase.firestore().collection("users")
          .where('userId','==',sourceDoc.data().uid)
          .get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              self.sources.push({source:sourceDoc.data(),user:doc.data()})
            })
            self.sources.sort(function(a,b){
              if(a.source.createdAt.toDate() < b.source.createdAt.toDate()) return 1;
              if(a.source.createdAt.toDate() > b.source.createdAt.toDate()) return -1;
              return 0;
            })
          })
        })
      })
    },
    loadNote() {

      this.notes = []

      var self = this
      firebase.firestore().collection("spotNotes")
      .where('spotid','==',this.userFlightSpot.id)
      .orderBy("createdAt", "desc")
      .get().then(function(querySnapshot) {
        querySnapshot.forEach(function(noteDoc) {
          firebase.firestore().collection("users")
          .where('userId','==',noteDoc.data().uid)
          .get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              self.notes.push({note:noteDoc.data(),user:doc.data()})
            })
            self.notes.sort(function(a,b){
              if(a.note.createdAt.toDate() < b.note.createdAt.toDate()) return 1;
              if(a.note.createdAt.toDate() > b.note.createdAt.toDate()) return -1;
              return 0;
            })
          })
        })
      })
    },
    showPhoto(index) {
      this.photoDialog.status = true
      this.photoDialog.src = this.userPhotos[index].photo.src
      this.photoDialog.userName = this.userPhotos[index].user.displayName
      this.photoDialog.userIconSrc = this.userPhotos[index].user.userIconSrc
      this.photoDialog.title = this.userPhotos[index].photo.title
      this.photoDialog.description = this.userPhotos[index].photo.description
      this.photoDialog.uid = this.userPhotos[index].user.userId

      this.photoDeleteDialog.id = this.userPhotos[index].photo.id
      this.photoDeleteDialog.filename = this.userPhotos[index].photo.filename
    },
    youtubeSubmit() {
      if(this.$refs.form.validate()){
        this.youtubeSubmitStatus = true
        this.saveYoutube()
      }
    },
    checkText(url) {
      if ( url.match(/https:\/\/www.youtube.com\//) || url.match(/https:\/\/youtu.be\//)) {
        return true
      } else {
        return false
      }
    },
    sourceSubmit() {
      this.sourceSubmitStatus = true
      this.saveSource()
    },
    noteSubmit() {
      this.noteSubmitStatus = true
      this.saveNote()
    },
    formatDate(date) {
      var year = date.getFullYear();
      var month = ('00' + (date.getMonth()+1)).slice(-2);
      var day = ('00' + date.getDate()).slice(-2);
      var week = ['日','月','火','水','木','金','土'][date.getDay()]
      var hour = date.getHours()
      var second = ('00' + date.getMinutes()).slice(-2)

      return (year + '年' + month + '月' + day + '日（' + week +'） '+ hour + ':' + second)
    },   
    deletePhoto() {

      var self = this

      if(this.photoDeleteDialog.id != null && this.photoDeleteDialog.id != ''){
        var db = firebase.firestore()
        db.collection("spotPhotos").doc(this.photoDeleteDialog.id).delete()
        .then(function() {
          var storage = firebase.storage()
          var storageRef = storage.ref()
          var imagesRef = storageRef.child('users/'+ self.$store.getters.user.uid +'/photo')
          var spaceRef = imagesRef.child(self.photoDeleteDialog.filename)
          spaceRef.delete().then(function() {
            self.photoDeleteDialog.status = false
            self.photoDialog.status = false
            self.photoDeleteSuccessDialog.status = true
            self.loadPhoto()
          }).catch(function(error) {
            console.log(error)
          })
        })
        .catch(function(error) {
          console.error("Error writing document: ", error);
        });
      } else {
        self.photoDeleteDialog.status = false
      }
    },
    reportPhoto() {
      alert('近日中に実装します')
    },
    opneSourceDeleteDialog(id) {
      this.sourceDeleteDialog.status = true
      this.sourceDeleteDialog.id = id
    },
    opneNoteDeleteDialog(id) {
      this.noteDeleteDialog.status = true
      this.noteDeleteDialog.id = id
    },
    deleteSource() {
      var self = this

      firebase.firestore().collection("spotSources").doc(this.sourceDeleteDialog.id).delete()
      .then(function() {
        self.sourceDeleteDialog.status = false
        self.sourceDeleteSuccessDialog.status = true
        self.loadSource()
      }).catch(function(error) {
        console.log(error)
      })
    },
    deleteNote() {
      var self = this

      firebase.firestore().collection("spotNotes").doc(this.noteDeleteDialog.id).delete()
      .then(function() {
        self.noteDeleteDialog.status = false
        self.noteDeleteSuccessDialog.status = true
        self.loadNote()
      }).catch(function(error) {
        console.log(error)
      })
    },
    openYoutubeDeleteDialog(id) {
      this.youtubeDeleteDialog.status = true
      this.youtubeDeleteDialog.id = id
    },
    deleteYoutube() {
      var self = this

      firebase.firestore().collection("spotYoutubes").doc(this.youtubeDeleteDialog.id).delete()
      .then(function() {
        self.youtubeDeleteDialog.status = false
        self.youtubeDeleteSuccessDialog.status = true
        self.loadYoutube()
      }).catch(function(error) {
        console.log(error)
      })
    },
    sendReport(type) {

      var payload = []
      var self = this

      switch (type) {
        case 'みんなの空撮写真':
          payload = {
              "blocks": [
              {
                "type":"section",
                "text": {
                  "type":"mrkdwn",
                  "text": this.uid + ' さんから通報がありました\n投稿タイプ：'+ type +'\n通報の内容：' + this.reportDialog.reportValue + '\n投稿者のID：'+  this.reportDialog.array.uid
                  }
              },
              {
                "type":"section",
                "text": {
                  "type":"mrkdwn",
                  "text": '投稿されたスポット名：' + this.userFlightSpot.name + '\nパス：' + this.$route.fullPath
                  }
              },
              {
                "type": "image",
                "image_url": this.reportDialog.array.src,
                "alt_text": 'image1'
              }
              ]
            }
          break;

        case 'このスポットの動画':
            payload = {
              "blocks": [
              {
                "type":"section",
                "text": {
                  "type":"mrkdwn",
                  "text": this.uid + ' さんから通報がありました\n投稿タイプ：'+ type +'\n通報の内容：' + this.reportDialog.reportValue + '\n投稿者のID：'+  this.reportDialog.array.user.userId
                  }
              },
              {
                "type":"section",
                "text": {
                  "type":"mrkdwn",
                  "text": '投稿されたスポット名：' + this.userFlightSpot.name + '\nパス：' + this.$route.fullPath
                  }
              },
              {
                "type":"section",
                "text": {
                  "type":"mrkdwn",
                  "text": 'spotYoutubesId：' + this.reportDialog.array.youtube.id + '\n<'+ this.reportDialog.array.youtube.url +' |'+ this.reportDialog.array.youtube.url +' >'
                  }
              },
              ]
            }
          break;

        case '禁止されている事がわかる情報':
            payload = {
              "blocks": [
              {
                "type":"section",
                "text": {
                  "type":"mrkdwn",
                  "text": this.uid + ' さんから通報がありました\n投稿タイプ：'+ type +'\n通報の内容：' + this.reportDialog.reportValue + '\n投稿者のID：'+  this.reportDialog.array.user.userId
                  }
              },
              {
                "type":"section",
                "text": {
                  "type":"mrkdwn",
                  "text": '投稿されたスポット名：' + this.userFlightSpot.name + '\nパス：' + this.$route.fullPath
                  }
              },
              {
                "type":"section",
                "text": {
                  "type":"mrkdwn",
                  "text": 'spotSourcesId：' + this.reportDialog.array.source.id + '\n投稿内容：'+ this.reportDialog.array.source.description
                  }
              },
              ]
            }
          break;

        case 'みんなのクチコミ':
            payload = {
              "blocks": [
              {
                "type":"section",
                "text": {
                  "type":"mrkdwn",
                  "text": this.uid + ' さんから通報がありました\n投稿タイプ：'+ type +'\n通報の内容：' + this.reportDialog.reportValue + '\n投稿者のID：'+  this.reportDialog.array.user.userId
                  }
              },
              {
                "type":"section",
                "text": {
                  "type":"mrkdwn",
                  "text": '投稿されたスポット名：' + this.userFlightSpot.name + '\nパス：' + this.$route.fullPath
                  }
              },
              {
                "type":"section",
                "text": {
                  "type":"mrkdwn",
                  "text": 'spotSourcesId：' + this.reportDialog.array.note.id + '\n投稿内容：'+ this.reportDialog.array.note.description
                  }
              },
              ]
            }
          break;
        default:
          break;
      }

      var url = 'https://hooks.slack.com/services/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'

      var params = new URLSearchParams();
      params.append('payload', JSON.stringify(payload));

      axios.post(url, params)
        .then(function (response) { // eslint-disable-line
          self.reportDialog.status = false
          self.reportDialog.reportValue = ''
          self.reportSuccessDialog.status = true
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    openRepotDialog(array,type) {
      this.reportDialog.type = type
      this.reportDialog.status = true
      this.reportDialog.array = array
    },
    fileClear() {
      this.file = ''
      this.src = ''
    },
    changeToPath(value){

      if(value != null){
        var result = []
        value.forEach(element => {
          var arrs = element.value.split('\n')
          var r = []
          arrs.forEach(element2 => {
            var arrss = element2.split(',')
            r.push({'lat': parseFloat(arrss[0]) , 'lng':parseFloat(arrss[1])})
          });
          result.push(r)
        });
        return result
      } else {
        return []
      }
    },
    getUid(){
      return this.$store.getters.user != null ? this.$store.getters.user.uid : ''
    },
    updateUserFlightSpot(){
      firebase.firestore().collection("userFlightSpots")
      .doc(this.userFlightSpot.id)
      .update({
        updateAt:firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(function() {
      })
      .catch(function(error) {
        console.error("Error writing document: ", error);
      })
    },
    addBookmark(spotId,spotName){

      this.canselSpotId = spotId

      var self = this
      if(this.$store.getters.user != null){
        firebase.firestore().collection("userBookmarks")
        .where("uid", "==",this.$store.getters.user.uid)
        .limit(1)
        .get()
        .then(function(querySnapshot) {
          if(querySnapshot.size != 0){
            var docId = ''
            querySnapshot.forEach(function(doc) {
              docId = doc.id
            })

            firebase.firestore().collection("userBookmarks")
            .doc(docId)
            .update({
              spots: firebase.firestore.FieldValue.arrayUnion(spotId),
              updateAt: firebase.firestore.FieldValue.serverTimestamp()
            })
            .then(function() {
              self.snackBar.isShow = true
              self.snackBar.message = spotName
            })
            .catch(function(error) {
              console.error("Error writing document: ", error);
            })

          } else {
            var db = firebase.firestore().collection("userBookmarks").doc()

            db.set({
              uid: self.$store.getters.user.uid,
              id: db.id,
              spots: [spotId],
              publicType: 0,
              listName: '行ってみたい',
              updateAt: firebase.firestore.FieldValue.serverTimestamp(),
              createdAt: firebase.firestore.FieldValue.serverTimestamp()
            })
            .then(function() {
              self.snackBar.isShow = true
              self.snackBar.message = spotName
            })
          }
        })
      } else {
        this.$router.push('/auth/login')
      }
    },
    addBookmarkCancel(){

      var self = this

      firebase.firestore().collection("userBookmarks")
      .where("uid", "==",this.$store.getters.user.uid)
      .limit(1)
      .get()
      .then(function(querySnapshot) {
        if(querySnapshot.size != 0){
          var docId = ''
          querySnapshot.forEach(function(doc) {
            docId = doc.id
          })

          firebase.firestore().collection("userBookmarks")
          .doc(docId)
          .update({
            spots: firebase.firestore.FieldValue.arrayRemove(self.canselSpotId),
            updateAt: firebase.firestore.FieldValue.serverTimestamp()
          })
          .then(function() {
            self.snackBar.isShow = false
            self.snackBar.message = ''
          })
          .catch(function(error) {
            console.error("Error writing document: ", error);
          })
        }
      })
    },
    listTransition(){
      this.$router.push('/users/'+this.$store.getters.user.uid+'/list')
    }
  },
}
</script>

<style lang="scss" scoped>

.use {
  background: linear-gradient(40deg, rgba(0,230,168,1) 0%, rgba(36,161,254,1) 100%);  color:white;
}

.path-controller {
  padding: 0px 24px;
}

.opa {
  opacity: 0.15;
}

.photo-d-back {
  background: #f1f3f7;
}

.photo-dialog {
  .footer-information {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .photo-title {
    line-height: 1.5;
  }
}

.source {
  position: relative;
  padding: 20px;
  border-radius: 4px;
  border: 1px rgba(0,0,0,0.15) solid;
}

.us {
  position: sticky;
  top:56px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 28px;
  box-shadow: 0px 0px 8px -2px rgba(0,0,0,0.8);
  background: linear-gradient(40deg,  rgba(204,81,208,1) 0%, rgba(36,161,254,1) 100%);  color:white;
  z-index: 90;
}

.fs {
  position: sticky;
  top:56px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 28px;
  box-shadow: 0px 0px 8px -2px rgba(0,0,0,0.8);
  background: linear-gradient(40deg, rgba(0,230,168,1) 0%, rgba(36,161,254,1) 100%);  color:white;
  z-index: 90;
}

.kusatsu {
  display:grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  gap:20px;
}

.add_photo {
  width: 500px;
  text-align: left;
}

#user_youtube {
  width: 100%;
  height: 100%;
  .contents {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px,1fr));
    gap: 20px;
    .wrapper {
      position: relative;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 3px;
      }
      &::before {
        content: "";
        display: inline-block;
        padding-top: 56.25%;
      }
    }
    .footer-information {
      display: flex;
      justify-content: space-between;
    }
  }
}

.not_flight_spot, .word_break_all {
  word-break: break-all;
}

.omit {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.img-hover {
  transition-duration: 1.3s;
  opacity: 1;
  &:hover {
    transition-duration: 0.3s;
    opacity: .7;
  }
}

@media (max-width: 768px) {
  .us,.fs {
    top:56px;
  }
  
}

@media (max-width: 600px) {

  .add_photo {
    width: 100%;
  }

  .kusatsu {
    display:grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap:8px 8px;
  }

}

</style>