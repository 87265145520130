<template>
  <div>
    <v-row class="landingg mr-0 ml-0">
      <div class="aaaaaaaaa">
        <v-card min-width="350px" class="mx-auto mt-5 pa-7 zzz"> 
          <v-img class="ma-6" src="@/assets/img/betalogo.svg"></v-img>
            <h1 class="text-h6 font-weight-bold mt-4 mb-0">さぁ、はじめましょう</h1>
          <v-card-text>
            <v-form>
              <v-text-field 
                prepend-icon="mdi-account" 
                counter="40"
                maxlength="40"
                v-model="displayName" 
                :rules="[rules.required]"
                >
                <template v-slot:label>
                  <p>ユーザー名<span class="caption">（いつでも変更可能）</span></p>
                </template>
              </v-text-field>
              <v-text-field 
                prepend-icon="mdi-email" 
                label="メールアドレス"
                v-model="email" />
              <v-text-field 
                :type="showPassword ? 'text' : 'password'" 
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                prepend-icon="mdi-lock" 
                label="パスワード（6文字以上）"
                @click:append="showPassword = !showPassword"
                v-model="password" />
              <h5 class="mt-4 mb-0">β版サービス中は無料でご利用いただけます</h5>
              <v-card-actions>
                <v-btn 
                  rounded
                  color="primary"
                  width="100%"
                  depressed
                  :loading="status"
                  :disabled="displayName == '' || password == '' || email == ''"
                  class="mt-0 mb-3"
                  @click="signUp"
                >
                <span class="font-weight-bold">ベータ版アカウント作成</span>
                </v-btn>
              </v-card-actions>
            </v-form>
            <p class="caption mb-0"><router-link to="/s/terms" class="mr-2">利用規約</router-link><router-link to="/s/privacy">プライバシーポリシー</router-link></p>
          </v-card-text>
        </v-card>
      </div>

      <div class="hoge"></div>
      <video poster="" preload="none" muted loop autoplay playsinline>
        <source src="@/assets/img/top.mp4">
      </video>
    </v-row>

      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
        >
        <v-card>
          <v-card-title class="text-h6">
            お知らせ
          </v-card-title>
          <v-card-text class="subtitle-1 text-left">
            {{ message }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="closeDialog"
            >
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'Signup',
  data () {
    return {
      email: '',
      password: '',
      showPassword: false,
      status: false,
      dialog: false,
      message: '',
      displayName: '',
      rules: {
        required: v => !!v || '必須です.',
      },
    }
  },
  methods: {
    signUp: function () {
    var self = this
    this.status = true
    firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
      .then((response) => { 
        firebase.firestore().collection("users").add({
          userId: response.user.uid,
          displayName: self.displayName,
          userIconSrc: '/img/icon_blank.svg',
          selfIntroduction: '',
          activityBase: '',
          youtube: '',
          twitter: '',
          requestsLeft: 1,
          web: '',
          updateAt: firebase.firestore.FieldValue.serverTimestamp(),
          createdAt: firebase.firestore.FieldValue.serverTimestamp()
        })
        .then(function() {
          self.message = 'Flyersベータ版アカウントが作成されました'
          self.$store.commit('updateIdToken', response.user);
          self.$store.commit('updateUserIconSrc', '/img/icon_blank.svg')
          self.showDialog()
        })
        .catch(function(error) {
          console.error("Error writing document: ", error);
        })
      })
      .catch((error) => { // eslint-disable-line
        var errorCode = error.code;

        switch(errorCode){
          case 'auth/email-already-in-use':
            this.message = 'このメールアドレスのアカウントが存在します'
          break
          case 'auth/invalid-email':
            this.message = '有効なメールアドレスを入力してください'
          break
          case 'auth/weak-password':
            this.message = '他人から推測されやすいパスワードが入力されています'
          break
          default:
            this.message = '何度もこのメッセージが表示される場合は、お手数ですが運営までお問い合わせください'
          break
        } 
       this.showDialog()
      });
    },
    showDialog: function () {
        this.dialog = true
        this.status = false
    },
    closeDialog: function () {
      this.dialog = false
      if(this.$store.getters.idToken != null){
        this.$router.push({name: 'Complete', params: {
          displayName:this.displayName
        }})
      }
    }
  },created() {
   firebase.analytics().logEvent('signup', {});
  },
  mounted() {
    document.title = this.$route.meta.title
  }
}
</script>

<style lang="scss">

.landingg {
  position: relative;
  min-height: 800px;
  &::before {
    content: "";
    display: inline-block;
    padding-top: 56.25%;
  }
  video {
    position: absolute;
    width: 100%;
    min-height: 800px;
    top:0;
    left:0;
    z-index:0;
    object-fit: cover;
  }
  .hoge {
    position: absolute;
    width: 100%;
    background-color: rgba(0,0,0,.25); 
    min-height: 800px;

    z-index:1;
    padding-top: 56.25%;
  }
  .fles {
    position: fixed;
    align-items: center;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    z-index:4;
  }
}

.signup {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 800px;
  background-image: url('/img/top_img.jpg');
  background-size: cover;
}

.zzz {
  z-index: 9;
}

.aaaaaaaaa {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>